/* istanbul ignore file */
export const scrollToObservation = (
  id: string,
  behavior: 'auto' | 'instant' | 'smooth' = 'smooth',
  block: 'start' | 'center' | 'end' = 'start'
) => {
  const element = document.getElementById(id)

  const timeout = setTimeout(() => {
    element?.scrollIntoView({
      // @ts-ignore
      behavior: behavior,
      block: block,
      inline: 'nearest',
    })
  }, 10)

  return () => clearTimeout(timeout)
}
