import 'react'

import {
  CustomBaseElement,
  CustomDescendant,
  SlateBadgeElement,
  slateUtils,
} from '@novax/zip-frontend-library'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
} from 'services/zipmodule.gen'
import {
  generateParagraphFromBadges,
  generateSlateBadgesFromIdValuePairs,
} from 'utils/sentenceGeneration'
import { getNodeFromDropdownId } from 'utils/terminologyTreeUtils'

import { IAddComponent, IObservation, ITermTreeNode } from '../interfaces/IReportDetails'
import { IValueInputType } from '../subcomponents/terminologyRow/TerminologyRowLevel1'
import { HIDDEN_TERMINOLOGY_ITEMS } from '../subcomponents/terminologyRow/utils/terminologyRowUtils'

export const createObservationArray = (
  terminologyTree: ITermTreeNode[] | null,
  observations: ZipModuleCommonDtosObservationDto[]
): IObservation[] => {
  const observationArray: IObservation[] = []

  //first fill observations from data from the tree
  terminologyTree?.forEach((term: ITermTreeNode) => {
    const observationFromTerminology: IObservation = {
      id: term.id,
      value: term.value,
      description: '',
      descriptionSlateJs: [{ type: 'paragraph', children: [{ text: '' }] }],
      components: [],
      showCard: true,
      isSaved: false,
      isUpdated: false,
      tKey: term.tKey ?? '',
      updatedAt: null,
      updatedBy: '',
    }
    // if data coming from the backend, update observation that is prefilled from the tree
    const backendObservation = observations.find((item) => item.id === term.id)
    backendObservation && updateObservation(backendObservation, observationFromTerminology, term)
    observationArray.push(observationFromTerminology)
  })

  if (terminologyTree?.find((el) => el.tKey?.toLowerCase() == 'findings')?.children.length == 0) {
    observationArray.push({
      id: 'images',
      value: 'Images',
      description: '',
      descriptionSlateJs: [{ type: 'paragraph', children: [{ text: '' }] }],
      components: [],
      showCard: false,
      isSaved: false,
      tKey: 'images',
      isUpdated: false,
    })
  }

  return observationArray
}

export const updateObservation = (
  observationFromBackend: ZipModuleCommonDtosObservationDto,
  observationFrontendModel: IObservation,
  rootTerminologyNode: ITermTreeNode
): void => {
  observationFrontendModel.isSaved = observationFromBackend.isSaved ?? false
  observationFrontendModel.isUpdated = observationFromBackend.isUpdated ?? false
  observationFrontendModel.updatedAt = observationFromBackend.updatedAt ?? null
  observationFrontendModel.updatedBy = observationFromBackend.updatedBy ?? ''

  observationFrontendModel.descriptionSlateJs = parseSlateValue(
    observationFromBackend,
    rootTerminologyNode
  )
  observationFrontendModel.description = slateUtils.toPlainText(
    observationFrontendModel.descriptionSlateJs
  )

  if (observationFromBackend.components && observationFromBackend.components.length > 0) {
    observationFrontendModel.components = observationFromBackend.components.map(
      (el: ZipModuleCommonDtosComponentDto) => ({
        dropdownIds: el.dropdownIds ? el.dropdownIds : [],
        text: el.text ? el.text : '',
        images: el.images ? el.images : [],
        isUpdated: el.isUpdated ?? false,
        updatedAt: el.updatedAt ?? null,
        updatedBy: el.updatedBy ?? '',
      })
    )
  } else {
    observationFrontendModel.components = []
  }
}

export const updatePathology = (
  terminologyTree: ITermTreeNode[] | null,
  observations: IObservation[]
): boolean => {
  const findings = observations.find(
    (observation: IObservation) => observation.tKey.toLowerCase() === 'findings'
  )

  const findingsTerminology = terminologyTree?.find((el) => el.tKey?.toLowerCase() == 'findings')

  // if findingsTerminology is empty or undefined that means that this procedure type doesn't have terminology
  // in that case skip everything
  if (!findingsTerminology || findingsTerminology.children.length === 0) return false

  const pathology = observations.find(
    (observation: IObservation) => observation.tKey.toLowerCase() === 'pathology'
  )
  // currently sending only polyp and GB to pathology observation
  const containerSequenceRaw = findingsTerminology?.children
    ?.find((t) => t.tKey?.toLowerCase() == 'polyp')
    ?.children.find((c) => c.tKey == 'container_number')?.id

  if (!containerSequenceRaw) {
    console.error(
      "Can't find terminology for findings - polyp - container_number. Can't conclude container sequnce, please check your terminology."
    )
    return false
  }
  if (!findings || !pathology || !findingsTerminology) {
    console.error('[updatePathology()] Unexpected error! Probably a terminology issue!')
    return false
  }

  // add comma to match only when value is present
  const containerSequence = `${containerSequenceRaw},`
  // map to easily append preservation method to container id
  const preservationMethodContainerMap: {
    [containerSequence: string]: string
  } = {}

  // go through existing pathology components and extract preservation methods for containers
  pathology.components.forEach((component) => {
    component.dropdownIds?.forEach((id) => {
      // if container number is in this input, extract preservation method
      if (id.includes(containerSequence) && id.split(containerSequence).length > 1) {
        const containerSequenceStartIndex = id.indexOf(containerSequence)
        const containerSequenceEndIndex = containerSequenceStartIndex + containerSequence.length
        const preservationSequenceStartIndex = id.indexOf(',', containerSequenceEndIndex)
        // if not found, skip
        if (preservationSequenceStartIndex == -1) return

        // this is container number sequence with value e.g for container_number,container_3
        const containerSequenceWithValue = id.substring(
          containerSequenceStartIndex,
          preservationSequenceStartIndex
        )
        // extract preservation sequence and save to map if not present
        if (!Object.hasOwn(preservationMethodContainerMap, containerSequenceWithValue)) {
          const preservationSequence = id.substring(preservationSequenceStartIndex + 1)
          preservationMethodContainerMap[containerSequenceWithValue] = preservationSequence
        }
      }
    })
  })

  // now generate new pathology components from finding components
  const pathologyComponents: ZipModuleCommonDtosComponentDto[] = []

  findings.components.forEach((component) => {
    const dropdownIdsWithPreservationMethod: string[] = []
    let shouldAddToPathology = false

    component.dropdownIds?.forEach((id) => {
      // if container number is in this input, add to pathology and add preservation method
      if (id.includes(containerSequence) && id.split(containerSequence).length > 1) {
        const valueTerminologyNode = getNodeFromDropdownId(findingsTerminology, id)
        // skip if value is no container
        if (!valueTerminologyNode || valueTerminologyNode.tKey === 'no_container') return

        shouldAddToPathology = true
        const containerSequenceStartIndex = id.indexOf(containerSequence)
        const containerSequenceEndIndex = containerSequenceStartIndex + containerSequence.length
        let preservationSequenceStartIndex = id.indexOf(',', containerSequenceEndIndex)
        // if not found, set to the end of string
        if (preservationSequenceStartIndex == -1) preservationSequenceStartIndex = id.length
        const containerSequenceWithValue = id.substring(
          containerSequenceStartIndex,
          preservationSequenceStartIndex
        )
        const firstPart = id.substring(0, preservationSequenceStartIndex)

        // find preservation method for this container in the map and add it
        if (Object.hasOwn(preservationMethodContainerMap, containerSequenceWithValue)) {
          dropdownIdsWithPreservationMethod.push(
            `${firstPart},${preservationMethodContainerMap[containerSequenceWithValue]}`
          )
          return
        }
      }
      // if no container or no preservation method -> just copy row
      dropdownIdsWithPreservationMethod.push(id)
    })
    // add component to pathology if it has containers
    if (shouldAddToPathology) {
      // generate sentence from terminology
      const descriptionParagraphs =
        component.dropdownIds &&
        findingsTerminology &&
        generateSlateBadgesFromIdValuePairs({
          idValuePairs: component.dropdownIds,
          rootNode: findingsTerminology,
          tkeysToExclude: ['container_number'],
        })

      pathologyComponents.push({
        dropdownIds: dropdownIdsWithPreservationMethod,
        text: slateUtils.toPlainText(
          descriptionParagraphs
            ? [
                {
                  type: 'list-item',
                  children: descriptionParagraphs.map((p) =>
                    generateParagraphFromBadges({ badges: p.badges })
                  ),
                },
              ]
            : undefined
        ),
      })
    }
  })

  pathology.components = pathologyComponents

  return pathologyComponents.length > 0
}

interface addComponentArgs {
  observationToChange?: IObservation
  payload: IAddComponent
  component?: ZipModuleCommonDtosComponentDto
}
export const addComponent = ({
  observationToChange,
  payload,
  component,
}: addComponentArgs): void => {
  if (observationToChange) {
    // add or edit component
    if (component) {
      const index = observationToChange.components.indexOf(component)
      if (index != -1) {
        observationToChange.components[index] = {
          dropdownIds: payload.idValuePairs,
          text: payload.text ? payload.text : observationToChange.components[index].text,
          images: observationToChange.components[index].images,
          isUpdated: true,
        }
      }
    } else {
      // find index to insert or set to array length (push as last element)
      let indexToInsert = observationToChange.components.length
      if (payload.insertOptions?.afterComponentWithIdStartingWith)
        indexToInsert =
          observationToChange.components.findIndex(
            (c) =>
              c.dropdownIds?.at(0) &&
              payload.insertOptions?.afterComponentWithIdStartingWith &&
              c.dropdownIds[0].startsWith(payload.insertOptions.afterComponentWithIdStartingWith)
          ) + 1

      if (payload.insertOptions?.atIndex !== undefined)
        indexToInsert = payload.insertOptions.atIndex

      observationToChange.components.splice(indexToInsert, 0, {
        dropdownIds: payload.idValuePairs,
        text: payload.text ? payload.text : '',
        images: [],
        isUpdated: true,
      })
    }
    observationToChange.isUpdated = true
  }
}

/**
 * Parse SlateJS value from string.
 * If parse fails, generate input from Structured Terminology.
 * If that fails, return empty SlateJS value.
 */
export const parseSlateValue = (
  observationFromBackend: ZipModuleCommonDtosObservationDto,
  rootTerminologyNode: ITermTreeNode
): [CustomDescendant, ...CustomDescendant[]] => {
  try {
    if (!observationFromBackend.descriptionSlateJs) throw Error()
    return JSON.parse(observationFromBackend.descriptionSlateJs)
  } catch (error) {
    if (
      // pathology (request) shouldn't be populated from terminology
      rootTerminologyNode.tKey?.toLowerCase() !== 'pathology' &&
      observationFromBackend.components &&
      observationFromBackend.components.length > 0
    ) {
      return observationFromBackend.components.map((component) => {
        //add only to rows in Medication. In the future this should be fetched from Terminology tree
        const valueInput =
          rootTerminologyNode.tKey?.toLowerCase() === 'medication'
            ? ({ type: 'number', suffix: 'mg' } as IValueInputType)
            : undefined
        return generateSlateListItemFromTerminology(component, rootTerminologyNode, valueInput)
      }) as [CustomDescendant, ...CustomDescendant[]]
    }

    return [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]
  }
}

export const generateSlateListItemFromTerminology = (
  item: ZipModuleCommonDtosComponentDto,
  rootNode: ITermTreeNode,
  valueInput?: IValueInputType
): CustomDescendant => {
  if (!item.dropdownIds)
    return {
      type: 'paragraph',
      children: [{ text: '' }],
    }

  const normalizedParagraphs: CustomBaseElement[] = [
    {
      id: item.dropdownIds[0] + '-paragraph-root',
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]

  // add badge for numeric input if observation has it
  const valueBadge: SlateBadgeElement | undefined =
    valueInput && item.text
      ? {
          id: item.dropdownIds[0] + '-text',
          label: 'value',
          type: 'badge',
          children: [{ text: `${item.text} ${valueInput.suffix}` }],
        }
      : undefined

  // generate badges & paragraph ids
  generateSlateBadgesFromIdValuePairs({
    idValuePairs: item.dropdownIds,
    rootNode,
    valueBadge,
    tkeysToExclude: HIDDEN_TERMINOLOGY_ITEMS,
  }).forEach(({ paragraphId, badges }, i) => {
    // create new paragraph if necessary
    if (i !== 0) {
      normalizedParagraphs.push({
        id: paragraphId,
        type: 'paragraph',
        children: [{ text: '' }],
      })
    }
    // add badges to paragraph
    badges.forEach((badge, j) => {
      // First badge doesn't have separator
      const separator = j === 0 ? { text: '' } : badge.separator ?? { text: ', ' }
      normalizedParagraphs[i].children.push(separator, badge)
    })
  })

  return {
    id: item.dropdownIds[0],
    type: 'list-item',
    children: normalizedParagraphs,
  }
}
