export const getNovaOSAppConfig = () => {
  const config = {
    AUTH_URL: process.env.REACT_APP_AUTH_SERVER_URL ?? '',
    AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_RESOURCE ?? '',
    AUTH_SILENT_CHECK: 'silent-check-sso.html',
    AUTH_LOGOUT_MINS: '60',

    MODULE_URL_INSIGHTS: process.env.REACT_APP_MODULE_URL_INSIGHTS ?? '',
    MODULE_URL_REPORT: process.env.REACT_APP_MODULE_URL_REPORT ?? '',
    MODULE_URL_USERADMIN: process.env.REACT_APP_MODULE_URL_USERADMIN ?? '',
    MODULE_LANDING_APP: process.env.REACT_APP_MODULE_LANDING_APP ?? '',
  }
  return config
}
