import {
  CheckCircleIcon,
  CloseCircleIcon,
  InfoCircleIcon,
  LogOutIcon,
  ModalType,
  WarningIcon,
} from '@novax/zip-frontend-library'
import React from 'react'

import colors from '../../../scss/colors.scss'

export const getIcon = (type: ModalType): React.ReactNode => {
  switch (type) {
    case ModalType.SUCCESS:
      return <CheckCircleIcon style={{ color: `${colors.green5}`, fontSize: '22px' }} />
    case ModalType.WARNING:
      return <WarningIcon style={{ color: `${colors.yellow7}`, fontSize: '22px' }} />
    case ModalType.ERROR:
      return <CloseCircleIcon style={{ color: `${colors.red6}`, fontSize: '22px' }} />
    case ModalType.INFO:
      return <InfoCircleIcon style={{ color: `${colors.blue5}`, fontSize: '22px' }} />
    case ModalType.LOGOUT:
      return <LogOutIcon style={{ color: `${colors.yellow7}`, fontSize: '22px' }} />
    default:
      return <CheckCircleIcon style={{ color: `${colors.green5}`, fontSize: '22px' }} />
  }
}
