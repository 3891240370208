import { LoadingIcon } from '@novax/zip-frontend-library'
import ReportSkeleton from 'features/previewReport/ReportSkeleton'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

export interface IPreviewReportLoadingProps {
  height?: number
  width?: number
  hideSpinner?: boolean
  isThumb: boolean
  isSidebar?: boolean
}

const PreviewReportLoading = ({
  height,
  width,
  hideSpinner,
  isThumb,
  isSidebar,
}: IPreviewReportLoadingProps) => {
  const { t } = useTranslation()

  //aspect ratio for A4 page is height/width = 1.414
  const calculatedHeight = useMemo(() => height ?? (width ?? 600) * 1.414, [height, width])
  const calculatedWidth = useMemo(() => width ?? (height ?? 800) / 1.414, [height, width])

  return (
    <div
      className={styles.loadingWrapper}
      style={{ width: `${calculatedWidth}px` }}
      id="nova-report-Preview-Report-Loading-root"
    >
      <ReportSkeleton height={calculatedHeight} isThumb={isThumb} isSidebar={isSidebar} />
      {!hideSpinner && (
        <div className={styles.spinnerContainer}>
          <LoadingIcon />
          <p className={styles.loadingText}>{t('previewReports.generatingPreview')}</p>
        </div>
      )}
    </div>
  )
}
export default PreviewReportLoading
