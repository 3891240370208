export interface AnnotationItemSelected {
  key: string
  selected: string
}

export interface IMST {
  id: string
  type: string
  value: string
  inputType?: number | string
  snowmed?: number | string
  children: IMST[]
}

export interface IParameter {
  id: string
  type: string
  value: string
  label?: string
}

export interface ICurrentParameter {
  id: string
  type: string
  value: string
  label: string
}

export interface IFHIRComponent {
  code: IFHIRCodableConcept
  valueString: string
}

export interface IFHIRCodableConcept {
  coding: IFHIRCoding[]
  text: string
}

export interface IFHIRCoding {
  system: string
  version: string
  code: string
  display: string
  userSelected: string
}

import 'i18next'
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false // without this all translations can be null which causes errors
  }
}

export enum Role {
  DOCTOR = 'novax-module/report/doctor',
  NURSE = 'novax-module/report/nurse',
}

export enum CombinedStatus {
  NotStarted = 0, // ServiceRequest status = draft, Procedure status = preparation
  InProgress = 1, // ServiceRequest status = active, Procedure status = in-progress
  Finished = 2, // ServiceRequest status = active, Procedure status = completed
  Submitted = 3, // ServiceRequest status = completed, Procedure status = completed
  Unknown = 4, // Any other combination of statuses, not supported.
}

export enum InternalStatus {
  Created = 0,
  NurseStart = 1,
  NurseEnd = 2,
  DoctorStart = 3,
  DoctorEnd = 4,
  DoctorEdit = 5,
  Unknown = 6,
}

export enum InputType {
  MultiSelect = 0,
  Checkbox = 1,
  Number = 2,
}
