import { Tabs } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getCombinedSections,
  getLastCardDeleted,
  getObservations,
  setFindingToHighlight,
  setIsContainerToggleDisabled,
  setShowContainersOnly,
  setTabChanged,
} from 'features/dataCapture/dataCaptureSlice'
import { checkIfSectionIncludesBiopsyContainer } from 'features/dataCapture/utils/checkIfAnyBiopsyContainersExist'
import { findSectionFindings } from 'features/dataCapture/utils/utils'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ContainersToggle from './containersToggle/ContainersToggle'
import styles from './styles.module.scss'

const { TabPane } = Tabs

interface ISectionMenu {
  children: ReactNode
}

const SectionMenu = ({ children }: ISectionMenu) => {
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const combinedSections = useAppSelector(getCombinedSections)
  const observations = useAppSelector(getObservations)
  const isLastCardDeleted = useAppSelector(getLastCardDeleted)
  const [animatedTabs, setAnimatedTabs] = useState(false)
  const [activeKey, setActiveKey] = useState(params.sectionTKey?.replace('section_', ''))

  useEffect(() => {
    calculateContainerToggleState(
      params.sectionTKey ? params.sectionTKey?.replace('section_', '') : 'all'
    )
    return () => {
      // cleanup tab change in store
      dispatch(
        setTabChanged({
          previous: null,
          current: null,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (params.sectionTKey !== 'section_all' && isLastCardDeleted) {
      navigate(`/data-capture/${params.id}/section_all`, {
        replace: true,
      })
    }
  }, [isLastCardDeleted])

  const handleTabClick = (activeKey: string) => {
    setActiveKey(activeKey)
    setAnimatedTabs(true)
    dispatch(
      setTabChanged({
        previous: params.sectionTKey?.replace('section_', '') ?? '',
        current: activeKey,
      })
    )

    setTimeout(
      () => {
        const section = combinedSections.find(
          (sec) => sec.tKey?.toLowerCase() === activeKey.toLowerCase()
        )
        const sectionTKey = section?.tKey
        calculateContainerToggleState(activeKey)
        navigate(`/data-capture/${params.id}/section_${sectionTKey}`, {
          replace: true,
        })
        dispatch(setFindingToHighlight({ id: null, newlyCreated: false }))
      },
      params.sectionTKey?.replace('section_', '') === 'all' ? 200 : 0
    )
  }

  const calculateContainerToggleState = (activeKey: string) => {
    //const activeKey = params.sectionTKey ? params.sectionTKey?.replace('section_', '') : 'all'
    const section = combinedSections.find(
      (sec) => sec.tKey?.toLowerCase() === activeKey.toLowerCase()
    )
    // section was not found and section from url params is not sectional all redirect to section all
    if (!section && activeKey !== 'all') {
      navigate(`/data-capture/${params.id}/section_all`, {
        replace: true,
      })
    }

    // on every tab/section change we check if that section contains containers, if not disable them
    const sectionHasBiopsyContainer = checkIfSectionIncludesBiopsyContainer(
      observations,
      section,
      t
    ).includes(true)
    dispatch(setIsContainerToggleDisabled(!sectionHasBiopsyContainer))

    if (!sectionHasBiopsyContainer) {
      dispatch(setShowContainersOnly(false))
    }
  }

  return (
    <Tabs
      data-testid="section-menu-tabs-root"
      onTabClick={handleTabClick}
      activeKey={activeKey}
      animated={{ inkBar: animatedTabs, tabPane: false }}
      className={styles.tabs}
      tabBarExtraContent={<ContainersToggle />}
    >
      {combinedSections.map((section) => {
        return (
          <TabPane
            key={section.tKey}
            tabKey={section.tKey ?? ''}
            tab={
              section.tKey?.toLowerCase() === 'all'
                ? t(`dataCapture.sections.${section.label?.toLowerCase()}`)
                : section.label
            }
            disabled={section.key !== 'all' && !findSectionFindings(section, observations)}
          >
            {children}
          </TabPane>
        )
      })}
    </Tabs>
  )
}

export default SectionMenu
