import {
  DownloadIcon,
  EditIcon,
  Header,
  LoadingIcon,
  PrinterIcon,
  UnorderedListIcon,
  UploadIcon,
} from '@novax/zip-frontend-library'
import { Button } from 'antd'
import BackButton from 'components/BackButton'
import InDevelopmentTag from 'components/inDevelopmentTag/InDevelopmentTag'
import { isMobileDevice } from 'features/previewReport/utils/pdfUtils'
import { getBasicInformation } from 'features/reportDetails/reportDetailsSlice'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CombinedStatus } from 'types'

import { useAppSelector } from '../../../../app/hooks'
import { getIsReportSubmitting, getPdfLoadStatus } from '../../previewReportSlice'
import styles from './styles.module.scss'

export interface PreviewReportHeaderProps {
  onPrintClick?: () => void
  onSaveToHIS?: () => void
  onDownloadClick?: () => void
  removeCTAButton?: boolean
}

const PreviewReportHeader = ({
  onSaveToHIS,
  onPrintClick,
  onDownloadClick,
  removeCTAButton,
}: PreviewReportHeaderProps) => {
  const pdfLoadStatus = useAppSelector(getPdfLoadStatus)
  const isReportSubmitting = useAppSelector(getIsReportSubmitting)
  const report = useAppSelector(getBasicInformation)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { id } = useParams() as { id: string }
  const isLoading = ['loading', 'not started'].includes(pdfLoadStatus)

  const isEditPreview = location.pathname.endsWith('/submitted-preview')
  const isSavedToHIS = report?.combinedStatus === CombinedStatus.Submitted

  return (
    <Header>
      <div className={styles.previewReportWrapper} id="nova-report-Preview-Report-Header-root">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BackButton
              label={t(
                isEditPreview ? 'previewReports.backToProcedures' : 'previewReports.backToReport'
              )}
              onClick={() => navigate(isEditPreview ? '/procedures' : `/report-details/${id}`)}
            />
            <InDevelopmentTag marginLeft={false} />
          </div>
        </div>

        <div className={styles.rightSideWrapper}>
          <Button
            data-testid="preview-report-download-button"
            icon={<DownloadIcon style={{ color: 'white', fontSize: '24px' }} />}
            ghost
            className={styles.borderTransparent}
            disabled={pdfLoadStatus === 'error' || !report}
            onClick={onDownloadClick}
          />
          {!isMobileDevice() && (
            <Button
              data-testid="preview-report-print-button"
              icon={<PrinterIcon style={{ color: 'white', fontSize: '24px' }} />}
              ghost
              className={styles.borderTransparent}
              disabled={pdfLoadStatus === 'error' || !report}
              onClick={onPrintClick}
            />
          )}
          {!isEditPreview && !removeCTAButton && (
            <Button
              data-testid="preview-report-submit-button"
              className={`${styles.sideBarButton} ${isLoading ? styles.sideBarButtonLoading : ''}`}
              onClick={() => !isLoading && onSaveToHIS && onSaveToHIS()}
              icon={!isLoading ? isReportSubmitting ? <LoadingIcon /> : <UploadIcon /> : null}
              disabled={pdfLoadStatus === 'error' || !report}
              data-cy="preview-report-print-button"
              id="preview-report-print-button"
            >
              {!isLoading
                ? isReportSubmitting
                  ? t('previewReports.savingReport')
                  : isSavedToHIS
                  ? t('previewReports.updateReport')
                  : t('previewReports.saveReport')
                : null}
            </Button>
          )}
          {/* If user updating show back to procedures button*/}
          {removeCTAButton && !isEditPreview && (
            <Button
              className={`${styles.sideBarButton} ${isLoading ? styles.sideBarButtonLoading : ''}`}
              onClick={() => !isLoading && navigate('/procedures')}
              icon={!isLoading && <UnorderedListIcon />}
              disabled={pdfLoadStatus === 'error' || !report}
              data-cy="preview-report-print-button"
              id="preview-report-print-button"
            >
              {!isLoading ? t('previewReports.seeProcedures') : null}
            </Button>
          )}
          {isEditPreview && (
            <Button
              className={`${styles.sideBarButtonWhite} ${
                isLoading ? styles.sideBarButtonLoading : ''
              }`}
              onClick={() => !isLoading && navigate(`/report-details/${id}`)}
              icon={!isLoading && <EditIcon />}
              disabled={!report}
              data-cy="preview-report-print-button"
              id="preview-report-print-button"
            >
              {!isLoading ? t('previewReports.editReport') : null}
            </Button>
          )}
        </div>
      </div>
    </Header>
  )
}

export default PreviewReportHeader
