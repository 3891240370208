import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'utils/useWindowDimensions'

import styles from './styles.module.scss'
interface IProps {
  marginLeft?: boolean
  autoHeight?: boolean
  headerStyle?: boolean
}
function InDevelopmentTag({ marginLeft = true, autoHeight = false, headerStyle }: IProps) {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  return (
    <div
      className={` ${headerStyle ? styles.headerStyle : styles.developmentTag} 
    ${autoHeight ? styles.autoHeight : ''} ${marginLeft ? styles.marginLeft : ''}`}
      id="nova-report-In-Development-Tag-root"
      data-testid="nova-report-In-Development-Tag"
    >
      {width < 920 ? t('common.inDevelopmentShort') : t('common.inDevelopment')}
    </div>
  )
}

export default InDevelopmentTag
