import { NotificationType, notify } from '@novax/zip-frontend-library'
import { notification } from 'antd'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getContinueEditingModalConfig,
  getDoctorStartedEndDocumentationModalConfig,
  getErrorSubmittingModalConfig,
} from 'utils/modalConfigs'

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useGlobalModalContext } from '../../../components/GlobalModalProvider/GlobalModalProvider'
import {
  usePutApiDataCaptureByIdMutation,
  usePutApiProceduresByIdUpdateInternalStatusMutation,
  ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
} from '../../../services/zipmodule.gen'
import { InternalStatus } from '../../../types'
import {
  ProcedureStatusChangedMessage,
  SignalRMessageTypes,
} from '../../connect/signalR/MessageTypes'
import SignalRService from '../../connect/signalR/SignalRService'
import {
  getBasicInformation,
  getDisplayNotification,
  getObservations,
  setDisplayNotification,
  setIsStopWatchRunning,
  setIsSubmitLoading,
  setProcedureStatus,
} from '../dataCaptureSlice'
import { NotificationTypes } from '../enum/notificationTypes'

const DataCaptureStatusChangeWrapper = forwardRef(
  (
    { children }: { children: React.ReactNode },
    ref: ForwardedRef<{
      endDocumentation: () => void
      setNurseStarted: () => void
    }>
  ) => {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const observations = useAppSelector(getObservations)
    const displayNotification = useAppSelector(getDisplayNotification)
    const basicInformation = useAppSelector(getBasicInformation)
    const { t } = useTranslation()
    const { showModal, hideAsyncModal } = useGlobalModalContext()
    const [submitDataCapture] = usePutApiDataCaptureByIdMutation()
    const [updateInternalStatus] = usePutApiProceduresByIdUpdateInternalStatusMutation()

    notification.config({
      maxCount: 1,
    })

    useEffect(() => {
      const handleCallback = function handleProcedureStatusChangedInDCOverview(
        message: ProcedureStatusChangedMessage
      ) {
        if (message.serviceRequestId == params.id) {
          dispatch(
            setProcedureStatus({
              status: message.status,
              internalStatus: message.internalStatus,
            })
          )
        }
      }

      SignalRService.addMessageListener(SignalRMessageTypes.ProcedureStatusChanged, handleCallback)

      return () => {
        SignalRService.removeMessageListener(
          SignalRMessageTypes.ProcedureStatusChanged,
          handleCallback
        )
      }
    }, [])

    useEffect(() => {
      switch (displayNotification) {
        case NotificationTypes.procedureFinishedDocumentationFinished:
          notify({
            type: NotificationType.INFO,
            message: t('dataCapture.notifications.procedureFinishedDocumentationFinished.title'),
            description: t(
              'dataCapture.notifications.procedureFinishedDocumentationFinished.content'
            ),
          })
          break
        case NotificationTypes.procedureActiveDocumentationFinished:
          notify({
            type: NotificationType.INFO,
            message: t('dataCapture.notifications.procedureActiveDocumentationFinished.title'),
            description: t(
              'dataCapture.notifications.procedureActiveDocumentationFinished.content'
            ),
          })
          break
        case NotificationTypes.procedureDoctorStarted:
          showModal(
            getDoctorStartedEndDocumentationModalConfig({
              t,
              handleOk: () => {
                endDocumentationHandle()
              },
            })
          )
          break
        case NotificationTypes.procedureFinishedDocumentationStarted:
          showModal(
            getContinueEditingModalConfig({
              t,
              handleOk: () => {
                hideAsyncModal()
              },
              handleCancel: () => {
                endDocumentationHandle().then(() => {
                  params.id &&
                    updateInternalStatus({
                      id: params.id,
                      zipModuleFeaturesProceduresUpdateInternalStatusCommand: {
                        serviceRequestId: params.id,
                        internalStatus: InternalStatus.NurseEnd,
                      },
                    })
                })
              },
            })
          )
          break
        default:
      }
      dispatch(setDisplayNotification(null))
    }, [displayNotification])

    const endDocumentationHandle = async () => {
      const unixTimestampString = localStorage.getItem(
        basicInformation.procedureNumber + '-stopwatchStartTime'
      )
      const targetTime = unixTimestampString && new Date(parseInt(unixTimestampString))
      const timeDifference = (targetTime && new Date().getTime() - targetTime.getTime()) || 0

      dispatch(setIsStopWatchRunning(false))
      localStorage.removeItem(basicInformation.procedureNumber + '-stopwatchStartTime')

      const formData: ZipModuleFeaturesDataCaptureSaveDataCaptureCommand = {
        serviceRequestId: params.id ?? '',
        dataCapture: {
          observations: observations,
          basicInformation: { ...basicInformation, reportWritingTime: timeDifference },
        },
      }
      dispatch(setIsSubmitLoading(true))

      submitDataCapture({
        zipModuleFeaturesDataCaptureSaveDataCaptureCommand:
          formData as ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
        id: params.id ?? '',
      })
        .then(() => {
          if (localStorage.getItem(basicInformation?.procedureNumber + '-stopwatchStartTime')) {
            localStorage.removeItem(basicInformation?.procedureNumber + '-stopwatchStartTime')
          }
          hideAsyncModal()
          navigate('/procedures')
        })
        .catch(() => {
          showModal(getErrorSubmittingModalConfig({ t }))
        })
        .finally(() => {
          // Always set isSubmitLoading to false
          dispatch(setIsSubmitLoading(false))
        })
    }

    useImperativeHandle(ref, () => ({
      endDocumentation: () => {
        endDocumentationHandle().then(() => {
          params.id &&
            updateInternalStatus({
              id: params.id,
              zipModuleFeaturesProceduresUpdateInternalStatusCommand: {
                serviceRequestId: params.id,
                internalStatus: InternalStatus.NurseEnd,
              },
            })
        })
      },
      setNurseStarted: () => {
        params.id &&
          updateInternalStatus({
            id: params.id,
            zipModuleFeaturesProceduresUpdateInternalStatusCommand: {
              serviceRequestId: params.id,
              internalStatus: InternalStatus.NurseStart,
            },
          })
            .then(() => {
              dispatch(
                setProcedureStatus({
                  status: null,
                  internalStatus: InternalStatus.NurseStart,
                })
              )
            })
            .catch(() => {
              // Error handling
              showModal(getErrorSubmittingModalConfig({ t }))
            })
      },
    }))

    return <>{children}</>
  }
)
DataCaptureStatusChangeWrapper.displayName = 'DataCaptureStatusChangeWrapper'

//export default ChildComponent;

export default DataCaptureStatusChangeWrapper
