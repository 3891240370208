import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'

export const getNodeFromDropdownId = (
  terminologyWorkflowStep: ITermTreeNode,
  dropdownId: string
): ITermTreeNode | undefined => {
  let result: ITermTreeNode | undefined = terminologyWorkflowStep
  dropdownId.split(',').forEach((id) => {
    if (result) result = result.children.find((n) => n.id === id)
  })
  return result
}
