import { Button } from 'antd'
import NovaIcon from 'components/NovaIcon'
import { setDiscardModalVariant } from 'features/dataCapture/dataCaptureSlice'
import { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { normalizeTKeyParams, tKeysMatch } from '../../../../../utils/URLhelper'
import styles from './styles.module.scss'

const DataCaptureAddButtonItem = (props: {
  id?: string
  label?: string | null
  align?: 'left' | 'right'
  sectionTKey?: string | null
  terminologyTKey?: string | null
  terminologyChildTKey?: string | null
  onClick?: () => void
  onOutsideClick?: (event: MouseEvent) => void
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleButtonClick = () => {
    if (
      props.sectionTKey &&
      props.sectionTKey.length > 0 &&
      props.terminologyTKey &&
      props.terminologyTKey.length > 0
    ) {
      // determine section
      const section = !tKeysMatch(params.sectionTKey, 'section_' + props.sectionTKey)
        ? 'section_all'
        : /* istanbul ignore next: unreachable code */
        params.sectionTKey?.toLowerCase() === 'section_all'
        ? params.sectionTKey
        : 'section_' + normalizeTKeyParams(props.sectionTKey)

      let url = `/data-capture/${props.id}/create-edit/${section}/${normalizeTKeyParams(
        props.terminologyTKey
      )}`

      /* istanbul ignore next: code possibly unreachable */
      if (props.terminologyChildTKey) {
        url += `/${normalizeTKeyParams(props.terminologyChildTKey)}`
      }

      dispatch(setDiscardModalVariant('create'))
      navigate(url, { replace: true })
    }

    props.onClick && props.onClick()
  }

  const handleOutsideClick = (event: MouseEvent) => {
    props.onOutsideClick && props.onOutsideClick(event)
  }

  return (
    <div
      className={props.align === 'right' ? styles.buttonsContainer : styles.buttonsContainerLeft}
      onClick={handleOutsideClick}
      id="nova-report-Data-Capture-Add-Button-Item-root"
    >
      <div
        className={
          props.align === 'right' ? styles.buttonInnerContainer : styles.buttonInnerContainerLeft
        }
        id="nova-report-Data-Capture-Add-Button-Item-button"
        data-testid="nova-report-Data-Capture-Add-Button-Item-button"
        onClick={handleButtonClick}
      >
        <div className={styles.buttonLabel}>{props.label}</div>
        <Button
          shape="circle"
          size="large"
          className={styles.terminologyButton}
          type="default"
          icon={
            <NovaIcon tkey={props.terminologyChildTKey || props.terminologyTKey} iconSize="24px" />
          }
        />
      </div>
    </div>
  )
}

export default DataCaptureAddButtonItem
