import { RadioButton } from '@novax/zip-frontend-library'
import { useAppSelector } from 'app/hooks'
import { ReactComponent as Indicator } from 'assets/svg/indicator.svg'
import { getFindingForm, getObservations } from 'features/dataCapture/dataCaptureSlice'
import {
  joinTKeyParams,
  normalizeTKeyParams,
  tKeysMatch,
} from 'features/dataCapture/utils/URLhelper'
import { useParams } from 'react-router-dom'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import styles from '../../styles.module.scss'
import { checkIfContainerExist } from '../../util/containersChildrenSize'

interface IProps {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  parentTkey: string
  option: ZipModuleTerminologyCommonTerminologyNodeDto
  disabled?: boolean
  showIndicator?: boolean
  setFormData: (
    id: string,
    keyName: string,
    sequenceFromRoot: string,
    inputType: string,
    error?: string[]
  ) => void
}

const Finding = ({ finding, option, disabled, setFormData, parentTkey }: IProps) => {
  const findingForm = useAppSelector(getFindingForm)
  const params = useParams()
  const workflowTKey = params.workflowTKey
  const observations = useAppSelector(getObservations)
  const checkRadioSelection = (keyName: string, option: string): boolean => {
    const form = findingForm.form[keyName as keyof typeof findingForm.form]
    return option === form?.toString()
  }

  const showIndicator = (
    finding: ZipModuleTerminologyCommonTerminologyNodeDto,
    option: ZipModuleTerminologyCommonTerminologyNodeDto
  ) => {
    if (normalizeTKeyParams(finding.tKey) == 'container_number') {
      // we dont want to put indicator on No Container
      if (normalizeTKeyParams(option.tKey) != 'no_container') {
        // if container is used put indicator on
        if (
          option.id &&
          checkIfContainerExist(
            option.id,
            observations?.find((o) => tKeysMatch(o.tKey, workflowTKey)) ?? null
          )
        ) {
          return true
        }
      } else {
        return false
      }
    }
    // default return false, dont put indicators
    return false
  }

  const renderLabel = (
    finding: ZipModuleTerminologyCommonTerminologyNodeDto,
    option: ZipModuleTerminologyCommonTerminologyNodeDto
  ) => {
    if (finding.tKey?.toLowerCase() === 'container_number') {
      // we don't want to put an indicator on No Container
      if (option.tKey?.toLowerCase() === 'no_container') {
        return option.value || ''
      } else {
        return option.value?.replace(/\D/g, '') || ''
      }
    } else {
      return option.value || ''
    }
  }

  const isChecked = () => {
    return checkRadioSelection(
      (option.tKey && joinTKeyParams([parentTkey, option.tKey])) ?? '',
      option.sequenceFromRoot?.split(',').slice(1).join(',') ?? ''
    )
  }

  return (
    <div className={styles.finding} id="nova-report-Finding-root">
      {showIndicator(finding, option) && (
        <span className={styles.indicator}>
          <Indicator />
        </span>
      )}
      <RadioButton
        key={`${finding.value}-${parentTkey}`}
        name={`${finding.value}-${parentTkey}`}
        data-was-checked={isChecked()}
        checked={isChecked()}
        disabled={disabled}
        value={option?.id ?? ''}
        onClick={() =>
          setFormData(
            finding.id ?? '',
            (parentTkey && option.tKey && joinTKeyParams([parentTkey, option.tKey])) ?? '',
            option.sequenceFromRoot?.split(',').slice(1).join(',') ?? '',
            finding?.inputType ?? ''
          )
        }
        readOnly
        spanValue={renderLabel(finding, option)}
        ischeckboxvisible={finding.inputType === 'MultiSelect' ? 'true' : 'false'}
      />
    </div>
  )
}
/* eslint-enable  @typescript-eslint/no-non-null-assertion */

export default Finding
