import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { normalizeTKeyParams, splitTKeyParams } from 'features/dataCapture/utils/URLhelper'
import {
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

/**
 *
 * Return weather given terminology finding needs to limit its container size
 *
 * @param findingTKey string termonology key tKey
 */
export const shouldLimitContainerChildrenSize = (findingTKey: string): boolean => {
  return ['container_number'].includes(normalizeTKeyParams(findingTKey))
}

/**
 *
 * Return weather given terminology finding needs to limit its number of biopsies size
 *
 * @param findingTKey string termonology key tKey
 */
export const shouldLimitNumberOfBiopsiesChildrenSize = (findingTKey: string): boolean => {
  return ['number_of_biopsies'].includes(normalizeTKeyParams(findingTKey))
}

/**
 *
 * Resolves to a max index of a selected workflow child. Used to determine
 * number of options to show, when we limit workflow container size.
 *
 * @param findingForm findingForm object from localStorage
 * @param keyName currently selected workflow keyName
 * @param workflow currently selected workflow object
 */
;[]
export const determineContainerMaxNumber = (
  containerSequence: string,
  observation: ZipModuleCommonDtosObservationDto | null,
  terminology: ZipModuleTerminologyCommonTerminologyNodeDto | null
): number | null => {
  const allContainersArray: string[] = []
  // we are looking for all containers in observation findings
  // all containers have same sequence
  observation?.components?.forEach((component) => {
    component.dropdownIds?.forEach((id) => {
      id && id.includes(containerSequence) ? allContainersArray.push(id) : null
    })
  })
  // we need to find containers values (from Container 1 to 30)
  // all containers in terminology have same sequences and values and we can use any
  const containers = terminology?.children
    ?.find((t) => normalizeTKeyParams(t.tKey) == 'polyp')
    ?.children?.find((c) => normalizeTKeyParams(c.tKey) == 'container_number')?.children

  const containerValues: number[] = []
  allContainersArray.forEach((id) => {
    const findContainerValueSequence = id.split(containerSequence + ',')
    if (findContainerValueSequence?.length > 0) {
      const containerValueSequence = containers?.find(
        (c) => c.id == findContainerValueSequence[1]
      )?.value
      if (containerValueSequence) {
        containerValues.push(Number(containerValueSequence.replace(/^\D+/g, '')))
      }
    }
  })
  if (!containers) {
    console.error(
      "Can't find Containers in Polyp, can't conclude the biggest container Value, showing all 20 Container values. " +
        'Observation ' +
        terminology?.tKey +
        ' has to have child polyp with child container_number which have Container Values array'
    )
    return 20
  }

  return Math.max(...containerValues)
}

// we are checking on observation level if for ex. Container 3 exists
// containers for ex. in GB and Poylp are the same containers in procedure room
// if Container 3 is used in General Biopsy, we color it when adding Poylp containers
export const checkIfContainerExist = (
  containerSequence: string,
  observation: ZipModuleCommonDtosObservationDto | null
): boolean => {
  let containerExists = false
  // we are looking for all containers in observation findings
  // all containers have same sequence
  observation?.components?.forEach((component) => {
    component.dropdownIds?.forEach((id) => {
      if (id && id.includes(containerSequence)) {
        containerExists = true
        return
      }
    })
  })
  return containerExists
}

export const determineNumberOfBiopsiesMaxNumber = (findingForm: IFindingForm) => {
  const numberOfBiopsiesSelected: number[] = []
  Object.keys(findingForm).forEach((key) => {
    if (splitTKeyParams(key)?.length > 3 && splitTKeyParams(key)[2] == 'number_of_biopsies') {
      numberOfBiopsiesSelected.push(Number(splitTKeyParams(key)[3]))
    }
  })
  return Math.max(...numberOfBiopsiesSelected)
}
