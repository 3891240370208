import { ModalType } from '@novax/zip-frontend-library'

interface justTArgs {
  t: (label: string) => string
  title?: string
  content?: string
  okText?: string
  cancelText?: string
}
interface handleOkArgs extends justTArgs {
  handleOk: () => void
}
interface handleOkAndCancelArgs extends handleOkArgs {
  handleCancel: () => void
}

interface handleOkWithVariantAndAsyncArgs extends handleOkArgs {
  modalVariant: 'edit' | 'create' | 'duplicate'
  isAsync?: boolean
}

/***
 *
 * Retrieve error modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 * @param handleCancel callback function for modal cancel button click event
 */
export const getErrorFetchingModalConfig = ({
  t,
  handleOk,
  handleCancel,
}: handleOkAndCancelArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.errorFetching.title'),
      content: t('dataCapture.modals.errorFetching.content'),
      okText: t('common.retry'),
      cancelText: t('common.goBack'),
      onOk: handleOk,
      onCancel: handleCancel,
    },
  }
}

/***
 *
 * Retrieve error modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 */
export const getErrorSubmittingModalConfig = ({ t }: justTArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.errorSubmitting.title'),
      content: t('dataCapture.modals.errorSubmitting.content'),
      okText: t('common.ok'),
      cancelText: t('common.goBack'),
    },
  }
}

/***
 *
 * Retrieve error modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal

 export const getLeaveWarningModalConfig = (t: any) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('pathology.leavingProcedureTitle'),
      content: t('pathology.leavingProcedureContent'),
      okText: t('pathology.leavingProcedureOkBtnLabel'),
      cancelText: t('pathology.leavingProcedureCancelBtnLabel'),
    },
  }
}*/

/***
 *
 * Retrieve delete modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getDeleteModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.ERROR,
    modalProps: {
      title: t('dataCapture.modals.deleteFinding.title'),
      content: t('dataCapture.modals.deleteFinding.content'),
      okText: t('dataCapture.modals.deleteFinding.okText'),
      cancelText: t('dataCapture.modals.deleteFinding.cancelText'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Retrieve delete modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getDeleteModalFindingConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.ERROR,
    modalProps: {
      title: t('reportDetails.indicationPart.deleteModal.title'),
      content: t('reportDetails.indicationPart.deleteModal.description'),
      okText: t('reportDetails.indicationPart.deleteModal.confirmButton'),
      cancelText: t('reportDetails.indicationPart.deleteModal.backButton'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Retrieve delete modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getEditModalFindingConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('reportDetails.editingModal.title'),
      content: t('reportDetails.editingModal.description'),
      okText: t('common.changeAnyway'),
      cancelText: t('common.goBack'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}
/***
 *
 * Retrieve confirm changes modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getConfirmChangesModalFindingConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('reportDetails.confirmChangesModal.title'),
      content: t('reportDetails.confirmChangesModal.content'),
      okText: t('reportDetails.confirmChangesModal.confirmButton'),
      cancelText: t('reportDetails.confirmChangesModal.cancelButton'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Retrieve delete modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 * @param modalVariant
 * @param isAsync
 */
export const getDiscardModalConfig = ({
  t,
  handleOk,
  modalVariant,
  isAsync,
}: handleOkWithVariantAndAsyncArgs) => {
  let contentKey: string

  if (modalVariant === 'edit') {
    contentKey = 'editContent'
  } else if (modalVariant === 'duplicate') {
    contentKey = 'duplicateContent'
  } else {
    contentKey = 'content'
  }

  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.discardSelection.title'),
      content: t(`dataCapture.modals.discardSelection.${contentKey}`),
      okText: t('dataCapture.modals.discardSelection.okText'),
      cancelText: t('dataCapture.modals.discardSelection.cancelText'),
      onOk: handleOk,
    },
    mode: isAsync ? 'async' : undefined,
  }
}

/***
 *
 * Retrieve end procedure modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getEndProcedureModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.SUCCESS,
    modalProps: {
      title: t('dataCapture.modals.endProcedure.title'),
      content: t('dataCapture.modals.endProcedure.content'),
      okText: t('dataCapture.modals.endProcedure.okText'),
      cancelText: t('dataCapture.modals.endProcedure.cancelText'),
      onOk: handleOk,
      width: '440px',
    },
    mode: 'async',
  }
}

/***
 *
 * Retrieve leave procedure modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 */
export const getLeaveProcedureModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.leaveProcedure.title'),
      content: t('dataCapture.modals.leaveProcedure.content'),
      okText: t('dataCapture.modals.leaveProcedure.okText'),
      cancelText: t('dataCapture.modals.leaveProcedure.cancelText'),
      onOk: handleOk,
    },
  }
}

/***
 *
 * Retrieve continue-editing details modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleOk callback function for modal ok button click event
 * @param handleCancel callback function for modal cancel button click event
 */
export const getContinueEditingModalConfig = ({
  t,
  handleOk,
  handleCancel,
}: handleOkAndCancelArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.continueEditingDetails.title'),
      content: t('dataCapture.modals.continueEditingDetails.content'),
      okText: t('dataCapture.modals.continueEditingDetails.okText'),
      cancelText: t('dataCapture.modals.continueEditingDetails.cancelText'),
      onCancel: handleCancel,
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Retrieve doctor took over - end documentation modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getDoctorStartedEndDocumentationModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('dataCapture.modals.doctorStartedEndDocumentation.title'),
      content: t('dataCapture.modals.doctorStartedEndDocumentation.content'),
      okText: t('dataCapture.modals.doctorStartedEndDocumentation.okText'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Leave image section modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getLeaveImageSectionModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('imagesSection.leaveSectionModal.title'),
      content: t('imagesSection.leaveSectionModal.description'),
      okText: t('imagesSection.leaveSectionModal.confirmButton'),
      cancelText: t('imagesSection.leaveSectionModal.backButton'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}
/***
 *
 * Delete image modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getDeleteImageModalConfig = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('reportDetails.deleteImageModal.title'),
      content: t('reportDetails.deleteImageModal.description'),
      okText: t('reportDetails.deleteImageModal.confirmButton'),
      cancelText: t('reportDetails.deleteImageModal.cancelButton'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Pairing error modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getFetchingDataErrorModal = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('devicePairing.failedFetchingDataTitle'),
      content: t('devicePairing.failedFetchingData'),
      okText: t('common.ok'),
      cancelText: t('common.goBack'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Logout user modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getLogoutUserModal = ({ t, handleOk }: handleOkArgs) => {
  return {
    modalType: ModalType.LOGOUT,
    modalProps: {
      title: t('procedureOverview.logoutText'),
      content: '',
      okText: t('procedureOverview.logoutOkButton'),
      cancelText: t('procedureOverview.logoutCancelButton'),
      onOk: handleOk,
    },
    mode: 'async',
  }
}

/***
 *
 * Pairing error modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getPairingErrorModal = ({ t }: justTArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('devicePairing.pairingErrorTitle'),
      content: t('devicePairing.pairingErrorDescription'),
    },
    mode: 'async',
  }
}

/***
 *
 * Report save failed modal config
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 */
export const getReportErrorModalConfig = ({
  title,
  content,
  okText,
  cancelText,
  handleOk,
  handleCancel,
}: handleOkAndCancelArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: title,
      content: content,
      okText: okText,
      cancelText: cancelText,
      onOk: handleOk,
      onCancel: handleCancel,
    },
    mode: 'async',
  }
}

/***
 *
 * Doctor continue documentation (after prefill report) modal
 *
 * @param t react translation hook (gained by calling useTranslation) in the component that invokes the modal
 * @param handleCancel callback function for modal cancel button click event
 * @param handleOk callback function for modal ok button click event
 */
export const getContinueDocumentationModalConfig = ({
  t,
  handleCancel,
  handleOk,
}: handleOkAndCancelArgs) => {
  return {
    modalType: ModalType.WARNING,
    modalProps: {
      title: t('reportDetails.continueDocumentationModal.title'),
      content: t('reportDetails.continueDocumentationModal.description'),
      okText: t('reportDetails.continueDocumentationModal.confirmButton'),
      cancelText: t('reportDetails.continueDocumentationModal.cancelButton'),
      onOk: handleOk,
      onCancel: handleCancel,
    },
  }
}
