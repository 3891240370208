import { NovaNavigation } from '@novax/os'
import InDevelopmentTag from 'components/inDevelopmentTag/InDevelopmentTag'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

// TODO: Remove this when OS wrapper exports it
type LogoutOptions = {
  redirectUri: string
}

interface CustomNovaNavigationProps {
  showInDevelopmentTag?: boolean
}

const CustomNovaNavigation = ({ showInDevelopmentTag }: CustomNovaNavigationProps) => {
  const { t } = useTranslation()

  /* istanbul ignore next: out of test scope */
  const handleLogout = (logout: (config?: LogoutOptions | undefined) => void) => {
    localStorage.removeItem('i18nextLng')
    logout()
  }

  return (
    <NovaNavigation
      appTitle={t('common.novaReport')}
      className={styles.customNovaNavigationHeader}
      onLogout={handleLogout}
    >
      {showInDevelopmentTag && <InDevelopmentTag marginLeft={false} />}
    </NovaNavigation>
  )
}
export default CustomNovaNavigation
