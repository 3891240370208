import { useAppDispatch, useAppSelector } from 'app/hooks'
import { findSectionFindings } from 'features/dataCapture/utils/utils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getErrorFetchingModalConfig, getErrorSubmittingModalConfig } from 'utils/modalConfigs'

import { useGlobalModalContext } from '../../../../components/GlobalModalProvider/GlobalModalProvider'
import {
  useGetApiDataCaptureByIdQuery,
  usePutApiDataCaptureByIdMutation,
  ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
} from '../../../../services/zipmodule.gen'
import { InternalStatus } from '../../../../types'
import DataCaptureStatusChangeWrapper from '../../component/DataCaptureStatusChangeWrapper'
import {
  getBasicInformation,
  getIsProcedureFinished,
  getObservations,
  getReadyToDelete,
  getSections,
  getShouldFetchData,
  getTempObservations,
  setDataCapture,
  setEditingFindingId,
  setInitialDataCapture,
  setShouldFetchData,
  TSection,
} from '../../dataCaptureSlice'
import { createURLFromParams } from '../../utils/URLhelper'
import DataCaptureLoadingSkeleton from './subcomponents/dataCaptureLoadingSkeleton/DataCaptureLoadingSkeleton'
import DataCaptureWithHeader from './subcomponents/dataCaptureWithHeader/DataCaptureWithHeader'
import NoData from './subcomponents/noData/NoData'
import DataCaptureSection from './subcomponents/section/DataCaptureSection'
import SectionMenu from './subcomponents/sectionMenu/SectionMenu'

const DataCaptureOverview = () => {
  const childRef = useRef<{ endDocumentation: () => void; setNurseStarted: () => void }>(null)

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const observations = useAppSelector(getObservations)
  const tempObservations = useAppSelector(getTempObservations)
  const basicInformation = useAppSelector(getBasicInformation)
  const isInCreateEdit = location.pathname.includes('create-edit')
  const { t } = useTranslation()
  const { id } = useParams()
  const sections = useAppSelector(getSections)
  const shouldFetchData = useAppSelector(getShouldFetchData)
  const [initialDataLoad, setInitialDataLoad] = useState<boolean>(true)
  const { showModal, hideAsyncModal } = useGlobalModalContext()
  const [submitDataCapture] = usePutApiDataCaptureByIdMutation()
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)
  const readyToDelete = useAppSelector(getReadyToDelete)
  const observationsExist = sections
    .map((section: TSection) => findSectionFindings(section, observations))
    .includes(true)
  const serviceRequestIdParam = id === undefined ? '' : id

  const {
    data,
    isFetching,
    error,
    refetch: refetchDataCapture,
  } = useGetApiDataCaptureByIdQuery(
    { id: serviceRequestIdParam },
    { refetchOnMountOrArgChange: false, skip: !shouldFetchData }
  )

  useEffect(() => {
    if (readyToDelete === 'overview' && params.id) {
      submitDataCapture({
        zipModuleFeaturesDataCaptureSaveDataCaptureCommand: {
          dataCapture: { observations: tempObservations, basicInformation: basicInformation },
          serviceRequestId: params.id,
        } as ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
        id: params.id,
      })
        .then(() => {
          dispatch(
            setDataCapture({
              sections: sections,
              observations: tempObservations,
              basicInformation: basicInformation,
            })
          )

          hideAsyncModal()
        })
        .catch(() => {
          navigator.onLine && showModal(getErrorSubmittingModalConfig({ t }))
        })
    }
  }, [readyToDelete])

  useEffect(() => {
    if (params.id !== basicInformation.serviceRequestId) {
      dispatch(setInitialDataCapture())
      dispatch(setShouldFetchData(true))
    }
  }, [params])

  useEffect(() => {
    if (data) {
      dispatch(setDataCapture(data))
      dispatch(setEditingFindingId(''))

      if (!initialDataLoad) {
        dispatch(setShouldFetchData(false))
      } else {
        // on initial data load set nurse started status
        data.basicInformation?.procedureInternalStatus === InternalStatus.Created &&
          childRef?.current?.setNurseStarted()
      }

      setInitialDataLoad(false)
    }
  }, [data])

  useEffect(() => {
    error &&
      // to prevent the modal for 404 errors, which will redirect to not-found
      navigator.onLine &&
      showModal(
        getErrorFetchingModalConfig({
          t,
          handleOk: refetchDataCapture,
          handleCancel: () => {
            navigate('/procedures')
          },
        })
      )
  }, [error])

  useEffect(() => {
    //if data exists
    if (observations.length) {
      const url = createURLFromParams(params, location, isInCreateEdit)
      // if we are on the same url, don't navigate again
      location.pathname !== url && navigate(url, { replace: true })
    }
  }, [params])

  const handleEndDocumentation = () => {
    childRef?.current?.endDocumentation()
  }

  return (
    <DataCaptureStatusChangeWrapper ref={childRef}>
      <DataCaptureWithHeader
        showAddButton={!isProcedureFinished}
        isLoading={!!(isFetching || error)}
        endDocumentation={handleEndDocumentation}
      >
        {!(isFetching || error) && !observationsExist ? (
          <NoData />
        ) : isFetching || error ? (
          <DataCaptureLoadingSkeleton />
        ) : (
          <>
            <SectionMenu>
              <></>
            </SectionMenu>
            <DataCaptureSection />
          </>
        )}
      </DataCaptureWithHeader>
    </DataCaptureStatusChangeWrapper>
  )
}

export default DataCaptureOverview
