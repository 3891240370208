import { useAppSelector } from 'app/hooks'
import { getShowContainersOnly } from 'features/dataCapture/dataCaptureSlice'
import { determineBiopsyContainer } from 'features/dataCapture/utils/utils'
import { useTranslation } from 'react-i18next'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

import styles from '../section/styles.module.scss'
import DataCaptureCard from './DataCaptureCard'
import DataCaptureCardLoading from './DataCaptureCardLoading'

interface IDataCaptureCardWrapper {
  title?: string | null
  subtitle?: string
  workflowStep?: ZipModuleTerminologyCommonTerminologyNodeDto
  dropdownIds?: string[]
  component?: ZipModuleCommonDtosComponentDto
  observation?: ZipModuleCommonDtosObservationDto
  tkey?: string | null
  isLastEdited: boolean
}

const DataCaptureCardWrapper = ({
  title,
  subtitle,
  workflowStep,
  dropdownIds,
  component,
  tkey,
  observation,
  isLastEdited,
}: IDataCaptureCardWrapper) => {
  const { t } = useTranslation()
  const isLoading = false
  const showContainersOnly = useAppSelector(getShowContainersOnly)
  const containers = determineBiopsyContainer(workflowStep, dropdownIds, t)

  return isLoading ? (
    <DataCaptureCardLoading />
  ) : (
    <div
      className={
        showContainersOnly && containers?.length == 0
          ? styles.sectionCardWrapperHidden
          : styles.sectionCardWrapper
      }
      id="nova-report-Data-Capture-Section-With-Title-root"
    >
      <DataCaptureCard
        title={title}
        subtitle={subtitle}
        workflowStep={workflowStep}
        dropdownIds={dropdownIds}
        component={component}
        containers={containers}
        observation={observation}
        tkey={tkey}
        isLastEdited={isLastEdited}
      />
    </div>
  )
}

export default DataCaptureCardWrapper
