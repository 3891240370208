import {
  Header,
  HeaderColorVariants,
  HeaderVariants,
  LeftIcon,
  StopIcon,
} from '@novax/zip-frontend-library'
import { Button } from 'antd'
import { useAppSelector } from 'app/hooks'
import Avatar from 'components/avatar/Avatar'
import CustomNovaNavigation from 'components/CustomNovaNavigation/CustomNovaNavigation'
import { useGlobalModalContext } from 'components/GlobalModalProvider/GlobalModalProvider'
import InDevelopmentTag from 'components/inDevelopmentTag/InDevelopmentTag'
import LoadingCard from 'components/loading/LoadingCard'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CombinedStatus } from 'types'
import { getEndProcedureModalConfig, getLeaveProcedureModalConfig } from 'utils/modalConfigs'
import { getNameInitials } from 'utils/name'

import {
  getBasicInformation,
  getDisableUPLButton,
  getIsProcedureFinished,
} from '../../../../dataCaptureSlice'
import { getPatientAge } from '../../../../utils/utils'
import styles from './styles.module.scss'

const DataCaptureHeader = ({
  isLoading,
  showInDevelopmentTag,
  endDocumentation,
}: {
  isLoading?: boolean
  showInDevelopmentTag?: boolean
  endDocumentation: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const basicInformation = useAppSelector(getBasicInformation)
  const { showModal } = useGlobalModalContext()
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)
  const disableUPLButton = useAppSelector(getDisableUPLButton)

  const displayPatientInfo = () => {
    if (!_.isEmpty(basicInformation)) {
      return (
        basicInformation?.patientName +
        ', ' +
        getPatientAge(basicInformation?.patientDateOfBirth ?? null) +
        t(`dataCapture.nav.years`) +
        ', ' +
        (basicInformation?.patientGender
          ? t('common.gender.' + basicInformation?.patientGender)
          : basicInformation?.patientGender)
      )
    } else {
      return ''
    }
  }
  const triggerEndProcedureModalOpen = () => {
    showModal(getEndProcedureModalConfig({ t, handleOk: handleEndProcedure }))
  }

  const handleEndProcedure = async () => {
    endDocumentation()
  }

  const handleBackButtonClick = () => {
    if (disableUPLButton) {
      return
    }
    if (!isProcedureFinished) {
      showModal(
        getLeaveProcedureModalConfig({
          t,
          handleOk: () => {
            navigate('/procedures')
          },
        })
      )
    } else {
      navigate('/procedures')
    }
  }

  return (
    <>
      <CustomNovaNavigation showInDevelopmentTag={showInDevelopmentTag} />
      <div className={styles.headerContent}>
        <Header
          color={HeaderColorVariants.LIGHT}
          variant={HeaderVariants.HIGH}
          isFixedWidth={false}
          isStaticWidth
        >
          <div className={styles.headerWrapper}>
            <div className={styles.headerTitleWrapper}>
              <div className={styles.flexCenter} data-testid="back-button">
                {!isLoading ? (
                  <LeftIcon
                    data-testid={'data-capture-back-button-icon'}
                    size={24}
                    className={disableUPLButton ? styles.backButtonDisabled : styles.backButton}
                    onClick={handleBackButtonClick}
                  />
                ) : (
                  <div className={styles.backButton}>
                    <LoadingCard width={'24px'} height={'24px'} />
                  </div>
                )}
              </div>
              <div className={styles.headerTitle}>
                <div className={styles.avatarContainer}>
                  <Avatar
                    isLoading={isLoading}
                    isLive={basicInformation?.procedureStatus === CombinedStatus.InProgress}
                    nameInitials={getNameInitials(basicInformation?.patientName ?? undefined)}
                  />
                </div>
                <div className={styles.basicInfo}>
                  {!isLoading ? (
                    displayPatientInfo()
                  ) : (
                    <LoadingCard width={'167px'} height={'25px'} />
                  )}
                </div>
                <div className={styles.divider} />
                <div className={styles.procedureType}>
                  {!isLoading ? (
                    basicInformation?.procedureType ? (
                      t('common.procedureType.' + basicInformation?.procedureType)
                    ) : (
                      basicInformation?.procedureType
                    )
                  ) : (
                    <LoadingCard width={'91px'} height={'25px'} />
                  )}
                </div>
                {showInDevelopmentTag && <InDevelopmentTag marginLeft autoHeight headerStyle />}
              </div>
            </div>
          </div>
          <div className={styles.watchAndButtonWrapper}>
            {!isProcedureFinished && (
              <Button
                data-testid="end-procedure-button"
                className={`${styles.endProcedureButton} ${
                  isLoading ? styles.endProcedureButtonLoading : ''
                }`}
                type="primary"
                size="large"
                onClick={() => !isLoading && triggerEndProcedureModalOpen()}
              >
                {!isLoading ? (
                  <div>
                    <StopIcon />
                    {t('dataCapture.nav.endProcedure')}
                  </div>
                ) : null}
              </Button>
            )}
          </div>
        </Header>
      </div>
    </>
  )
}

export default DataCaptureHeader
