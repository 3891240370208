import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getObservations,
  getSections,
  setIsContainerToggleDisabled,
  setShowContainersOnly,
} from 'features/dataCapture/dataCaptureSlice'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { checkIfSectionIncludesBiopsyContainer } from '../../../../utils/checkIfAnyBiopsyContainersExist'
import { getSectionTKeyFromParams, tKeysMatch } from '../../../../utils/URLhelper'
import DataCaptureSectionWithTitle from './subcomponents/DataCaptureSectionWithTitle'

const DataCaptureSection = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const params = useParams()
  const sections = useAppSelector(getSections)
  const observations = useAppSelector(getObservations)

  useEffect(() => {
    const displayedSections = sections.filter(
      (section) =>
        !params.sectionTKey ||
        tKeysMatch(getSectionTKeyFromParams(params), 'all') ||
        tKeysMatch(section.tKey, getSectionTKeyFromParams(params))
    )

    const sectionHasBiopsyContainer = displayedSections.find((section) =>
      checkIfSectionIncludesBiopsyContainer(observations, section, t).includes(true)
    )

    dispatch(setIsContainerToggleDisabled(!sectionHasBiopsyContainer))
    if (!sectionHasBiopsyContainer) {
      dispatch(setShowContainersOnly(false))
    }
  }, [observations, params])

  return (
    <>
      {sections
        .filter(
          (section) =>
            !params.sectionTKey ||
            tKeysMatch(getSectionTKeyFromParams(params), 'all') ||
            tKeysMatch(section.tKey, getSectionTKeyFromParams(params))
        )
        .map((section) => (
          <div key={section.tKey} id="nova-report-Data-Capture-Section-root">
            <DataCaptureSectionWithTitle section={section} />
          </div>
        ))}
    </>
  )
}

export default DataCaptureSection
