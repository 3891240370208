import Icon from '@ant-design/icons/lib/components/Icon'
import { RhombusIcon } from '@novax/zip-frontend-library'
import { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'

interface INovaIconProps extends React.HTMLProps<HTMLSpanElement> {
  ref?: React.Ref<HTMLSpanElement>
  tkey?: string | null
  className?: string
  iconSize?: string | number
}

const NovaIcon = ({ tkey, className, iconSize, color, ...props }: INovaIconProps) => {
  const [path, setPath] = useState<string | undefined>(undefined)
  const [pathResolved, setPathResolved] = useState<boolean>(false)

  useEffect(() => {
    const importIcon = async () => {
      try {
        const { default: _path } = await import(`../assets/dataCaptureIcons/${tkey}.svg`)
        setPath(_path)
      } catch (err) {
        /* istanbul ignore next: possibly unreachable */
        setPath(undefined)
      }
      setPathResolved(true)
    }
    importIcon()
  }, [tkey])

  return (
    <Icon
      data-testid="nova-icon-root"
      {...props}
      className={className}
      style={{ fontSize: iconSize, lineHeight: 0 }}
      component={() => (
        <ReactSVG
          fill={color}
          src={path ?? ''}
          wrapper="div"
          style={{ lineHeight: 0, opacity: pathResolved ? 1 : 0 }}
          title={`novax-icon-${tkey}`}
          fallback={() => <RhombusIcon style={{ fontSize: iconSize }} />}
          beforeInjection={
            /* istanbul ignore next: unreachable in test */
            (svg) => {
              svg.setAttribute('width', '1em')
              svg.setAttribute('height', '1em')
              const pathElements = svg.querySelectorAll('path')
              pathElements.forEach((path) => {
                color && path.setAttribute('fill', color)
              })
            }
          }
        />
      )}
    />
  )
}

export default NovaIcon
