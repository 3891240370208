import { useAuth, useConfig } from '@novax/os'
import { useMemo } from 'react'
import { Role } from 'types'

export const useGetRealm = () => {
  const [config] = useConfig()

  return config?.AUTH_REALM
}

export const useUserHasRole = (role: Role) => {
  const { user } = useAuth()
  const hasRole = useMemo(() => user?.roles.includes(role.valueOf()) ?? false, [role, user])

  return hasRole
}
