import { useAppSelector } from 'app/hooks'
import { getFindingForm, getSections } from 'features/dataCapture/dataCaptureSlice'
import { joinTKeyParams, splitTKeyParams } from 'features/dataCapture/utils/URLhelper'
import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import {
  determineSelectedWorkflowObjects,
  getTerminologyItemByTKeys,
} from '../../../../../../../../utils/utils'
import FindingSection from '../../FindingSection'
import styles from '../../styles.module.scss'
import { getLevel3sFromForm } from '../../util/animationsUtil'
import {
  findArrayDifference,
  getPreviousAndCurrentLevel3sShareLevel5Children,
  setDeepLevelAnimationTerms,
} from './helpers'

interface IFindingSectionLevel5Props {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  option: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
  parentTkey: string
  isDisabled: boolean
  workflow: ZipModuleTerminologyCommonTerminologyNodeDto | null
}

const FindingSectionLevel5 = ({
  finding,
  option,
  parentTkey,
  isDisabled,
  workflow,
}: IFindingSectionLevel5Props) => {
  const params = useParams()
  const findingForm = useAppSelector(getFindingForm)
  const sections = useAppSelector(getSections)
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  const localRef = useRef<HTMLDivElement>(null)
  const deepRef = useRef<HTMLDivElement>(null)

  const { terminologySection } = determineSelectedWorkflowObjects(sections, {
    workflowTKey: params.workflowTKey ?? '',
    childTKey: params.childTKey ?? null,
  })

  const [localOption, setLocalOption] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined
  >(option)
  const [localOptions, setLocalOptions] = useState<
    { tKeys: string; item: ZipModuleTerminologyCommonTerminologyNodeDto }[] | undefined
  >([])

  useEffect(() => {
    const {
      shouldAnimateHeightEnter,
      shouldAnimateHeightExit,
      shouldAnimateOpacityEnter,
      shouldAnimateOpacityExit,
    } = setDeepLevelAnimationTerms(
      localOption,
      option,
      finding,
      parentTkey,
      findingForm.form,
      findingForm.previousForm
    )

    // find difference between current and previous form to determine finding that has been changed
    const diffOption = findArrayDifference(
      Object.keys(findingForm.form),
      Object.keys(findingForm.previousForm)
    )

    if (isEmpty(diffOption) && localOption) {
      // option has been deselected, reset local state and animate exit
      animateHeightExit()
    } else if (getPreviousAndCurrentLevel3sShareLevel5Children(option, localOption)) {
      // set new option to local state and do NOT perform any animations
      setLocalOption(option)
      setShouldRender(true)
    } else {
      if (shouldAnimateOpacityEnter) animateOpacityEnter()
      if (shouldAnimateOpacityExit) animateOpacityExit()
      if (shouldAnimateHeightEnter) animateHeightEnter()
      if (shouldAnimateHeightExit) animateHeightExit()
    }
  }, [option])

  useEffect(() => {
    if (workflow && terminologySection) {
      const options: { tKeys: string; item: ZipModuleTerminologyCommonTerminologyNodeDto }[] = []
      getLevel3sFromForm(findingForm.form, finding).map((level3Option) => {
        const terminologyItem = getTerminologyItemByTKeys(
          splitTKeyParams(Object.keys(level3Option)[0]),
          terminologySection.terminology
        )
        if (
          terminologyItem &&
          terminologyItem.children?.length &&
          terminologyItem.children?.length > 0
        ) {
          options.push({ tKeys: Object.keys(level3Option)[0], item: terminologyItem })
        }
      })

      if (options && options.length > 0) {
        const parentItem = getTerminologyItemByTKeys(
          splitTKeyParams(options[0].tKeys).slice(0, -1),
          terminologySection.terminology
        )
        options.sort((optionA, optionB) => {
          return optionA.item &&
            optionB.item &&
            parentItem &&
            parentItem?.children &&
            parentItem?.children?.indexOf(optionA.item) <=
              parentItem?.children?.indexOf(optionB.item)
            ? -1
            : 1
        })
      }
      if (localOptions && localOptions.length != 0 && options.length === 0) {
        animateHeightExit()
      } else {
        setLocalOptions(options)
      }
    }
  }, [findingForm])

  const animateHeightEnter = () => {
    setLocalOption(option)
    setShouldRender(true)
    // timeout serves here to wait for state to be set, ideally we can move this classlist add into separate useEffect
    const timeout = setTimeout(() => {
      localRef.current?.classList.add(styles.animateHeight)
      return () => clearTimeout(timeout)
    }, 1)
  }

  const animateHeightExit = () => {
    //height exit animation start
    localRef.current?.classList.add(styles.animateHeightBackwards)
    const timeout = setTimeout(() => {
      setLocalOptions([])
      setLocalOption(undefined)
      setShouldRender(false)
      localRef.current?.classList.remove(styles.animateHeightBackwards)
      return () => clearTimeout(timeout)
    }, 250)
  }

  const animateOpacityEnter = () => {
    // set new option
    setLocalOption(option)
    // add opacity animation class and remove it just before it will finish
    deepRef.current?.classList.add(styles.opacityAnimation)
    const timeout = setTimeout(() => {
      deepRef.current?.classList.remove(styles.opacityAnimation)
      return () => clearTimeout(timeout)
    }, 150)
  }
  const animateOpacityExit = () => {
    // adding opacity exit animation from 1 to 0
    deepRef.current?.classList.add(styles.opacityAnimationBackwards)
    //eslint-disable-next-line
    const timeout = setTimeout(() => {
      // when opacity exit animation is done, set local state to option and remove class from the div
      setLocalOption(option)
      deepRef.current?.classList.remove(styles.opacityAnimationBackwards)
      return () => clearTimeout(timeout)
    }, 150)
  }

  return (
    <div
      ref={localRef}
      className={`${styles.option} ${shouldRender && localOption ? styles.display : styles.hide} ${
        workflow?.alwaysSelected && localOption?.lvl === 3 ? styles.mb32 : 0
      }`}
      id="nova-report-Finding-Section-Level5-root"
      data-testid="nova-report-Finding-Section-Level5-root"
    >
      {localOptions?.map((localOption) => (
        <div
          key={localOption.item.tKey}
          className={
            shouldRender && localOption
              ? `${styles.blueBorderLeft} ${styles.deepLevelBackground}`
              : `${styles.hide}`
          }
        >
          <div ref={deepRef}>
            {localOption.item.children?.map((level4Finding) => (
              <div
                className={!shouldRender && isEmpty(localOption.item) ? styles.hide : ''}
                key={level4Finding.tKey}
              >
                <FindingSection
                  finding={level4Finding}
                  isDisabled={isDisabled}
                  isLevel4={level4Finding.lvl === 4}
                  parentTkey={
                    workflow?.alwaysSelected
                      ? joinTKeyParams([localOption.tKeys, level4Finding.tKey ?? ''])
                      : joinTKeyParams([localOption.tKeys])
                  }
                  workflowTitle={''}
                  parentId={''}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    /**/
  )
}

export default FindingSectionLevel5
