import { ZipModuleCommonDtosImageDto } from '../../../services/zipmodule.gen'
import { CombinedStatus, InternalStatus } from '../../../types'

export class SignalRMessageTypes {
  static ProcedureUpdated = 'ProcedureUpdated'
  static ImageReleased = 'ImageReleased'
  static ProcedureStatusChanged = 'ProcedureStatusChanged'
}

export type ProcedureUpdatedMessage = {
  serviceRequestId: string
}

export type ImageReleasedMessageType = {
  serviceRequestId: string
  images: ZipModuleCommonDtosImageDto[]
}

export type ProcedureStatusChangedMessage = {
  serviceRequestId: string
  status: CombinedStatus
  internalStatus: InternalStatus
}

export interface SignalRMessageListener<T> {
  methodName: keyof T
  callback: (message: T[keyof T]) => void
}
