import { ITermTreeNode } from '../interfaces/IReportDetails'

// creating free text for single observationm
export const createNavigationItems = (terminologyTree: ITermTreeNode[] | null) => {
  const navigationItems: { id: string; name: string; tKey: string }[] = []

  navigationItems.push({
    id: 'basicInformation',
    name: 'Basic Information',
    tKey: 'basicInformation',
  })

  terminologyTree?.forEach((terms: ITermTreeNode) => {
    navigationItems.push({
      id: terms.id.toString(),
      name: terms.value,
      tKey: terms.tKey ?? '',
    })
  })

  // in case there is no terminology for findings (no children), show the Image section
  if (terminologyTree?.find((el) => el.tKey?.toLowerCase() == 'findings')?.children.length == 0) {
    const index = terminologyTree?.length
      ? terminologyTree?.findIndex((el) => el.tKey?.toLowerCase() == 'findings') + 1
      : 0
    navigationItems.splice(index, 0, {
      id: 'images',
      name: 'Images',
      tKey: 'images',
    })
  }

  /* removing */
  // Removing items that are not needed for screen but coming from terminology
  //navigationItems = navigationItems.filter(function (obj) {
  //return obj.tKey?.toLowerCase() !== 'pathology'
  //})
  /* removing end */

  return navigationItems
}
