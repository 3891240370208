import { useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useGetApiReportsByIdQuery,
  ZipModuleCommonDtosObservationDto,
} from 'services/zipmodule.gen'

import { IBasicInformation, ITermTreeNode } from '../interfaces/IReportDetails'
import {
  getReportsApiGet,
  setInitialReportDetails,
  setReportDetails,
  setReportsApiGet,
} from '../reportDetailsSlice'
import { setAllImages } from '../subcomponents/images/imagesSlice'

const useGetReportsFromApi = (id: string) => {
  const dispatch = useDispatch()
  const [apiObjInStore] = useAppSelector(getReportsApiGet)

  const { data, isFetching, refetch, error } = useGetApiReportsByIdQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  //update api object in store
  useEffect(() => {
    dispatch(
      setReportsApiGet({
        isLoading: isFetching,
        firstLoadDone: true,
        error: error,
      })
    )

    // set apiObj in store to initial value on unmount
    return () => {
      dispatch(
        setReportsApiGet({
          isLoading: true,
          firstLoadDone: false,
          triggerRefetch: false,
          error: undefined,
        })
      )
    }
  }, [isFetching, error])

  // load data from API to the store
  useEffect(() => {
    if (!isFetching) {
      if (!error) {
        // check if any descriptionSlateJs is empty, that means that this procedure is opened by doctor for the first time
        const shouldPushUpdateToApi = data?.observations?.some(
          (o) => !o.descriptionSlateJs || o.descriptionSlateJs.length === 0
        )
        dispatch(
          setReportDetails({
            data: data as unknown as {
              basicInformation: IBasicInformation
              terminology: ITermTreeNode[]
              observations: ZipModuleCommonDtosObservationDto[]
            },
            shouldPushUpdateToApi,
          })
        )
        dispatch(setAllImages(data?.images))
      } else {
        dispatch(setInitialReportDetails())
      }
    }
  }, [data])

  // do the refetch when triggered in store
  useEffect(() => {
    if (apiObjInStore.triggerRefetch) {
      refetch()
      dispatch(setReportsApiGet({ triggerRefetch: false }))
    }
  }, [apiObjInStore.triggerRefetch])
}

export default useGetReportsFromApi
