import { CloseOutlined } from '@ant-design/icons'
import { FloatButton } from 'antd'
import { MouseEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAppSelector } from '../../../../../../app/hooks'
import { getSections } from '../../../../dataCaptureSlice'
import DataCaptureAddButtonItem from './dataCaptureAddButtonItem/DataCaptureAddButtonItem'
import styles from './styles.module.scss'

const DataCaptureAddButton = (props: { align: 'left' | 'right'; isLoading: boolean }) => {
  const params = useParams()
  const sections = useAppSelector(getSections)
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleHandler = () => {
    setExpanded(!expanded)
  }
  const handleButtonClick = () => {
    setExpanded(false)
  }

  const handleOutsideClick = (event: MouseEvent) => {
    event.preventDefault()
    if (event.target === event.currentTarget) {
      // handle
      setExpanded(false)
    }
  }
  return (
    <>
      <div
        className={expanded && !props.isLoading ? styles.addButtonExpanded : styles.addButton}
        id="nova-report-Data-Capture-Add-Button-root"
      >
        <div
          className={
            expanded && !props.isLoading
              ? styles.addButtonOuterContainerExpanded
              : styles.addButtonOuterContainer
          }
        >
          <div
            className={
              expanded && !props.isLoading ? styles.buttonContainerExpanded : styles.buttonContainer
            }
            id="nova-report-Data-Capture-Add-Button-click-outside"
            data-testid="nova-report-Data-Capture-Add-Button-click-outside"
            onClick={(event) => handleOutsideClick(event)}
          >
            {sections.map((section) => {
              return (
                <div key={section.tKey} className={styles.buttonGroupContainer}>
                  <div
                    className={
                      props.align === 'right'
                        ? styles.buttonGroupLabel
                        : styles.buttonGroupLabelLeft
                    }
                    id={`'add-button-label-section-'${section.sectionName}`}
                    onClick={(event) => handleOutsideClick(event)}
                  >
                    <span> {section.sectionName}</span>
                  </div>
                  {section.terminology?.map((terminology) => {
                    if (terminology?.tKey?.toLowerCase() === section?.tKey?.toLowerCase()) {
                      // if terminology key is equal to section key
                      // distinct items are in the terminology children map (in e.g. findings)
                      return (
                        <div key={terminology.id}>
                          {terminology.children?.map((child) => {
                            return (
                              <DataCaptureAddButtonItem
                                key={child.id}
                                align={props.align}
                                id={params.id}
                                label={child.value}
                                sectionTKey={section.tKey}
                                terminologyTKey={terminology.tKey}
                                terminologyChildTKey={child.tKey}
                                onClick={handleButtonClick}
                                onOutsideClick={handleOutsideClick}
                              />
                            )
                          })}
                        </div>
                      )
                    } else {
                      // if terminology key is not equal to section key
                      // distinct items are in the terminology array itself (in e.g. procedure information)
                      return (
                        <DataCaptureAddButtonItem
                          key={terminology.id}
                          align={props.align}
                          id={params.id}
                          label={terminology.value}
                          sectionTKey={section.tKey}
                          terminologyTKey={terminology.tKey}
                          onClick={handleButtonClick}
                          onOutsideClick={handleOutsideClick}
                        />
                      )
                    }
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {props.isLoading ? (
        <div
          className={
            props.align === 'left'
              ? styles.skeletonButtonLeftAligned
              : styles.skeletonButtonRightAligned
          }
        />
      ) : (
        <FloatButton
          data-testid="data-capture-toggle-button"
          className={
            props.align === 'left'
              ? styles.mainFloatButtonLeftAligned
              : styles.mainFloatButtonRightAligned
          }
          type="primary"
          icon={<CloseOutlined />}
          onClick={toggleHandler}
        />
      )}
    </>
  )
}

export default DataCaptureAddButton
