import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { splitTKeyParams, tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import { isEmpty, isEqual, map, sortBy } from 'lodash'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

export const setAnimationTerms = (
  currentForm: IFindingForm,
  previousForm: IFindingForm,
  finding: ZipModuleTerminologyCommonTerminologyNodeDto,
  level2?: ZipModuleTerminologyCommonTerminologyNodeDto
) => {
  //find all selected level 3s from current form
  const selectedLevel3s = getLevel3sFromForm(currentForm, level2)
  //find all selected level 3s from previous form
  const previouslySelectedLevel3s = getLevel3sFromForm(previousForm, level2)
  // find finding that changed from current -> previous form
  const changedFinding = getChangedFinding(selectedLevel3s, previouslySelectedLevel3s, level2)

  let previousDeepLevelsExist = true
  let deepSections: ZipModuleTerminologyCommonTerminologyNodeDto | undefined = undefined
  finding.children?.find((lvl2) => {
    if (
      changedFinding &&
      changedFinding.length &&
      tKeysMatch(lvl2.tKey, splitTKeyParams(Object.keys(changedFinding[0])[0])[2])
    ) {
      // find changed level 3 values, and get their IFindingForm objects
      const { currentFormChangedLevel3, previousFormChangedLevel3 } = getChangedLevel3s(
        selectedLevel3s,
        previouslySelectedLevel3s,
        changedFinding[0]
      )

      // if previous form level 3 is undefiend, meaning form was empty and we should animate
      if (!previousFormChangedLevel3 || isEmpty(previousFormChangedLevel3)) {
        previousDeepLevelsExist = false
      }

      // find changed level 3 values, and get their Terminology objects
      const { currentLevel3FromTerminology, previousLevel3FromTerminology } =
        getLevel3sFromTerminology(lvl2, currentFormChangedLevel3, previousFormChangedLevel3)

      // true if they both level3s (previous and current) have the same level 5 children by childrens tKey
      const previousAndCurrentLevel3sShareChildren =
        getPreviousAndCurrentLevel3sShareLevel5Children(
          currentLevel3FromTerminology,
          previousLevel3FromTerminology
        )

      // only animating opacity when level 3s do NOT share children
      if (!previousAndCurrentLevel3sShareChildren) {
        deepSections = currentLevel3FromTerminology
      } else {
        deepSections = currentLevel3FromTerminology
      }
    }
  })
  return { previousDeepLevelsExist, deepSections }
}

/**
 *
 * Returns all level 3s from `form`
 *
 * @param form finding form to check for all level 3s
 *
 */
export const getLevel3sFromForm = (
  form: IFindingForm,
  level2: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
) => {
  return Object.entries(form)
    .filter(
      ([key, _]) =>
        splitTKeyParams(key).length === 4 && tKeysMatch(splitTKeyParams(key)[2], level2?.tKey)
    )
    .map(([key, value]) => ({ [key]: value }))
}

/**
 *
 * Finds changed level 3 from previous to current form
 *
 * @param selectedLevel3s all currently selected Level 3s from form
 * @param previouslySelectedLevel3s all previously selected Level 3s from form
 *
 * Returns changed level 3 as `key: value`
 */
const getChangedFinding = (
  selectedLevel3s: { [key: string]: string }[],
  previouslySelectedLevel3s: { [key: string]: string }[],
  level2Key: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
) => {
  if (isEqual(selectedLevel3s, previouslySelectedLevel3s)) {
    return selectedLevel3s.filter(
      (lvl3) => splitTKeyParams(Object.keys(lvl3)[0])[2] === level2Key?.tKey
    )
  } else {
    return selectedLevel3s.filter((lvl3) => {
      const lvl2Key = splitTKeyParams(Object.keys(lvl3)[0])[2]
      return (
        previouslySelectedLevel3s.length === 0 ||
        previouslySelectedLevel3s.some(
          (prevlvl3) =>
            !isEqual(lvl3, prevlvl3) &&
            tKeysMatch(lvl2Key, splitTKeyParams(Object.keys(prevlvl3)[0])[2])
        )
      )
    })
  }
}

/**
 *
 * Checks for changed level 3 from both previous and current form
 *
 * @param selectedLevel3s all selected Level 3s from form
 * @param previouslySelectedLevel3s all previously selected Level 3s from form
 * @param changedFinding changed Level 3 from form
 *
 * Returns previous and current selected level 3 that includes the same level 2 as in `changedFinding`
 */
const getChangedLevel3s = (
  selectedLevel3s: { [key: string]: string }[],
  previouslySelectedLevel3s: { [key: string]: string }[],
  changedFinding: { [key: string]: string }
) => {
  const currentFormChangedLevel3 =
    selectedLevel3s.find(
      (lvl3) =>
        tKeysMatch(
          splitTKeyParams(Object.keys(lvl3)[0])[2],
          splitTKeyParams(Object.keys(changedFinding)[0])[2]
        ) &&
        tKeysMatch(
          splitTKeyParams(Object.keys(lvl3)[0])[1],
          splitTKeyParams(Object.keys(changedFinding)[0])[1]
        )
    ) ?? {}

  const previousFormChangedLevel3 =
    previouslySelectedLevel3s.find(
      (lvl3) =>
        tKeysMatch(
          splitTKeyParams(Object.keys(lvl3)[0])[2],
          splitTKeyParams(Object.keys(currentFormChangedLevel3)[0])[2]
        ) &&
        tKeysMatch(
          splitTKeyParams(Object.keys(lvl3)[0])[1],
          splitTKeyParams(Object.keys(currentFormChangedLevel3)[0])[1]
        )
    ) ?? {}

  return { currentFormChangedLevel3, previousFormChangedLevel3 }
}

/**
 *
 * Checks if level 5 children are the same by the `tKey` property
 *
 * @param lvl2 `ZipModuleTerminologyCommonTerminologyNodeDto` selected Level 2 from form
 * @param currentFormChangedLevel3 currently selected Level 3s from form
 * @param previousFormChangedLevel3 previously selected Level 3s from form
 *
 * Returns current and previous Level 3 as a Terminology Child `ZipModuleTerminologyCommonTerminologyNodeDto`
 */
export const getLevel3sFromTerminology = (
  lvl2: ZipModuleTerminologyCommonTerminologyNodeDto,
  currentFormChangedLevel3: { [key: string]: string },
  previousFormChangedLevel3: { [key: string]: string }
) => {
  const currentLevel3Key = splitTKeyParams(Object.keys(currentFormChangedLevel3)[0])[3]
  const previousLevel3Key = splitTKeyParams(Object.keys(previousFormChangedLevel3)[0])[3]
  const currentLevel3FromTerminology = lvl2.children?.find(
    (lvl3: ZipModuleTerminologyCommonTerminologyNodeDto) => tKeysMatch(lvl3.tKey, currentLevel3Key)
  )
  const previousLevel3FromTerminology = lvl2.children?.find(
    (lvl3: ZipModuleTerminologyCommonTerminologyNodeDto) => tKeysMatch(lvl3.tKey, previousLevel3Key)
  )
  return { currentLevel3FromTerminology, previousLevel3FromTerminology }
}

/**
 *
 * Checks if level 5 children are the same by the `tKey` property
 *
 * @param currentLevel3FromTerminology selected Level 3 from form
 * @param previousFormChangedLevel3 previously selected Level 3 from form
 *
 */
export const getPreviousAndCurrentLevel3sShareLevel5Children = (
  currentLevel3FromTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  previousFormChangedLevel3: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
) => {
  if (
    currentLevel3FromTerminology?.children &&
    currentLevel3FromTerminology?.children.length > 0 &&
    previousFormChangedLevel3?.children &&
    previousFormChangedLevel3?.children.length > 0
  ) {
    return compareArraysByProperty(
      currentLevel3FromTerminology.children[0].children ?? [],
      previousFormChangedLevel3.children[0].children ?? [],
      'tKey'
    )
  }
  return false
}

/**
 *
 * Comapres two Arrays by property found in array's type. Returns true if they're equal by passed property value.
 *
 * @param arr1 array of `ZipModuleTerminologyCommonTerminologyNodeDto`
 * @param arr2 array of `ZipModuleTerminologyCommonTerminologyNodeDto`
 * @param property of `ZipModuleTerminologyCommonTerminologyNodeDto` to compare arr1 and arr2 to by
 *
 */
export const compareArraysByProperty = (
  arr1: ZipModuleTerminologyCommonTerminologyNodeDto[],
  arr2: ZipModuleTerminologyCommonTerminologyNodeDto[],
  property: string
) => {
  const sortedArray1 = sortBy(arr1, property)
  const sortedArray2 = sortBy(arr2, property)

  return isEqual(map(sortedArray1, property), map(sortedArray2, property))
}
