import { useAuth } from '@novax/os'
import LoadingScreen from 'features/connect/loadingScreen/LoadingScreen'
import DataCaptureOverview from 'features/dataCapture/feature/overview/DataCaptureOverview'
import CreateProcedure from 'features/debug/CreateProcedure/CreateProcedure'
import { lazy, Suspense, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import { Role } from 'types'

import DataCaptureCreateEdit from '../features/dataCapture/feature/create-edit/DataCaptureCreateEdit'
import PreviewReport from '../features/previewReport/PreviewReport'
import FetchReportFromApiProvider from './FetchReportFromApiProvider'
import SlideRoutes from './slideRoutes'

const ProcedureOverview = lazy(() => import('features/procedureOverview/ProcedureOverview'))
const ReportDetails = lazy(() => import('features/reportDetails/ReportDetails'))
/*const DevicePairing = lazy(() => import('features/devicePairing/DevicePairing'))
const DisconnectDevicePairing = lazy(
  () => import('features/disconnectDevicePairing/DisconnectDevicePairing')
)*/
const DebugContainer = lazy(() => import('features/debug/DebugContainer/DebugContainer'))
const CVSimulator = lazy(() => import('features/debug/CVSimulator/CVSimulator'))
const PageNotFound = lazy(() => import('features/pageNotFound/PageNotFound'))

const PrivateRoute = ({ role, children }: { role: Role; children?: React.ReactNode }) => {
  const { user } = useAuth()
  const hasRole = useMemo(() => user?.roles.includes(role) ?? false, [user])

  return <>{hasRole ? children : <Navigate to="/not-found" replace />}</>
}

const Router = () => {
  const { t } = useTranslation()
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen label={t('common.loading')} />}>
        <SlideRoutes duration={500}>
          <Route path="/" element={<ProcedureOverview />} />

          <Route path="/procedures" element={<ProcedureOverview />} />

          <Route
            path="/data-capture/:id/:sectionTKey?/:workflowTKey?"
            element={
              <PrivateRoute role={Role.NURSE}>
                <DataCaptureOverview />
              </PrivateRoute>
            }
          />

          <Route
            path="/data-capture/:id/create-edit/:sectionTKey?/:workflowTKey?/:childTKey?/:dropdownId?"
            element={
              <PrivateRoute role={Role.NURSE}>
                <DataCaptureCreateEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="report-details/:id"
            element={
              <PrivateRoute role={Role.DOCTOR}>
                <ReportDetails />
              </PrivateRoute>
            }
          />
          {/*<Route path="/" element={<DevicePairing />} />
           <Route path="/disconnect" element={<DisconnectDevicePairing />} />*/}

          <Route path="/debug" element={<DebugContainer />}>
            <Route path="cv" element={<CVSimulator />} />
            <Route path="create-procedure" element={<CreateProcedure />} />
          </Route>
          <Route path="cv" element={<CVSimulator />} />

          <Route
            path="report-details/:id"
            element={
              <PrivateRoute role={Role.DOCTOR}>
                <FetchReportFromApiProvider />
              </PrivateRoute>
            }
          >
            <Route path="" element={<ReportDetails />} />
            <Route path="preview" element={<PreviewReport />} />
            <Route path="submitted-preview" element={<PreviewReport />} />
          </Route>

          <Route path="not-found" element={<PageNotFound />} />
          <Route path="/*" element={<Navigate to="/not-found" replace />} />
        </SlideRoutes>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
