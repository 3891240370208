import { ArrowLeftIcon, LoadingIcon, Typography } from '@novax/zip-frontend-library'
import * as React from 'react'
import styled from 'styled-components'

import colors from '../scss/colors.scss'

const ButtonContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  margin: auto 0;
  margin-right: 32px;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 920px) {
    margin-right: 12px !important;
  }

  .anticon {
    cursor: pointer;
    width: 32px;
    color: ${(props) => props.theme.colors.gray5};
    padding: 9px 0;
    transition: 0.3s;
  }

  .anticon.disabled {
    cursor: not-allowed !important;
  }

  &:hover {
    .anticon:not(.disabled) {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .label-wrapper {
    padding: 4px 0;
    padding-left: 2px;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.gray5};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .label-wrapper.disabled {
    cursor: not-allowed !important;
  }
`

interface Props {
  className?: string
  label?: string
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}

const BackButton: React.FC<Props> = ({ onClick, label, className, disabled, loading }) => {
  return (
    <ButtonContainer
      data-testid="back-button-root"
      style={{ cursor: 'pointer' }}
      className={className}
      onClick={() => !disabled && onClick && onClick()}
    >
      {loading ? (
        <LoadingIcon
          data-testid="back-button-arrow-loading-icon"
          style={{ cursor: 'not-allowed' }}
        />
      ) : (
        <ArrowLeftIcon
          data-testid="back-button-arrow-left-icon"
          className={disabled ? 'disabled' : ''}
          style={{ color: colors.gray5 }}
        />
      )}
      {label && (
        <div className={disabled ? 'label-wrapper disabled' : 'label-wrapper'}>
          <Typography variant="s">{label}</Typography>
        </div>
      )}
    </ButtonContainer>
  )
}

export default BackButton
