import { Document, Page } from 'react-pdf'
import { convertBreakpointToNumber } from 'utils/breakpointsTypeConverter'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import screenBreakpoints from '../../../../scss/screenBreakpoints.scss'
import useWindowDimensions from '../../../../utils/useWindowDimensions'
import { getSelectedPage, getTotalPageCount, setSelectedPage } from '../../previewReportSlice'
import PreviewReportLoading from '../previewReportLoading/PreviewReportLoading'
import styles from './styles.module.scss'
interface PreviewReportSidebarProps {
  pdfFile: object
  pageTitles: string[]
}

const PreviewReportSidebar = ({ pdfFile, pageTitles }: PreviewReportSidebarProps) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowDimensions()
  const totalPageCount = useAppSelector(getTotalPageCount)
  const selectedPage = useAppSelector(getSelectedPage)
  const pageWidth = width > convertBreakpointToNumber(screenBreakpoints.large) ? 176 : 148
  const pageHeight =
    width > convertBreakpointToNumber(screenBreakpoints.large) ? 176 * 1.414 : 148 * 1.414

  const handleSelectPage = (number: number) => {
    dispatch(setSelectedPage(number))
  }

  return (
    <div className={styles.sidebar} id="nova-report-Preview-Report-Sidebar-root">
      {pdfFile ? (
        <Document file={pdfFile} className={styles.pagesContainer}>
          {[...Array(totalPageCount)].map((e, index) => (
            <div
              className={styles.pageWrapper}
              key={index}
              onClick={() => handleSelectPage(index + 1)}
              id={`nova-report-Preview-Report-Sidebar-button-page-${index + 1}`}
            >
              {pageTitles.at(index) &&
                (index === 0 || pageTitles.at(index) !== pageTitles.at(index - 1)) &&
                pageTitles.length > 1 && (
                  <span className={styles.pageTitle}>{pageTitles[index]}</span>
                )}
              <Page
                pageNumber={index + 1}
                width={pageWidth}
                height={pageHeight}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className={`${styles.page} ${
                  index + 1 === selectedPage ? styles.selectedPage : styles.otherPage
                }`}
              />
              <span className={styles.pageNumber}>{index + 1}</span>
            </div>
          ))}
        </Document>
      ) : (
        <div className={styles.loadingWrapper}>
          <PreviewReportLoading
            height={pageHeight}
            width={pageWidth}
            hideSpinner
            isThumb
            isSidebar
          />
        </div>
      )}
    </div>
  )
}

export default PreviewReportSidebar
