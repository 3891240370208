import { useAppSelector } from 'app/hooks'
import { getFindingForm } from 'features/dataCapture/dataCaptureSlice'
import { shouldRenderLevel4 } from 'features/dataCapture/utils/utils'
import { useEffect, useState } from 'react'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import { joinTKeyParams } from '../../../../../../../utils/URLhelper'
import { setAnimationTerms } from '../util/animationsUtil'
import FindingSectionLevel5 from './findingSectionLevel5/FindingSectionLevel5'
import { FindingSectionRow } from './FindingsSectionRow'
interface IProps {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  parentTKey: string
  setFormData: (
    id: string,
    keyName: string,
    sequenceFromRoot: string,
    inputType: string,
    error?: string[]
  ) => void
  isDisabled: boolean
  isLevel4: boolean
}

export const FindingSectionChildren = ({
  finding,
  parentTKey,
  setFormData,
  /* istanbul ignore next: possibly unreachable code */ isDisabled = false,
  /* istanbul ignore next: possibly unreachable code */ isLevel4 = false,
}: IProps) => {
  const findingForm = useAppSelector(getFindingForm)
  const [findingSections, setFindingSections] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined
  >(undefined)

  useEffect(() => {
    // generate finding sections for level 4 & 5, and set animation terms if animation should be present or not when changing between level 3 options
    const level2 = finding.children?.find((option) =>
      option.children?.find((option2) =>
        shouldRenderLevel4(
          option2,
          option,
          findingForm.form,
          joinTKeyParams([parentTKey, finding.tKey ?? ''])
        )
      )
    )
    const { deepSections } = setAnimationTerms(
      findingForm.form,
      findingForm.previousForm,
      finding ?? {},
      level2
    )
    setFindingSections(deepSections)
  }, [findingForm.form, finding])

  return (
    <>
      {finding &&
        finding.children?.map((option, index) => (
          <div key={`${option.tKey}-${index}`} id="nova-report-Finding-Section-Children-root">
            <FindingSectionRow
              finding={option}
              parentTKey={joinTKeyParams([parentTKey, finding.tKey ?? '', option.tKey ?? ''])}
              setFormData={setFormData}
              isDisabled={isDisabled}
              isLevel4={isLevel4}
              renderFindingLabel={!!(finding.children?.length && finding.children?.length > 1)}
            />

            <FindingSectionLevel5
              option={findingSections}
              finding={option}
              parentTkey={joinTKeyParams([
                parentTKey,
                finding.tKey ?? '',
                option.tKey ?? '',
                findingSections?.tKey ?? '',
              ])}
              isDisabled={isDisabled}
              workflow={finding}
            />
          </div>
        ))}
    </>
  )
}
