import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { joinTKeyParams, splitTKeyParams, tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import { compareArraysByProperty } from '../../util/animationsUtil'

export const setDeepLevelAnimationTerms = (
  localOption: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  option: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  finding: ZipModuleTerminologyCommonTerminologyNodeDto,
  parentTkey: string,
  currentForm: IFindingForm,
  previousForm: IFindingForm
) => {
  // get current and previous selected options for this parentTkey (optical diagnosis,...)
  const { currentSelectedOption, previousSelectedOption } = getSelectedOptions(
    parentTkey,
    currentForm,
    previousForm
  )

  // get current and previous selected options terminology
  const { currentLevel3FromTerminology, previousLevel3FromTerminology } = getLevel3sFromTerminology(
    finding,
    currentSelectedOption ?? '',
    previousSelectedOption ?? ''
  )

  const shouldAnimateOpacityEnter =
    previousSelectedOption &&
    tKeysMatch(splitTKeyParams(previousSelectedOption)[3], localOption?.tKey) &&
    !getPreviousAndCurrentLevel3sShareLevel5Children(localOption, previousLevel3FromTerminology)

  const shouldAnimateOpacityExit =
    currentSelectedOption &&
    tKeysMatch(splitTKeyParams(currentSelectedOption)[3], option?.tKey) &&
    !getPreviousAndCurrentLevel3sShareLevel5Children(option, previousLevel3FromTerminology) &&
    previousLevel3FromTerminology &&
    hasLevel3DeepLevels(previousLevel3FromTerminology) &&
    hasLevel3DeepLevels(option)

  const shouldAnimateHeightEnter =
    currentSelectedOption &&
    hasLevel3DeepLevels(currentLevel3FromTerminology) &&
    tKeysMatch(splitTKeyParams(currentSelectedOption)[3], option?.tKey)

  const shouldAnimateHeightExit =
    previousSelectedOption &&
    tKeysMatch(splitTKeyParams(previousSelectedOption)[3], localOption?.tKey) &&
    (!currentSelectedOption || !hasLevel3DeepLevels(option))

  return {
    shouldAnimateHeightEnter,
    shouldAnimateHeightExit,
    shouldAnimateOpacityEnter,
    shouldAnimateOpacityExit,
  }
}

export const getLevel3sFromTerminology = (
  lvl2: ZipModuleTerminologyCommonTerminologyNodeDto,
  currentFormChangedLevel3: string,
  previousFormChangedLevel3: string
) => {
  const currentLevel3Key = splitTKeyParams(currentFormChangedLevel3)[3]
  const previousLevel3Key = splitTKeyParams(previousFormChangedLevel3)[3]
  const currentLevel3FromTerminology = lvl2.children?.find(
    (lvl3: ZipModuleTerminologyCommonTerminologyNodeDto) => tKeysMatch(lvl3.tKey, currentLevel3Key)
  )
  const previousLevel3FromTerminology = lvl2.children?.find(
    (lvl3: ZipModuleTerminologyCommonTerminologyNodeDto) => tKeysMatch(lvl3.tKey, previousLevel3Key)
  )
  return { currentLevel3FromTerminology, previousLevel3FromTerminology }
}

export const getPreviousAndCurrentLevel3sShareLevel5Children = (
  currentLevel3FromTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  previousFormChangedLevel3: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
) => {
  if (
    currentLevel3FromTerminology?.children &&
    currentLevel3FromTerminology?.children.length > 0 &&
    previousFormChangedLevel3?.children &&
    previousFormChangedLevel3?.children.length > 0
  ) {
    return compareArraysByProperty(
      currentLevel3FromTerminology.children[0].children ?? [],
      previousFormChangedLevel3.children[0].children ?? [],
      'tKey'
    )
  }
  return false
}

const getSelectedOptions = (
  parentTKey: string,
  currentForm: IFindingForm,
  previousForm: IFindingForm
) => {
  const currentSelectedOption = Object.keys(currentForm).find((key) => key === parentTKey)
  const previousSelectedOption = Object.keys(previousForm).find((key) => {
    const parentTKeyWithoutLevel3 = joinTKeyParams(splitTKeyParams(parentTKey).slice(0, -1))
    const optionWithoutLevel3 = joinTKeyParams(splitTKeyParams(key).slice(0, -1))
    return parentTKeyWithoutLevel3 === optionWithoutLevel3
  })
  return { currentSelectedOption, previousSelectedOption }
}

const hasLevel3DeepLevels = (level3: ZipModuleTerminologyCommonTerminologyNodeDto | undefined) => {
  return (
    level3?.children &&
    level3.children.length > 0 &&
    level3.children[0].children &&
    level3.children[0].children.length > 0
  )
}

export const findArrayDifference = (arr1: string[], arr2: string[]) => {
  // Use spread operator to convert Set back to array
  let difference = arr1.filter((element) => !arr2.find((e) => e === element))
  if (difference.length === 0) {
    difference = arr2.filter((element) => !arr1.find((e) => e === element))
  }
  return difference
}
