import { PlusIcon } from '@novax/zip-frontend-library'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import styles from '../../styles.module.scss'
interface IProps {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  handleClick: (finding: ZipModuleTerminologyCommonTerminologyNodeDto) => void
  disabledHelper: boolean
}

export const AddContainerButton = ({ finding, handleClick, disabledHelper }: IProps) => {
  return (
    <div className={`${styles.finding}`} id="nova-report-Add-Container-Button-root">
      <button
        data-testid="nova-report-Add-Container-Button-button"
        id="nova-report-Add-Container-Button-button"
        className={styles.containerNumberButton}
        value="+"
        onClick={() => handleClick(finding)}
        disabled={disabledHelper}
        name="+"
      >
        <PlusIcon />
      </button>
    </div>
  )
}
