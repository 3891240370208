import { Switch } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getIsContainerToggleDisabled,
  getShowContainersOnly,
  setShowContainersOnly,
} from 'features/dataCapture/dataCaptureSlice'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'

const ContainersToggle = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isContainerToggleDisabled = useAppSelector(getIsContainerToggleDisabled)
  const showContainersOnly = useAppSelector(getShowContainersOnly)
  const toggleContainers = (e: boolean) => {
    dispatch(setShowContainersOnly(e))
  }

  return (
    <div className={styles.toggleContainer} id="nova-report-Containers-Toggle-root">
      <span
        className={`${styles.containersOnly} ${isContainerToggleDisabled ? styles.disabled : ''}`}
      >
        {t('dataCapture.showContainersOnly')}
      </span>
      <Switch
        data-testid="nova-report-Containers-Toggle-switch"
        onChange={toggleContainers}
        checked={showContainersOnly}
        disabled={isContainerToggleDisabled}
        className={styles.toggle}
      />
    </div>
  )
}

export default ContainersToggle
