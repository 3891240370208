import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getBasicInformation,
  getEditingFindingId,
  getFindingForm,
  getInitialFindingForm,
  getIsProcedureFinished,
  getIsSubmitLoading,
  getObservations,
  getSections,
  setEditingFindingId,
  setFindingForm,
  setInitialFindingForm,
} from 'features/dataCapture/dataCaptureSlice'
import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { joinTKeyParams, tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import {
  determineSelectedWorkflowObjects,
  resolveDropdownIdsToTKeysList,
  shouldDisableComponent,
} from 'features/dataCapture/utils/utils'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

import FindingSection from './subcomponents/findingSection/FindingSection'
import LoadingPathology from './subcomponents/loadingPathology/LoadingPathology'

interface IProps {
  isLoading: boolean
}

const DataCaptureAddFinding = ({ isLoading }: IProps) => {
  const sections = useAppSelector(getSections)
  const basicInformation = useAppSelector(getBasicInformation)
  const findingForm = useAppSelector(getFindingForm)
  const initialFindingForm = useAppSelector(getInitialFindingForm)
  const editingFindingId = useAppSelector(getEditingFindingId)
  const observations = useAppSelector(getObservations)
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)
  const isSubmitting = useAppSelector(getIsSubmitLoading)
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // each time we render the component we select the currently opened section
  // from url parameters
  const { workflow, workflowChild } = determineSelectedWorkflowObjects(
    sections,
    params as { workflowTKey: string; childTKey: string }
  )

  const disabledHelper = (componentName: string) => {
    if (basicInformation?.procedureStatus) {
      return shouldDisableComponent(
        isProcedureFinished || isSubmitting,
        findingForm.form,
        initialFindingForm.form,
        editingFindingId,
        componentName
      )
    }
    return false
  }

  useEffect(() => {
    if (params.dropdownId) {
      // setting editingFindingId for non-unique
      dispatch(setEditingFindingId(params.dropdownId))
    } else if (
      workflow &&
      workflow.lvl === 0 &&
      !workflow.alwaysSelected &&
      params.childTKey &&
      tKeysMatch(workflow.tKey, 'medication')
    ) {
      // setting editingFindingId for medication
      dispatch(setEditingFindingId(params.childTKey))
    } else {
      // setting editingFindingId for unique
      const observationTKey = params.workflowTKey
      const existingObservation = observations?.find(
        (observation: ZipModuleCommonDtosObservationDto) =>
          tKeysMatch(observation?.tKey, observationTKey)
      )

      const { workflow } = determineSelectedWorkflowObjects(sections, {
        workflowTKey: params.workflowTKey ?? '',
        childTKey: params.childTKey ?? null,
      })

      // if workflow object is not found, redirect to data capture overview
      // todo: fix workflowChild check also
      if (sections?.length > 0 && !workflow) {
        //if (sections?.length > 0 && (!workflow || (!workflowChild && params?.childTKey))) {
        navigate(`/data-capture/${params.id}/${params.sectionTKey}`, {
          replace: true,
        })
      }

      // dispatch(setEditingFindingId(`${existingObservation?.id},1` ?? ''))
      // dispatch first level1 from dropdown Ids - matches ids in the DataCaptureCard
      workflow?.isUnique &&
        existingObservation &&
        existingObservation.components &&
        existingObservation.components[0] &&
        existingObservation.components[0].dropdownIds &&
        dispatch(setEditingFindingId(existingObservation.components[0].dropdownIds[0]))
    }
  }, [sections, params])

  useEffect(() => {
    const terminologyObservation = sections
      .find((section) => section.terminology?.some((t) => t.id === workflow?.id))
      ?.terminology?.find((t) => t.id === workflow?.id)

    if (editingFindingId !== '') {
      if (terminologyObservation?.isUnique) {
        // find unique observation
        const dropdownIds = observations.find((o) => o.id === workflow?.id)?.components
        const dropdownIdsToEdit =
          dropdownIds &&
          JSON.parse(JSON.stringify(dropdownIds))
            ?.map((c: ZipModuleCommonDtosComponentDto) => {
              c.dropdownIds?.shift()
              return c.dropdownIds
            })
            .flat()

        const keys =
          dropdownIdsToEdit &&
          dropdownIdsToEdit.map((dropdownIds: string) =>
            resolveDropdownIdsToTKeysList(
              sections,
              dropdownIds,
              terminologyObservation?.tKey ?? '',
              undefined
            )
          )
        // Combine the arrays into an object
        const resultObject = {}

        keys?.forEach((key: string, index: number) => {
          // @ts-ignore
          resultObject[key] = dropdownIdsToEdit[index]
        })
        dispatch(
          setFindingForm({
            form: resultObject as IFindingForm,
            errors: [],
          })
        )

        dispatch(setInitialFindingForm(findingForm))

        //set dropdownIdsToEdit to findingForm
      } else {
        const observationToChange = observations.find(
          (observation) => observation.id === workflow?.id
        )
        const findingToEdit = observationToChange?.components?.find(
          (component) => component.dropdownIds && component.dropdownIds[0] === editingFindingId
        )

        // if finding observation is not found, redirect to data capture overview
        if (editingFindingId?.length > 0 && observations?.length > 0 && !findingToEdit) {
          navigate(`/data-capture/${params.id}/${params.sectionTKey}`, {
            replace: true,
          })
        }

        if (findingToEdit && editingFindingId) {
          // for medication we have `level1,index`, for other findings we have slice and remove first id
          let keysToResolve = tKeysMatch(terminologyObservation?.tKey, 'medication')
            ? findingToEdit?.dropdownIds
            : findingToEdit?.dropdownIds?.slice(1)

          let medicationLevel1WithAmountId: string | undefined = undefined
          if (
            tKeysMatch(terminologyObservation?.tKey, 'medication') &&
            findingToEdit.text &&
            findingToEdit.text !== ''
          ) {
            // find level 1
            const selectedLevel1 = terminologyObservation?.children?.find(
              (lvl1) =>
                findingToEdit.dropdownIds && lvl1.id === findingToEdit.dropdownIds[0].split(',')[0]
            )
            if (
              selectedLevel1 !== undefined &&
              selectedLevel1.children &&
              selectedLevel1.children?.length > 0
            ) {
              // level1 children[0] should be `amount`
              medicationLevel1WithAmountId = `${selectedLevel1.id},${
                selectedLevel1?.children && selectedLevel1?.children[0].id
              }`
              //push new level1,level2 ids to keysToResolve
              keysToResolve = [...[...(keysToResolve ?? [])], medicationLevel1WithAmountId]
            }
            //before each dropdownId push medicationId
            keysToResolve = keysToResolve?.map((dropdownId, index) =>
              [
                terminologyObservation?.id,
                index === 0 ? dropdownId.split(',')[0] : dropdownId,
              ].join(',')
            )
          }

          // generate keys from values to set to form
          const keys = keysToResolve
            ?.filter((item, index) => keysToResolve?.indexOf(item) === index)
            ?.map((dropdownIds: string) =>
              resolveDropdownIdsToTKeysList(
                sections,
                dropdownIds,
                observationToChange?.tKey ?? '',
                tKeysMatch(terminologyObservation?.tKey, 'medication')
                  ? terminologyObservation
                  : undefined
              )
            )

          let values: string[] | undefined = undefined
          if (tKeysMatch(terminologyObservation?.tKey, 'medication')) {
            if (findingToEdit.dropdownIds) {
              // deep copy
              const tempIds = JSON.parse(JSON.stringify(findingToEdit.dropdownIds))
              // remove index from first dropdownIds to select level1
              const lvl1Id = tempIds[0].split(',')[0]
              const newIds = []
              newIds.push(lvl1Id)
              if (findingToEdit.text && findingToEdit.text !== '') {
                // manually set amount to dropdownIds `level1Id,level2Id,amountValue`
                newIds.push(`${medicationLevel1WithAmountId},${findingToEdit.text}`)
              }

              // eslint-disable-next-line
              // now we remove first ID `level1,index` because we need to have only levelIds, no index
              values = newIds
            }
          } else {
            // remove first ID `level1,index`
            values = findingToEdit?.dropdownIds?.slice(1)
          }

          // Combine the arrays into an object
          const resultObject = {}

          keys?.forEach((key: string, index: number) => {
            // @ts-ignore
            resultObject[key] = values[index]
          })

          if (!localStorage.getItem(window.location.pathname)) {
            dispatch(
              setFindingForm({
                form: resultObject as IFindingForm,
                errors: [],
              })
            )
          }
        }
        if (editingFindingId && editingFindingId != '') {
          dispatch(setInitialFindingForm(findingForm))
        }
      }
    }
  }, [editingFindingId])

  return (
    <>
      {isLoading ? (
        <LoadingPathology />
      ) : // if alwaysSelected is true render the selected Level1 (current workflow) children as finding sections
      workflow?.alwaysSelected ? (
        workflow?.children?.map(
          (finding: ZipModuleTerminologyCommonTerminologyNodeDto, index: number) => {
            return (
              <FindingSection
                key={index}
                finding={finding}
                isDisabled={disabledHelper('findings')}
                isLevel4={false}
                parentTkey={params.workflowTKey ?? ''}
                workflowTitle={workflow?.value ?? ''}
                parentId={finding?.id ?? ''}
              />
            )
          }
        )
      ) : // if alwaysSelected is false and renderWorkflowChildren is true render the selected workflowChild children as finding sections
      workflow?.renderWorkflowChildren ? (
        workflowChild?.children?.map(
          (finding: ZipModuleTerminologyCommonTerminologyNodeDto, index: number) => {
            return (
              <FindingSection
                key={index}
                finding={finding}
                isDisabled={disabledHelper('findings')}
                isLevel4={false}
                parentTkey={
                  (params.workflowTKey &&
                    workflowChild.tKey &&
                    joinTKeyParams([params.workflowTKey, workflowChild.tKey])) ??
                  ''
                }
                workflowTitle={finding?.value ?? ''}
                parentId={workflowChild?.id ?? ''}
              />
            )
          }
        )
      ) : (
        // if alwaysSelected is false and renderWorkflowChildren is false render the selected workflow as a finding section
        <>
          {workflow && (
            <FindingSection
              finding={workflow}
              isDisabled={disabledHelper('findings')}
              isLevel4={false}
              parentTkey={params.workflowTKey ?? ''}
              workflowTitle={workflow?.value ?? ''}
              parentId={workflow?.id ?? ''}
            />
          )}
        </>
      )}
    </>
  )
}

export default DataCaptureAddFinding
