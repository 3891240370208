import { Modal, ModalRefType, ModalType } from '@novax/zip-frontend-library'
import * as React from 'react'
import { useEffect, useRef } from 'react'

import { useGlobalModalContext } from '../GlobalModalProvider'

interface Props {
  id: string
  type: ModalType
}

const GlobalAntModal: React.FC<Props> = ({ type }) => {
  const { hideModal, modalData } = useGlobalModalContext()
  const { modalProps } = modalData
  const { title, content, cancelText, width, okText, onOk, onCancel } = modalProps || {}
  const modalRef = useRef<ModalRefType | null>(null)

  // trigger open on component mount
  useEffect(() => {
    modalRef.current?.triggerOpen()
  }, [])

  const handleHideModal = () => {
    // todo check if needed modalRef.current?.close()
    hideModal()
  }

  const handleOnOK = () => {
    if (onOk) {
      onOk()
    }

    handleHideModal()
  }
  const handleOnCancel = () => {
    if (onCancel) {
      onCancel()
    }

    handleHideModal()
  }
  return (
    <div data-testid="global-modal-root" id="global-modal-root">
      <Modal
        ref={modalRef}
        promptType={type}
        open
        title={<span style={{ fontWeight: 600 }}>{title}</span>}
        content={content}
        cancelText={cancelText}
        okText={
          <div style={{ display: 'flex' }}>
            <div>{okText}</div>
          </div>
        }
        onOk={handleOnOK}
        onCancel={handleOnCancel}
        width={width}
        cancelButtonProps={{
          style: { display: cancelText ? 'initial' : 'none' },
        }}
      />
    </div>
  )
}

export default GlobalAntModal
