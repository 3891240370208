import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { ZipModuleFeaturesProceduresResponsesProcedureOverviewDto } from 'services/zipmodule.gen'

import { IProcedures } from './interfaces/IProcedures'

export const initialState: IProcedures = {
  procedures: {
    todoProcedures: [],
    todayProcedures: [],
    submittedProcedures: {},
    numberOfProcedures: 0,
    todoNextPageToken: undefined,
    todayNextPageToken: undefined,
    submittedNextPageToken: undefined,
  },
  currentlyShowingProcedures: 'active',
  isLoading: true,
}

export const procedureOverviewSlice = createSlice({
  name: 'procedureOverviewSlice',
  initialState,
  reducers: {
    setTodayProcedures: (
      state,
      action: PayloadAction<{
        procedures: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[]
      }>
    ) => {
      if (action.payload) {
        state.procedures.todayProcedures = action.payload.procedures
      }
    },
    setTodoProcedures: (
      state,
      action: PayloadAction<{
        procedures: ZipModuleFeaturesProceduresResponsesProcedureOverviewDto[]
      }>
    ) => {
      if (action.payload) {
        state.procedures.todoProcedures = action.payload.procedures
      }
    },
    setSubmittedProcedures: (
      state,
      action: PayloadAction<{
        procedures: {
          [date: string]: Array<ZipModuleFeaturesProceduresResponsesProcedureOverviewDto>
        }
      }>
    ) => {
      if (action.payload) {
        state.procedures.submittedProcedures = action.payload.procedures
      }
    },
    setNumberOfProcedures: (state, action: PayloadAction<number>) => {
      state.procedures.numberOfProcedures = action.payload
    },
    setCurrentlyShowingProcedures: (state, action: PayloadAction<'active' | 'finished'>) => {
      state.currentlyShowingProcedures = action.payload
    },
    setIsLoadingProceduresScreen: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setToDoNextPageToken: (state, action: PayloadAction<string | undefined>) => {
      state.procedures.todoNextPageToken = action.payload
    },
    setTodayNextPageToken: (state, action: PayloadAction<string | undefined>) => {
      state.procedures.todayNextPageToken = action.payload
    },
    setSubmittedNextPageToken: (state, action: PayloadAction<string | undefined>) => {
      state.procedures.submittedNextPageToken = action.payload
    },
    setProceduresResetState: (state) => {
      state.procedures = initialState.procedures
    },
  },
})

export const {
  setTodayProcedures,
  setTodoProcedures,
  setSubmittedProcedures,
  setCurrentlyShowingProcedures,
  setIsLoadingProceduresScreen,
  setNumberOfProcedures,
  setToDoNextPageToken,
  setTodayNextPageToken,
  setSubmittedNextPageToken,
  setProceduresResetState,
} = procedureOverviewSlice.actions

export const getProcedures = (state: RootState) => state.procedures.procedures
export const getCurrentlyShowingProcedures = (state: RootState) =>
  state.procedures.currentlyShowingProcedures
export const getIsLoadingProceduresScreen = (state: RootState) => state.procedures.isLoading

export const getToDoNextPageToken = (state: RootState) =>
  state.procedures.procedures.todoNextPageToken

export const getTodayNextToken = (state: RootState) =>
  state.procedures.procedures.todayNextPageToken

export const getSubmittedNextToken = (state: RootState) =>
  state.procedures.procedures.submittedNextPageToken

export default procedureOverviewSlice.reducer
