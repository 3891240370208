import { Divider, Skeleton } from 'antd'
import styled from 'styled-components'

import screenBreakpoints from '../../scss/screenBreakpoints.scss'

type ReportSkeletonProps = {
  height: number
  isThumb: boolean
  isSidebar?: boolean
}

const StyledSkeleton = styled.div<{ height: number; isThumb: boolean; isSidebar?: boolean }>`
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
  background: white;
  padding: ${(props) => `${props.isThumb ? '5px' : '24px'}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .header-left {
      width: ${(props) => `${props.isThumb ? '15px' : '40%'}`};
      height: ${(props) => `${props.isSidebar ? '18px' : '34px'}`};
    }

    .header-right {
      width: ${(props) => `${props.isThumb ? '60px' : '95px'}`};
      height: ${(props) => `${props.isSidebar ? '14px' : '26px'}`};
    }
    @media screen and (max-width: ${screenBreakpoints.large}) {
      .header-left {
        height: ${(props) => `${props.isThumb ? '8px' : ''}`};
      }

      .header-right {
        height: ${(props) => `${props.isThumb ? '8px' : ''}`};
      }
    }
  }

  .divider {
    margin: 8px 0;
    @media screen and (max-width: ${screenBreakpoints.large}) {
      margin: 3px 0;
    }
  }

  .patient {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: ${(props) => `${props.isThumb ? '5px' : '1rem'}`};
    grid-column-gap: ${(props) => `${props.isThumb ? '5px' : '2rem'}`};

    .patient-section {
      display: flex;
      flex-direction: column;

      .patient-title {
        height: ${(props) => `${props.isSidebar ? '8px' : '16px'}`};
        width: 100%;
        margin-bottom: 4px;
        min-width: unset !important;
      }

      .patient-subtitle {
        height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
        width: 80%;
        min-width: unset !important;
      }
    }
    @media screen and (max-width: ${screenBreakpoints.large}) {
      grid-row-gap: ${(props) => `${props.isSidebar ? '2px' : '.5rem'}`};
      grid-column-gap: ${(props) => `${props.isSidebar ? '2px' : '1rem'}`};
      .patient-title {
        height: ${(props) => `${props.isSidebar ? '6px !important' : '12px !important'}`}
        margin-bottom: 2px !important;
      }
      .patient-subtitle {
        height: ${(props) => `${props.isSidebar ? '4px !important' : '8px !important'}`}
      }
    }
  }

  .annotation {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${(props) => `${props.isThumb ? '5px' : '1rem'}`};

    .annotation-section {
      display: flex;
      flex-direction: column;

      .annotation-title {
        width: 70%;
        height: ${(props) => `${props.isSidebar ? '8px' : '16px'}`};
        margin-bottom: 4px;
        min-width: unset !important;
      }

      .annotation-subtitle {
        width: 100%;
        height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
        min-width: unset !important;
      }
    }
    @media screen and (max-width: ${screenBreakpoints.large}) {
      .annotation-title {
        height: ${(props) => `${props.isSidebar ? '6px !important' : '12px !important'}`}
        margin-bottom: 2px !important;
      }
      .annotation-subtitle {
        height: ${(props) => `${props.isSidebar ? '4px !important' : '8px !important'}`}
      }
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: ${(props) => `${props.isSidebar ? '.5rem' : '1rem'}`};
    .content {
      .content-section {
        margin-bottom: ${(props) => `${props.isSidebar ? '6px' : '18px'}`};
        display: ${(props) => `${props.isSidebar ? 'flex' : 'block'}`};
        flex-direction: ${(props) => `${props.isSidebar ? 'column' : ''}`};
      }

      .findings-title {
        width: 20%;
        height: ${(props) => `${props.isSidebar ? '6px' : '16px'}`};
        margin-right: 6px;
      }

      .findings-section {
        width: 100%;
        padding-left: 8px;
      }

      @media screen and (max-width: ${screenBreakpoints.large}) {
        .content-section {
          margin-bottom: 6px;
        }

        .findings-title {
          height: ${(props) => `${props.isSidebar ? '6px' : '16px'}`};
          margin-right: 3px;
          line-height: ${(props) => `${props.isSidebar ? '6px' : '16px'}`};
        }
        .findings-section {
          padding-left: 4px;
        }
      }
    }

    .images {
      display: grid;
      grid-row-gap: ${(props) => `${props.height / 1.414 > 520 ? '8px' : '2px'}`};

      .image {
        height: ${(props) => `${props.isThumb ? '48px' : '108px'}`};
        width: 100%;
      }

      .label-section {
        width: 100%;
        height: ${(props) => `${props.isSidebar ? '8px' : '14px'}`};
        margin: ${(props) => `${props.isSidebar ? '2px 0 4px' : '4px 0 8px'}`};
        display: flex;
        flex-direction: row;
        gap: 8px;
        position: relative;

        .img-tag {
          height: ${(props) => `${props.isSidebar ? '8px' : '14px'}`};
          width: 0;
          flex: 0 1 15px;

          .inner {
            width: 100%;
            height: ${(props) => `${props.isSidebar ? '8px' : '14px'}`};
            min-width: 100%;
          }
        }

        .label {
          height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
          width: 100%;

          .inner {
            width: 100%;
            height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
            min-width: 100%;
          }
        }
      }
    }

    .content-paragraph,
    .content-paragraph-short {
      width: 100%;
      height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
      margin-top: ${(props) => `${props.isSidebar ? '3px' : '5px'}`};
      display: block;

      &-short {
        width: 80%;
      }
      @media screen and (max-width: ${screenBreakpoints.large}) {
        height: ${(props) => `${props.isSidebar ? '4px' : '8px'}`};
        margin-top: ${(props) => `${props.isSidebar ? '2px' : '5px'}`};
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: end;

    div {
      display: flex;
      flex-direction: column;
      gap: ${(props) => `${props.isSidebar ? '2px' : '4px'}`};

      .footer-title {
        min-width: ${(props) => `${props.isSidebar ? '30px' : '119px'}`};
        width: ${(props) => `${props.isSidebar ? '30%' : '119px'}`};
        height: ${(props) => `${props.isSidebar ? '4px' : '12px'}`};
      }
      .footer-subtitle {
        min-width: ${(props) => `${props.isSidebar ? '50px' : '152px'}`};
        width: ${(props) => `${props.isSidebar ? '50%' : '152px'}`};
        height: ${(props) => `${props.isSidebar ? '8px' : '19px'}`};
      }
    }
    .footer-logo {
      width: ${(props) => `${props.isSidebar ? '20%' : '91px'}`};
      height: ${(props) => `${props.isSidebar ? '12px' : '29px'}`};
    }
    @media screen and (max-width: ${screenBreakpoints.large}) {
      div {
        gap: 2px;
        .footer-title {
          width: 40%;
          height: ${(props) => `${props.isSidebar ? '4px' : '12px'}`};;
        }
        .footer-subtitle {
          width: 60%;
          height: ${(props) => `${props.isSidebar ? '8px' : '19px'}`};
        }
      }
      .footer-logo {
        width: 40%;
        height: ${(props) => `${props.isSidebar ? '12px' : '18px'}`};
      }
    }
  }
`

const ReportSkeleton = ({ height, isThumb, isSidebar }: ReportSkeletonProps) => {
  return (
    <StyledSkeleton height={height} isThumb={isThumb} isSidebar={isSidebar ?? false}>
      <div className="main-wrapper" id="nova-report-Report-Skeleton-root">
        <div className="header" id="nova-report-Report-Skeleton-root">
          <Skeleton.Button className="header-left" active />
          <Skeleton.Button className="header-right" active />
        </div>
        <Divider className="divider" />
        <div className="patient">
          {[...Array(height < 250 ? 8 : 8)].map((e, i) => (
            <div className="patient-section" key={i}>
              <Skeleton.Button active className="patient-title" />
              <Skeleton.Button active className="patient-subtitle" />
            </div>
          ))}
        </div>
        <Divider className="divider" />
        <div className="annotation">
          {[...Array(4)].map((e, i) => (
            <div className="annotation-section" key={i}>
              <Skeleton.Button active className="annotation-title" />
              <Skeleton.Button active className="annotation-subtitle" />
            </div>
          ))}
        </div>
        <Divider className="divider" />
        <div className="main-content">
          <div className="content">
            <div className="content-section">
              <Skeleton.Button active className="findings-title" />
              <div className="findings-section">
                {[...Array(height < 250 ? 4 : 9)].map((e, i) => (
                  <Skeleton.Button key={i} active className="content-paragraph" />
                ))}
                <Skeleton.Button active className="content-paragraph-short" />
              </div>
            </div>
            {height > 250 && (
              <div className="content-section">
                <Skeleton.Button active className="findings-title" />
                <div className="findings-section">
                  {[...Array(9)].map((e, i) => (
                    <Skeleton.Button key={i} active className="content-paragraph" />
                  ))}
                  <Skeleton.Button active className="content-paragraph-short" />
                </div>
              </div>
            )}
            <div className="content-section">
              <Skeleton.Button active className="findings-title" />
              <Skeleton.Button active className="content-paragraph" />
            </div>
          </div>

          <div className="images">
            {[...Array(height < 250 ? 1 : 3)].map((e, i) => (
              <div key={i}>
                <Skeleton.Button active className="image" />
                <div className="label-section">
                  <div className="img-tag">
                    <Skeleton.Button active block className="inner" />
                  </div>
                  <div className="label">
                    <Skeleton.Button active block className="inner" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="footer-wrapper">
        <Divider className="divider" />
        <div className="footer">
          <div>
            <Skeleton.Button active className="footer-title" />
            <Skeleton.Button active className="footer-subtitle" />
          </div>
          <div>
            <Skeleton.Button active className="footer-logo" />
          </div>
        </div>
      </div>
    </StyledSkeleton>
  )
}

export default ReportSkeleton
