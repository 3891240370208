import './App.scss'
import './i18n'

import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { AuthState, getToken, useAuth, userApplicationAccess } from '@novax/os'
import { App as AntApp, Button, ConfigProvider } from 'antd'
import axios from 'axios'
import NotForClinicalUseBanner from 'components/banner/NotForClinicalUseBanner'
import { antdDesignToken, combinedTheme } from 'configs/antDesignAndThemeConfig'
import LoadingScreen from 'features/connect/loadingScreen/LoadingScreen'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useGetRealm } from 'utils/novaOsAppUtils'

import { store } from './app/store'
import { reactPlugin } from './AppInsights'
import { GlobalModalProvider } from './components/GlobalModalProvider/GlobalModalProvider'
import OfflineContext from './components/OfflineContext/OfflineContext'
import SignalRService from './features/connect/signalR/SignalRService'
import Router from './routes/routes'

function App() {
  const { i18n, t } = useTranslation()
  const { user, logout, authState } = useAuth()
  const tenant = useGetRealm()

  const hasAppAccess = useMemo(
    () =>
      authState === AuthState.AUTHENTICATED &&
      !!user &&
      userApplicationAccess(user).some((u) => u.key === 'report'),
    [user, authState]
  )

  const [isLangFetched, setIsLangFetched] = useState<boolean>(!!localStorage.getItem('i18nextLng'))

  // Fetch language for a user if needed
  useEffect(() => {
    const fetchLanguage = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/languages`, {
        headers: { Authorization: `Bearer ${getToken()}`, 'Nova-Tenant-Id': tenant },
        validateStatus: () => true,
      })

      // If language fetch fails, console it and mark as fetched to remove loading screen
      if ((response.status < 200 && response.status > 299) || !response.data.language) {
        console.log('Language fetch failed! Fallback to EN')
      }
      // save language to local storage & apply it
      else {
        localStorage.setItem('i18nextLng', response.data.language)

        document.dispatchEvent(
          new CustomEvent('langChanged', {
            detail: response.data.language,
          })
        )
      }
      setIsLangFetched(true)
    }
    if (!isLangFetched && authState === AuthState.AUTHENTICATED) {
      fetchLanguage()
    }
  }, [isLangFetched, authState])

  useEffect(() => {
    // Start SignalR connection when the component mounts
    SignalRService.startConnection()

    // Clean up SignalR listener and stop the connection when the component unmounts
    return () => {
      SignalRService.stopConnection()
    }
  }, [])

  document.addEventListener(
    'langChanged',
    (event: Event) => {
      i18n.changeLanguage((event as CustomEvent).detail, (err) => {
        if (err) {
          return console.error(
            `Error while changing the language to ${(event as CustomEvent).detail}`,
            err
          )
        } else {
          localStorage.setItem('i18nextLng', (event as CustomEvent).detail)
        }
      })
    },
    false
  )

  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
      onError={() => <h1>An unexpected error has occurred. Please refresh your browser.</h1>}
    >
      <Provider store={store}>
        <ConfigProvider theme={antdDesignToken}>
          <AntApp>
            <GlobalModalProvider>
              <div className="app-container" id="nova-report-App-root">
                <NotForClinicalUseBanner />
                <div className="theme-container">
                  <ThemeProvider theme={combinedTheme}>
                    {
                      // This was used when pairing enabled
                      // <ClientIdProvider>
                      //   <PairingProvider>
                      //     <Router />
                      //   </PairingProvider>
                      // </ClientIdProvider>
                    }
                    {isLangFetched ? (
                      <>
                        {hasAppAccess ? (
                          // User is authenticated, language is fetched and user has access to the Report App
                          <>
                            <Router />
                          </>
                        ) : (
                          // User is authenticated, language is fetched and user has NO access to the Report App
                          <div className="nova-x-report-app-tsx-message-without-design">
                            <span>Logged in user has no access to Nova Report!</span>
                            <Button onClick={() => logout()}>Log out</Button>
                          </div>
                        )}
                      </>
                    ) : (
                      // User is authenticated but language is still not fetched
                      <LoadingScreen label={t('common.loading')} />
                    )}
                  </ThemeProvider>
                </div>
                <OfflineContext />
              </div>
            </GlobalModalProvider>
          </AntApp>
        </ConfigProvider>
      </Provider>
    </AppInsightsErrorBoundary>
  )
}

export default App
