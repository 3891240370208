import { LiveIcon } from '@novax/zip-frontend-library'
import * as React from 'react'

import LoadingCard from '../loading/LoadingCard'
import styles from './styles.module.scss'

interface Props {
  isLive?: boolean
  nameInitials?: string
  isLoading?: boolean
}

const LiveIndicatorSmall = ({ children }: { children: React.ReactElement }) => {
  return (
    <span
      className={styles.liveIndicatorWrapperSmall}
      data-testid="nova-report-Avatar-root-live"
      id="nova-report-Avatar-root"
    >
      {children}
      <LiveIcon size={18} />
      <div className={styles.pulse1small} />
      <div className={styles.pulse2small} />
    </span>
  )
}

const Avatar: React.FC<Props> = ({ isLive, nameInitials, isLoading }) => {
  return (
    <div className={styles.avatarContainer}>
      {!isLoading ? (
        <>
          {isLive ? (
            <LiveIndicatorSmall>
              <span className={styles.activeBadge}>{nameInitials}</span>
            </LiveIndicatorSmall>
          ) : (
            <span className={styles.badgeSmall}>{nameInitials}</span>
          )}
        </>
      ) : (
        <LoadingCard height="40px" width="40px" borderRadius={'20px'} />
      )}
    </div>
  )
}

export default Avatar
