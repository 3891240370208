import axios from 'axios'

interface fetchPdfArgs {
  procedureId: string
  token: string
  tenant: string
  procedureTimeInUsersTimezone?: string
}
export const fetchPdf = async ({
  procedureId,
  token,
  tenant,
  procedureTimeInUsersTimezone,
}: fetchPdfArgs): Promise<[Blob, string[]] | null> => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/api/reports/Preview/${procedureId}.pdf`,
      params: { dateTime: procedureTimeInUsersTimezone },
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        'Nova-Tenant-Id': tenant,
        'Nova-Tenant-Language': localStorage.getItem('i18nextLng') ?? 'en',
      },
    })
    const pageTitles = response.headers['pdf-page-titles']
    const parsedPageTitles = pageTitles ? JSON.parse(pageTitles) : []
    return [response.data, parsedPageTitles]
  } catch (error) {
    console.error(error)
    return null
  }
}

export const printPdfFromBlob = (blob: Blob, renderElement: HTMLElement | null): void => {
  if (!renderElement) return

  try {
    // remove frame if it was rendered previously
    const oldFrame = document.getElementById('NOVA_X_report_print_iframe')
    oldFrame &&
      URL.revokeObjectURL(
        (oldFrame /* istanbul ignore next: test out of scope */ as HTMLIFrameElement)?.src
      )
    oldFrame?.remove()

    const hiddenFrame = document.createElement('iframe')
    hiddenFrame.setAttribute('id', 'NOVA_X_report_print_iframe')
    hiddenFrame.onload = () => {
      // Safari and Firefox fire event too early and sometimes printing empty pages, 500ms should be enough
      /* istanbul ignore next: test out of scope */
      setTimeout(() => hiddenFrame.contentWindow?.print(), 500)
    }
    hiddenFrame.src = URL.createObjectURL(blob)
    hiddenFrame.style.position = 'fixed'
    hiddenFrame.style.right = '0'
    hiddenFrame.style.bottom = '0'
    hiddenFrame.style.width = '0'
    hiddenFrame.style.height = '0'
    hiddenFrame.style.border = '0'
    renderElement.appendChild(hiddenFrame)
  } catch (error) {
    // start file download if there's an exception when printing
    /* istanbul ignore next: test out of scope */
    console.error(error)
    /* istanbul ignore next: test out of scope */
    downloadPdfFromBlob(blob)
  }
}

export const downloadPdfFromBlob = (blob?: Blob, fileName?: string) => {
  if (!blob) return
  const url = URL.createObjectURL(blob)
  // Create a hidden anchor element
  const anchor = document.createElement('a')
  anchor.style.display = 'none'
  anchor.href = url
  anchor.download = fileName ?? 'Report'
  document.body.appendChild(anchor)
  // Trigger the download
  anchor.click()

  // Clean up the temporary URL and the anchor element
  URL.revokeObjectURL(url)
  document.body.removeChild(anchor)
}

export const isMobileDevice = () => {
  // Get the user agent string
  const userAgent = navigator.userAgent.toLowerCase()
  // Check if the device is running on Android or iOS mobile
  return !!userAgent.match(/android|iphone|ipad|ipod/i)
}
