import { getToken, updateToken } from '@novax/os'
import { NotificationType, notify } from '@novax/zip-frontend-library'
import { useApiSelector, useAppDispatch, useAppSelector } from 'app/hooks'
import { useGlobalModalContext } from 'components/GlobalModalProvider/GlobalModalProvider'
import { getBasicInformation, getReportsApiGet } from 'features/reportDetails/reportDetailsSlice'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import { usePostApiReportsByIdSubmitMutation } from 'services/zipmodule.gen'
import { CombinedStatus } from 'types'
import { procedureDateTimeFormatted } from 'utils/date'
import { getReportErrorModalConfig } from 'utils/modalConfigs'
import { useGetRealm } from 'utils/novaOsAppUtils'

import useWindowDimensions from '../../utils/useWindowDimensions'
import { getPdfLoadStatus, setIsReportSubmitting, setPdfLoadStatus } from './previewReportSlice'
import styles from './styles.module.scss'
import PreviewReportHeader from './subcomponents/previewReportHeader/PreviewReportHeader'
import PreviewReportLoading from './subcomponents/previewReportLoading/PreviewReportLoading'
import PreviewReportPageControlDock from './subcomponents/previewReportPageControlDock/PreviewReportPageControlDock'
import PreviewReportPdfView from './subcomponents/previewReportPdfView/PreviewReportPdfView'
import PreviewReportSidebar from './subcomponents/previewReportSidebar/PreviewReportSidebar'
import { downloadPdfFromBlob, fetchPdf, printPdfFromBlob } from './utils/pdfUtils'

const PreviewReport = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { showModal, hideModal } = useGlobalModalContext()

  const { id } = useParams() as { id: string }
  const tenant = useGetRealm()

  const procedureBasicInfo = useAppSelector(getBasicInformation)
  const pdfLoadStatus = useAppSelector(getPdfLoadStatus)
  const [{ firstLoadDone: procedureBasicInfoFetched }, refetchReport] =
    useApiSelector(getReportsApiGet)
  const [submitReport] = usePostApiReportsByIdSubmitMutation()

  const rootRef = useRef<HTMLDivElement>(null)

  const [saveSucceeded, setSaveSucceeded] = useState(false)
  const [zoomRatio, setZoomRatio] = useState<number>(
    width <= 768 ? 0.7 : width >= 1280 ? 0.55 : 0.65
  )
  const [pdfFile, setPdfFile] = useState<[Blob, string[]] | null>(null)
  const isUpdatingExistingReport = procedureBasicInfo?.combinedStatus === CombinedStatus.Submitted

  useEffect(() => {
    // set as not started on component mount
    dispatch(setPdfLoadStatus('not started'))
  }, [])

  useEffect(() => {
    // open error modal on pdf load error
    if (pdfLoadStatus === 'error') navigator.onLine && openErrorModal()
  }, [pdfLoadStatus])

  useEffect(() => {
    // start pdf fetch if basic information is loaded and pdf is not already fetched
    if (procedureBasicInfoFetched && pdfLoadStatus === 'not started') getPDFReport()
  }, [procedureBasicInfoFetched, pdfLoadStatus])

  const openErrorModal = () => {
    showModal(
      getReportErrorModalConfig({
        t,
        title: t('previewReports.failedToGet'),
        content: t('previewReports.getFailMessage'),
        okText: t('common.retry'),
        cancelText: t('common.goBack'),
        handleOk: () => {
          handleOk()
          hideModal()
        },
        handleCancel,
      })
    )
  }
  const getPDFReport = async () => {
    if (id) {
      dispatch(setPdfLoadStatus('loading'))
      await updateToken()
      const pdf = await fetchPdf({
        procedureId: id,
        token: getToken() ?? '',
        tenant: tenant ?? '',
        procedureTimeInUsersTimezone: procedureBasicInfo?.procedureDate
          ? procedureDateTimeFormatted(procedureBasicInfo?.procedureDate)
          : undefined,
      })
      if (!pdf) {
        dispatch(setPdfLoadStatus('error'))
      } else {
        dispatch(setPdfLoadStatus('success'))
      }
      if (pdfjs) {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
      }
      setPdfFile(pdf)
    }
  }

  const saveToHisOrRedirect = async () => {
    if (procedureBasicInfo) {
      try {
        dispatch(setIsReportSubmitting(true))
        await submitReport({
          id: id,
        }).unwrap()

        notify({
          type: NotificationType.SUCCESS,
          message: t(
            isUpdatingExistingReport
              ? 'previewReports.updateSuccessReportTitle'
              : 'previewReports.successReportTitle'
          ),
          description: t(
            isUpdatingExistingReport
              ? 'previewReports.updateSuccessReportDescription'
              : 'previewReports.successReportDescription',
            {
              name: procedureBasicInfo.patientName,
            }
          ),
        })
        setSaveSucceeded(true)
      } catch (error) {
        notify({
          type: NotificationType.ERROR,
          message: t('previewReports.errorReportTitle'),
          description: t('previewReports.errorReportDescription'),
        })
      } finally {
        dispatch(setIsReportSubmitting(false))
        refetchReport()
      }
    }
  }

  const handleOk = () => {
    getPDFReport()
  }

  const handleCancel = () => {
    navigate(`/report-details/${id}`)
  }

  return (
    <>
      <div
        className={styles.previewReportContainer}
        ref={rootRef}
        id="nova-report-Preview-Report-root"
      >
        <PreviewReportHeader
          onSaveToHIS={saveToHisOrRedirect}
          onPrintClick={() => printPdfFromBlob(pdfFile?.at(0) as Blob, rootRef.current)}
          onDownloadClick={() =>
            downloadPdfFromBlob(
              pdfFile?.at(0) as Blob,
              `${
                procedureBasicInfo?.patientName +
                '-' +
                procedureBasicInfo?.patientId +
                '-' +
                procedureBasicInfo?.procedureType
              }-${t('previewReports.documents')}`
            )
          }
          removeCTAButton={saveSucceeded}
        />
        <div className={styles.previewReportContentWrapper}>
          <PreviewReportSidebar
            pdfFile={pdfFile?.at(0) as object}
            pageTitles={(pdfFile?.at(1) as string[]) ?? []}
          />
          <div className={styles.previewReportContentContainer}>
            <div className={styles.previewReportContentContainerInner}>
              {pdfFile ? (
                <PreviewReportPdfView
                  width={width}
                  pdfFile={pdfFile.at(0) as object}
                  zoomRatio={zoomRatio}
                />
              ) : (
                <PreviewReportLoading
                  width={width * zoomRatio}
                  height={width * zoomRatio * 1.414}
                  isThumb={false}
                  isSidebar={false}
                />
              )}
            </div>
            {pdfFile && (
              <PreviewReportPageControlDock
                zoomRatio={zoomRatio}
                onZoomRatioChange={setZoomRatio}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewReport
