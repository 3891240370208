import { format, parseISO } from 'date-fns'
import de from 'date-fns/locale/de'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'

/* istanbul ignore next */
const pickLocale = () => {
  const lang = localStorage.getItem('i18nextLng')
  switch (lang) {
    case 'en':
      return en
    case 'es':
      return es
    case 'de':
      return de
    default:
      return en
  }
}
export const formatDate = (date: string) => {
  return format(parseISO(date), 'do MMM', { locale: pickLocale() })
}

export const birthdateFormatted = (date: string) => {
  return format(parseISO(date), 'dd.MM.yyyy', { locale: pickLocale() })
}

export const procedureDateTimeFormatted = (dateTime: string) => {
  const date = parseISO(dateTime)
  const locale = pickLocale()
  return `${format(date, 'dd.MM.yyyy', { locale })} ${format(date, 'HH:mm', { locale })}`
}
