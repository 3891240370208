import DataCaptureCardLoading from '../card/DataCaptureCardLoading'
import styles from './styles.module.scss'

const DataCaptureLoadingSection = () => {
  return (
    <>
      <div
        className={styles.filterSectionWrapperLoading}
        id="nova-report-Data-Capture-Loading-Section-root"
        data-testid="nova-report-Data-Capture-Loading-Section-root"
      >
        <div className={`${styles.filterSectionLoading} ${styles.width114}`} />
        <div className={`${styles.filterSectionLoading} ${styles.width38}`} />
      </div>
      {[...new Array(3)].map((v, i) => {
        return (
          <div className={styles.cardMargin} key={i}>
            <DataCaptureCardLoading />
          </div>
        )
      })}
    </>
  )
}

export default DataCaptureLoadingSection
