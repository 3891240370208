import { useAppSelector } from 'app/hooks'
import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import {
  getEditingFindingId,
  getFindingForm,
  getSections,
  setFindingForm,
  setIsFindingFormDirty,
} from '../../../../../../dataCaptureSlice'
import { joinTKeyParams, normalizeTKeyParams, tKeysMatch } from '../../../../../../utils/URLhelper'
import {
  determineSelectedWorkflowChild,
  determineSelectedWorkflowObjects,
  setErrors,
} from '../../../../../../utils/utils'
import styles from './styles.module.scss'
import { FindingSectionChildren } from './subcomponents/FindingSectionChildren'
import FindingSectionLevel5 from './subcomponents/findingSectionLevel5/FindingSectionLevel5'
import { FindingSectionRow } from './subcomponents/FindingsSectionRow'
import { setAnimationTerms } from './util/animationsUtil'
import { setFormDataObject } from './util/setFormData'
interface IProps {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  isDisabled: boolean
  isLevel4: boolean
  parentTkey: string
  workflowTitle: string
  parentId: string
}

const FindingSection = ({ finding, isDisabled, isLevel4, parentTkey, workflowTitle }: IProps) => {
  const dispatch = useDispatch()
  const params = useParams()
  const findingForm = useAppSelector(getFindingForm)
  const editingFindingId = useAppSelector(getEditingFindingId)
  const sections = useAppSelector(getSections)
  const [findingSections, setFindingSections] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined
  >(undefined)
  const [selectedWorkflowChild, setSelectedWorkflowChild] =
    useState<ZipModuleTerminologyCommonTerminologyNodeDto>()

  // each time we render the component we select the currently opened section
  // from url parameters
  const { workflow } = determineSelectedWorkflowObjects(
    sections,
    params as { workflowTKey: string; childTKey: string }
  )

  useEffect(() => {
    //medication helper start
    const selectedWorkflowChild = determineSelectedWorkflowChild(
      findingForm.form,
      parentTkey,
      finding
    )
    if (selectedWorkflowChild) {
      setSelectedWorkflowChild(selectedWorkflowChild)
    }
    // on first render, finding sections by default is undefined and we need to apply animation for current state of the form
    if (!findingSections) {
      setAnimation(findingForm.form, findingForm.previousForm)
    }
    //medication helper end
  }, [findingForm.form, finding])

  // on load checking if there is finding form in local storage and loading it
  useEffect(() => {
    const LSForm = localStorage.getItem(window.location.pathname)
    if (LSForm && !editingFindingId && !isEqual(JSON.parse(LSForm).form, findingForm.form)) {
      dispatch(setFindingForm({ form: JSON.parse(LSForm).form, errors: JSON.parse(LSForm).errors }))
    }
  }, [])

  const setAnimation = (currentForm: IFindingForm, previousForm: IFindingForm) => {
    // generate finding sections for level 4 & 5, and set animation terms if animation should be present or not when changing between level 3 options
    const { deepSections } = setAnimationTerms(
      currentForm,
      previousForm,
      workflow?.children?.find((lvl1) => tKeysMatch(lvl1.tKey, params.childTKey)) ?? {},
      finding
    )
    // setting deep levels finding section for currently selected level 3. If we pass level 4 also, then we should theoretically render level 6/7
    finding.lvl === 2 && setFindingSections(deepSections)
  }
  const setFormData = (
    id: string,
    keyName: string,
    sequenceFromRoot: string,
    inputType: string,
    error?: string[]
  ) => {
    const oldForm = findingForm.form
    const data = setFormDataObject(findingForm.form, keyName, sequenceFromRoot, inputType)
    const errors = setErrors(id, findingForm.errors, error) ?? []
    setAnimation(data, oldForm)
    dispatch(
      setFindingForm({
        form: data,
        errors: errors,
      })
    )

    dispatch(setIsFindingFormDirty(true))
  }

  return (
    <div
      className={`${styles.findingSection} ${isLevel4 ? styles.mb16 : ''}`}
      data-cy="add-pathology-finding-section"
      id="nova-report-Finding-Section-root"
      data-testid="nova-report-Finding-Section-root"
    >
      {
        /* level 1 or workflow title label */
        <div className={isLevel4 ? styles.level4Label : styles.level1Label}>
          {finding?.alwaysSelected || isLevel4 ? finding.value : workflowTitle}
        </div>
      }
      {/* alwaysSelected workflow */}
      {finding?.alwaysSelected ? (
        /* render workflow children */
        <FindingSectionChildren
          finding={finding}
          parentTKey={parentTkey}
          setFormData={setFormData}
          isDisabled={isDisabled}
          isLevel4={isLevel4}
        />
      ) : (
        /* otherwise render findings row directly */
        <FindingSectionRow
          finding={finding}
          /* if we do render workflow children currently opened finding
           * is a part of the section parent key*/
          parentTKey={
            workflow?.renderWorkflowChildren
              ? (finding.tKey && joinTKeyParams([parentTkey, finding.tKey])) ?? ''
              : normalizeTKeyParams(parentTkey)
          }
          setFormData={setFormData}
          isDisabled={isDisabled}
          isLevel4={isLevel4}
          renderFindingLabel={false}
        />
      )}
      {/* medication workflow */}
      {!finding?.alwaysSelected &&
        normalizeTKeyParams(finding?.tKey) === 'medication' &&
        finding?.children &&
        selectedWorkflowChild &&
        selectedWorkflowChild.children &&
        selectedWorkflowChild.children[0] && (
          <FindingSectionRow
            finding={{
              ...selectedWorkflowChild.children[0],
              inputType: 'Number',
            }}
            /* if we do not render workflow children currently opened finding
             * is a part of the section parent key*/
            parentTKey={
              (selectedWorkflowChild?.tKey &&
                joinTKeyParams([parentTkey, selectedWorkflowChild?.tKey])) ??
              ''
            }
            setFormData={setFormData}
            isDisabled={isDisabled}
            isLevel4={isLevel4}
            renderFindingLabel={!!(finding.children?.length && finding.children?.length > 1)}
          />
        )}
      {/* other not alwaysSelected workflow */}
      {normalizeTKeyParams(finding?.tKey) !== 'medication' &&
        !finding?.alwaysSelected &&
        selectedWorkflowChild && (
          <FindingSectionChildren
            finding={selectedWorkflowChild}
            parentTKey={parentTkey}
            setFormData={setFormData}
            isDisabled={isDisabled}
            isLevel4={isLevel4}
          />
        )}
      {/* render level 4 and level 5 children below findings level 3 */}
      <FindingSectionLevel5
        option={findingSections}
        finding={finding}
        parentTkey={joinTKeyParams([parentTkey, finding.tKey ?? '', findingSections?.tKey ?? ''])}
        isDisabled={isDisabled}
        workflow={workflow}
      />
    </div>
  )
}

export default FindingSection
