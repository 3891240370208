import { CustomDescendant, slateUtils } from '@novax/zip-frontend-library'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { isEqual } from 'lodash'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosImageDto,
  ZipModuleCommonDtosObservationDto,
} from 'services/zipmodule.gen'
import { CombinedStatus, InternalStatus } from 'types'

import {
  IAddComponent,
  IBasicInformation,
  IObservation,
  IReportDetails,
  IReportsApi,
  ITermTreeNode,
} from './interfaces/IReportDetails'
import { createNavigationItems } from './utils/manageNavigationItems'
import { addComponent, createObservationArray, updatePathology } from './utils/manageObservations'

export const initialState: IReportDetails = {
  basicInformation: null,
  terminologyTree: [],
  observations: [],
  activeObservationId: 'basicInformation',
  reportDetailsSubmitted: false,
  navigationItems: [],
  lastSavedObservation: null,
  pathologyDataExist: false,
  observationToHighlight: null,
  reportDetailsDisabled: false,
  selectedFinding: null,
  firstSelectedFinding: null,
  isDraggingActive: false,
  reportsApiGet: {
    isLoading: false,
    firstLoadDone: false,
    triggerRefetch: false,
  },
  initialSubmittedReport: null,
  isCurrentReportEqualInitial: true,
  isImageSelectionModalOpen: false,
  shouldPushChangesToApi: false,
  shouldPromptDoctorToContinue: false,
}
export const reportDetailsSlice = createSlice({
  name: 'reportDetailsSlice',
  initialState,
  reducers: {
    setReportDetails: (
      state,
      action: PayloadAction<{
        data: {
          basicInformation: IBasicInformation
          terminology: ITermTreeNode[]
          observations: ZipModuleCommonDtosObservationDto[]
        }
        shouldPushUpdateToApi?: boolean
      }>
    ) => {
      // when status changes to InProgress and doctor had not started yet
      // open up a procedure prompt to user to continue working
      if (
        action.payload.data.basicInformation.combinedStatus === CombinedStatus.InProgress &&
        action.payload.data.basicInformation.procedureInternalStatus !==
          InternalStatus.DoctorStart &&
        action.payload.data.basicInformation.procedureInternalStatus !== InternalStatus.DoctorEnd
      ) {
        state.shouldPromptDoctorToContinue = true
      }

      state.basicInformation = action.payload.data.basicInformation
      state.reportDetailsDisabled = false
      state.terminologyTree = action.payload.data.terminology

      state.navigationItems = []
      state.observations = []

      const observationArray: IObservation[] = createObservationArray(
        state.terminologyTree,
        action.payload.data.observations
      )
      state.observations = observationArray

      state.pathologyDataExist = updatePathology(state.terminologyTree, state.observations)
      state.navigationItems = createNavigationItems(state?.terminologyTree)
      state.initialSubmittedReport = {
        basicInformation: action.payload.data.basicInformation,
        observations: observationArray,
      }
      state.isCurrentReportEqualInitial = true

      state.shouldPushChangesToApi = !!action.payload.shouldPushUpdateToApi
    },
    setSavedTimestampsAndFlags: (
      state,
      action: PayloadAction<ZipModuleCommonDtosObservationDto[]>
    ) => {
      const observations: IObservation[] = JSON.parse(JSON.stringify(state.observations))
      action.payload.forEach((newObs) => {
        const obsIndex = observations.findIndex((obs) => obs.id === newObs.id)
        if (obsIndex !== -1) {
          const obs = observations[obsIndex]
          // Update observation properties
          obs.isSaved = !!newObs.isSaved
          obs.updatedAt = newObs.updatedAt
          obs.updatedBy = newObs.updatedBy
          obs.isUpdated = !!newObs.isUpdated
          // Update components
          newObs.components?.forEach((newComponent) => {
            const componentIndex = obs.components.findIndex((component) =>
              isEqual(component.dropdownIds, newComponent.dropdownIds)
            )
            if (componentIndex !== -1) {
              const component = obs.components[componentIndex]
              // Update component properties
              component.updatedAt = newComponent.updatedAt
              component.updatedBy = newComponent.updatedBy
              component.isUpdated = newComponent.isUpdated
            }
          })
        }
      })
      state.observations = observations
    },
    setInitialReportDetails: () => {
      return initialState
    },
    setObservationText: (
      state,
      action: PayloadAction<{ id: string; value: CustomDescendant[] }>
    ) => {
      const observationToChange = state.observations.find(
        (observation: IObservation) => observation.id === action.payload.id
      )
      if (observationToChange) {
        const desc = action.payload.value as [CustomDescendant, ...CustomDescendant[]]
        observationToChange.descriptionSlateJs = desc
        observationToChange.description = slateUtils.toPlainText(desc)
        state.lastSavedObservation = { ...observationToChange }
        // flag that current observations are different than initial
        if (state.isCurrentReportEqualInitial) state.isCurrentReportEqualInitial = false
      }
    },
    setActiveObservationId: (state, action: PayloadAction<string>) => {
      const lastObservation = state.observations.find(
        (observation: IObservation) => observation.id === state.activeObservationId
      )
      const observation = state.observations.find(
        (observation: IObservation) => observation.id === action.payload
      )
      if (
        lastObservation &&
        lastObservation.id !== observation?.id &&
        !lastObservation.isSaved &&
        lastObservation.descriptionSlateJs &&
        !slateUtils.isBlank(lastObservation.descriptionSlateJs)
      ) {
        lastObservation.isSaved = true
        state.lastSavedObservation = lastObservation
      } else if (
        lastObservation?.tKey.toLowerCase() === 'pathology' &&
        lastObservation.id !== observation?.id &&
        !lastObservation.isSaved
      ) {
        lastObservation.isSaved = true
        state.lastSavedObservation = lastObservation
      }

      state.activeObservationId = observation?.id ?? action.payload
    },
    setObservationComponent(state, action: PayloadAction<IAddComponent>) {
      const observationToChange = state.observations.find(
        (observation: IObservation) => observation.id === action.payload.observationId
      )

      // always search for oldIdValuePairs[0] because if level1 is edited we want to find old and update it
      // if level1 is not edited, oldIdValuePairs[0] will be the same as new idValuePairs[0]
      // if this is new observation and oldIdValuePairs is empty, then take idValuePairs[0]
      const level1Id = action.payload.oldIdValuePairs.at(0) ?? action.payload.idValuePairs[0]

      const component = observationToChange?.components.find(
        (component: ZipModuleCommonDtosComponentDto) =>
          component &&
          component.dropdownIds &&
          component.dropdownIds[0] &&
          component.dropdownIds[0] === level1Id
      )

      if (observationToChange) {
        if (action.payload.idValuePairs.length > 0)
          addComponent({ observationToChange, payload: action.payload, component })

        // check observation type and update Pathology if needed
        if (observationToChange.tKey.toLowerCase() === 'findings') {
          state.pathologyDataExist = updatePathology(state.terminologyTree, state.observations)
        }
        state.lastSavedObservation = observationToChange
        // flag that current observations are different than initial
        if (state.isCurrentReportEqualInitial) state.isCurrentReportEqualInitial = false
      }
    },
    deleteObservationComponent: (
      state,
      action: PayloadAction<{
        idValuePairs: string[]
        rootNode: ITermTreeNode
      }>
    ) => {
      const observationToChange = state.observations.find(
        (observation: IObservation) => observation.id === action.payload.rootNode.id
      )

      const idValuePair = action.payload.idValuePairs[0]

      const componentIndex =
        observationToChange?.components.findIndex(
          (component: ZipModuleCommonDtosComponentDto) =>
            component &&
            component.dropdownIds &&
            component.dropdownIds[0] &&
            component.dropdownIds[0] == idValuePair
        ) ?? -1

      if (observationToChange && componentIndex !== -1) {
        observationToChange.components.splice(componentIndex, 1)

        // check observation type and delete from Pathology if needed
        if (observationToChange.tKey.toLowerCase() === 'findings') {
          const pathologyRoot = state.observations.find((n) => n.tKey.toLowerCase() === 'pathology')
          if (pathologyRoot) {
            deleteObservationComponent({
              idValuePairs: action.payload.idValuePairs,
              rootNode: pathologyRoot as unknown as ITermTreeNode,
            })
            state.pathologyDataExist = pathologyRoot.components.length > 0
          }
        }
        state.lastSavedObservation = observationToChange
        // flag that current observations are different than initial
        if (state.isCurrentReportEqualInitial) state.isCurrentReportEqualInitial = false
      }
    },
    setLastSavedObservation(state, action) {
      state.lastSavedObservation = action.payload
    },
    setReportsApiGet: (state, action) => {
      state.reportsApiGet = { ...state.reportsApiGet, ...action.payload }
      state.activeObservationId = state.lastSavedObservation
        ? state.lastSavedObservation.id
        : 'basicInformation'
    },
    setObservationToHighlight: (state, action) => {
      state.observationToHighlight = action.payload
    },
    setSelectedFinding: (state, action) => {
      state.selectedFinding = action.payload
    },
    setFirstSelectedFinding: (state, action) => {
      state.firstSelectedFinding = action.payload
    },
    setImagesToFinding: (
      state,
      action: PayloadAction<{
        images: ZipModuleCommonDtosImageDto[]
      }>
    ) => {
      const observationToChange: IObservation | undefined = state.observations.find(
        (observation: IObservation) => observation.id == state.activeObservationId
      )

      const componentIndex =
        observationToChange?.components.findIndex(
          (component: ZipModuleCommonDtosComponentDto) =>
            component &&
            component.dropdownIds &&
            state.selectedFinding?.dropdownIds &&
            component.dropdownIds[0] == state.selectedFinding?.dropdownIds[0]
        ) ?? -1

      if (observationToChange && componentIndex !== -1) {
        const componentToChange = {
          ...observationToChange.components[componentIndex],
          images: action.payload.images,
        }
        observationToChange.components.splice(componentIndex, 1, componentToChange)
        //need to update selected finding when images are updated
        state.selectedFinding = componentToChange
      }

      if (observationToChange) {
        state.lastSavedObservation = { ...observationToChange }
        // flag that current observations are different than initial
        if (state.isCurrentReportEqualInitial) state.isCurrentReportEqualInitial = false
      }
    },
    updateImageOrder: (
      state,
      action: PayloadAction<{
        componentId: string
        images: ZipModuleCommonDtosImageDto[]
      }>
    ) => {
      const observationToChange: IObservation | undefined = state.observations.find(
        (observation: IObservation) => observation.id == state.activeObservationId
      )

      const componentToChange: ZipModuleCommonDtosComponentDto | undefined =
        observationToChange?.components?.find((component: ZipModuleCommonDtosComponentDto) => {
          if (component.dropdownIds) return component.dropdownIds[0] == action.payload.componentId
        })

      const newImages = action.payload.images.map(
        (img: ZipModuleCommonDtosImageDto, index: number) => ({
          ...img,
          orderNumber: index + 1,
        })
      )

      if (componentToChange && componentToChange.images && observationToChange) {
        componentToChange.images = newImages
        state.lastSavedObservation = { ...observationToChange }
        // flag that current observations are different than initial
        if (state.isCurrentReportEqualInitial) state.isCurrentReportEqualInitial = false
      }
    },
    setIsDraggingActive: (state, action) => {
      state.isDraggingActive = action.payload
    },
    setIsImageSelectionModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isImageSelectionModalOpen = action.payload
    },
    setShouldPushChangesToApi: (state, action: PayloadAction<boolean>) => {
      state.shouldPushChangesToApi = action.payload
    },
    setShouldPromptDoctorToContinue: (state, action: PayloadAction<boolean>) => {
      state.shouldPromptDoctorToContinue = action.payload
    },
    setProcedureStatuses: (
      state,
      action: PayloadAction<{ combinedStatus?: CombinedStatus; internalStatus?: InternalStatus }>
    ) => {
      if (action.payload.combinedStatus && state.basicInformation)
        state.basicInformation.combinedStatus = action.payload.combinedStatus
      if (action.payload.internalStatus && state.basicInformation)
        state.basicInformation.procedureInternalStatus = action.payload.internalStatus
    },
  },
})

export const {
  setObservationText,
  setActiveObservationId,
  setReportDetails,
  setSavedTimestampsAndFlags,
  setObservationComponent,
  setInitialReportDetails,
  setLastSavedObservation,
  setReportsApiGet,
  setObservationToHighlight,
  setSelectedFinding,
  setFirstSelectedFinding,
  setImagesToFinding,
  deleteObservationComponent,
  updateImageOrder,
  setIsDraggingActive,
  setIsImageSelectionModalOpen,
  setShouldPushChangesToApi,
  setProcedureStatuses,
  setShouldPromptDoctorToContinue,
} = reportDetailsSlice.actions
export const getReportDetails = (state: RootState) => state.reportDetails
export const getReportDetailsDisabled = (state: RootState) =>
  state.reportDetails.reportDetailsDisabled
export const getObservations = (state: RootState) => state.reportDetails.observations
export const getNavigationItems = (state: RootState) => state.reportDetails.navigationItems
export const getBasicInformation = (state: RootState) => state.reportDetails.basicInformation
export const getActiveObservationId = (state: RootState) => state.reportDetails.activeObservationId
export const getReportDetailsSubmitted = (state: RootState) =>
  state.reportDetails.reportDetailsSubmitted
export const getTerminologyTree = (state: RootState) => state.reportDetails.terminologyTree
export const getIsLoading = (state: RootState) => state.reportDetails.reportsApiGet.isLoading
export const getLastSavedObservation = (state: RootState) =>
  state.reportDetails.lastSavedObservation
export const getPathologyDataExists = (state: RootState) => state.reportDetails.pathologyDataExist
// getReportsApiGet is memoized to prevent additional re renders.
// See here: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
const getReportsApiGetBaseSelector = (state: RootState) => state.reportDetails.reportsApiGet
export const getReportsApiGet = createSelector(
  [getReportsApiGetBaseSelector],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (baseSelectorResult): [IReportsApi, any] => [
    baseSelectorResult,
    setReportsApiGet({ triggerRefetch: true }),
  ]
)
export const getObservationToHighlight = (state: RootState) =>
  state.reportDetails.observationToHighlight
export const getSelectedFinding = (state: RootState) => state.reportDetails.selectedFinding
export const getFirstSelectedFinding = (state: RootState) =>
  state.reportDetails.firstSelectedFinding
export const getIsDraggingActive = (state: RootState) => state.reportDetails.isDraggingActive
export const getInitialSubmittedReport = (state: RootState) =>
  state.reportDetails.initialSubmittedReport
export const getIsImageSelectionModalOpen = (state: RootState) =>
  state.reportDetails.isImageSelectionModalOpen
export const getShouldPushChangesToApi = (state: RootState) =>
  state.reportDetails.shouldPushChangesToApi
export const getIsCurrentReportEqualInitial = (state: RootState) =>
  state.reportDetails.isCurrentReportEqualInitial
export const getShouldPromptDoctorToContinue = (state: RootState) =>
  state.reportDetails.shouldPromptDoctorToContinue

export default reportDetailsSlice.reducer
