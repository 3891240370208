import { CopyIcon, DeleteIcon, RightIcon } from '@novax/zip-frontend-library'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useGlobalModalContext } from 'components/GlobalModalProvider/GlobalModalProvider'
import NovaIcon from 'components/NovaIcon'
import {
  deleteFinding,
  duplicateFinding,
  getFindingDuplicateId,
  getFindingToHighlight,
  getIsProcedureFinished,
  getIsSubmitLoading,
  getReadyToDelete,
  setDiscardModalVariant,
  setFindingToHighlight,
} from 'features/dataCapture/dataCaptureSlice'
import { createURLFromParams, normalizeTKeyParams } from 'features/dataCapture/utils/URLhelper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'
import { getDeleteModalConfig } from 'utils/modalConfigs'

import colors from '../../../../../../scss/colors.scss'
import { scrollToObservation } from '../../../../../reportDetails/utils/scrollToSection'
import SentenceParagraphs from './SentenceParagraphs'
import styles from './styles.module.scss'

interface IDataCaptureCard {
  title?: string | null
  subtitle?: string
  workflowStep?: ZipModuleTerminologyCommonTerminologyNodeDto
  dropdownIds?: string[]
  component?: ZipModuleCommonDtosComponentDto
  observation?: ZipModuleCommonDtosObservationDto
  containers?: string[]
  tkey?: string | null
  isLastEdited: boolean
}

const DataCaptureCard = ({
  title,
  workflowStep,
  dropdownIds,
  containers,
  component,
  observation,
  tkey,
  isLastEdited,
}: IDataCaptureCard) => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [highlighted, setHighlighted] = useState<boolean>(false)
  const [animated, setAnimated] = useState<boolean>(false)
  const findingToHighlight = useAppSelector(getFindingToHighlight)
  const findingDuplicateId = useAppSelector(getFindingDuplicateId)
  const isSubmitLoading = useAppSelector(getIsSubmitLoading)
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)
  const readyToDelete = useAppSelector(getReadyToDelete)

  const { showModal } = useGlobalModalContext()

  useEffect(() => {
    if (
      (dropdownIds &&
        findingToHighlight &&
        dropdownIds[0] === findingToHighlight.id &&
        !isLastEdited) ||
      (isLastEdited && findingToHighlight.id && !findingDuplicateId)
    ) {
      setHighlighted(true)
      findingToHighlight.newlyCreated && setAnimated(true)
      scrollToObservation(findingToHighlight.id, 'smooth', 'center')
      const timeout = setTimeout(() => {
        setHighlighted(false)
        setAnimated(false)
        dispatch(setFindingToHighlight({ id: null, newlyCreated: true }))
      }, 1500)
      return () => clearTimeout(timeout)
    }
  }, [findingToHighlight])

  const editFinding = (dropdownIds?: string) => {
    const childTKey = workflowStep?.renderWorkflowChildren
      ? normalizeTKeyParams(
          workflowStep?.children?.find((t) => dropdownIds && t.id === dropdownIds.split(',')[0])
            ?.tKey
        )
      : undefined

    const url = createURLFromParams(
      {
        ...params,
        workflowTKey: workflowStep?.tKey ?? undefined,
        childTKey: !workflowStep?.isUnique ? childTKey : undefined,
        dropdownId: dropdownIds && !workflowStep?.isUnique ? dropdownIds : undefined,
      },
      location,
      true
    )

    navigate(url)
  }

  const handleDeleteFindingInitialCall = () => {
    showModal(getDeleteModalConfig({ t, handleOk: handleDeleteFinding }))
  }

  const handleDeleteFinding = () => {
    dispatch(deleteFinding({ source: 'overview', observation: workflowStep, dropdownIds }))
  }

  const handleDuplicateFinding = async () => {
    if (!workflowStep?.isUnique && dropdownIds) {
      dispatch(duplicateFinding({ observation: workflowStep, dropdownIds }))
      dispatch(setDiscardModalVariant('duplicate'))
    }
  }

  useEffect(() => {
    if (
      dropdownIds &&
      findingToHighlight &&
      dropdownIds[0] === findingToHighlight.id &&
      findingDuplicateId &&
      findingDuplicateId !== '' &&
      !isSubmitLoading
    ) {
      const timeoutId = setTimeout(() => {
        editFinding(findingDuplicateId)
      }, 1000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [dropdownIds, findingToHighlight, findingDuplicateId, isSubmitLoading])

  return (
    <div
      className={`${styles.cardContainer} ${highlighted ? styles.cardContainerHighlighted : ''} ${
        isProcedureFinished ? styles.cardContainerNoHover : ''
      } ${animated ? styles.cardContainerAnimated : ''} `}
      id={dropdownIds && dropdownIds[0]}
      key={dropdownIds && component && `${dropdownIds[0]}-${component.text}`}
      onClick={(e) => {
        if (!isProcedureFinished) {
          editFinding(dropdownIds && dropdownIds[0])
          dispatch(setDiscardModalVariant('edit'))
          e.stopPropagation()
        }
      }}
    >
      <div className={styles.cardInformation}>
        <div className={styles.icon} style={{ padding: 0, display: 'initial' }}>
          <NovaIcon
            tkey={tkey}
            iconSize="16px"
            color={highlighted ? colors.brandPrimary6 : undefined}
          />
        </div>
        <div className={styles.cardTitles}>
          <div className={styles.title}>
            {title}{' '}
            {dropdownIds && findingDuplicateId === dropdownIds[0] && (
              <span>({t('dataCapture.card.copy')})</span>
            )}
          </div>
          <div className={styles.subtitle}>
            <SentenceParagraphs
              workflowStep={workflowStep}
              dropdownIds={dropdownIds}
              observation={observation}
            />
          </div>
        </div>
      </div>
      <div className={styles.cardActionsWrapper}>
        <div className={styles.cardActions}>
          {!workflowStep?.isUnique && (
            <button
              id={`card-duplicate-button-${dropdownIds && dropdownIds[0]}`}
              data-testid={`card-duplicate-button-${dropdownIds && dropdownIds[0]}`}
              onClick={(e) => {
                !isProcedureFinished && handleDuplicateFinding()
                e.stopPropagation()
              }}
              className={`${styles.icon}`}
              disabled={isProcedureFinished || !!readyToDelete}
            >
              <CopyIcon size={16} />
            </button>
          )}
          <button
            id={`card-delete-button-${dropdownIds && dropdownIds[0]}`}
            data-testid={`card-delete-button-${dropdownIds && dropdownIds[0]}`}
            onClick={(e) => {
              !isProcedureFinished && handleDeleteFindingInitialCall()
              e.stopPropagation()
            }}
            className={`${styles.icon}`}
            disabled={isProcedureFinished || !!readyToDelete}
          >
            <DeleteIcon size={16} />
          </button>
          <button
            id={`card-edit-button-${dropdownIds && dropdownIds[0]}`}
            data-testid={`card-edit-button-${dropdownIds && dropdownIds[0]}`}
            onClick={() => {
              !isProcedureFinished && dispatch(setDiscardModalVariant('edit'))
              !isProcedureFinished && editFinding(dropdownIds && dropdownIds[0])
            }}
            className={`${styles.icon}`}
            disabled={isProcedureFinished || !!readyToDelete}
          >
            <RightIcon size={16} />
          </button>
        </div>
        {containers && containers.length > 0 && (
          <div className={styles.containersWrapper}>
            {containers && containers.length > 0 ? (
              containers.map((container, index) => (
                <div className={styles.containerInformation} key={index}>
                  {container}
                </div>
              ))
            ) : (
              <div className={styles.containerInformationPlaceholder} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default DataCaptureCard
