import { CombinedStatus, InternalStatus } from '../types'

// helper procedure state resolvers
export const isNurseDocumentationActive = (documentationStatus: InternalStatus | undefined) => {
  return (
    documentationStatus === InternalStatus.Created ||
    documentationStatus === InternalStatus.NurseStart
  )
}

export const canDoctorStartDocumentation = (
  procedureStatus: CombinedStatus | undefined,
  documentationStatus: InternalStatus | undefined
) => {
  return (
    procedureStatus !== CombinedStatus.NotStarted &&
    (documentationStatus === InternalStatus.Created ||
      documentationStatus === InternalStatus.NurseStart ||
      documentationStatus === InternalStatus.NurseEnd)
  )
}

export const isProcedureFinishedDocumentationActive = (
  procedureStatus: CombinedStatus | undefined,
  documentationStatus: InternalStatus | undefined
) => {
  return (
    (procedureStatus === CombinedStatus.Finished || procedureStatus === CombinedStatus.Submitted) &&
    (documentationStatus === InternalStatus.Created ||
      documentationStatus === InternalStatus.NurseStart)
  )
}

export const isProcedureFinishedDocumentationFinished = (
  procedureStatus: CombinedStatus | undefined,
  documentationStatus: InternalStatus | undefined
) => {
  return (
    (procedureStatus === CombinedStatus.Finished || procedureStatus === CombinedStatus.Submitted) &&
    documentationStatus !== InternalStatus.Created &&
    documentationStatus !== InternalStatus.NurseStart
  )
}

export const isProcedureActiveDocumentationFinished = (
  procedureStatus: CombinedStatus | undefined,
  documentationStatus: InternalStatus | undefined
) => {
  return (
    procedureStatus === CombinedStatus.InProgress &&
    documentationStatus !== InternalStatus.Created &&
    documentationStatus !== InternalStatus.NurseStart
  )
}
