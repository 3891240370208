import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getSelectedPage,
  initializePdfPaging,
  setPdfLoadStatus,
  setSelectedPage,
} from 'features/previewReport/previewReportSlice'
import { useEffect } from 'react'
import { Document, Page } from 'react-pdf'

import PreviewReportLoading from '../previewReportLoading/PreviewReportLoading'
import styles from './styles.module.scss'

export interface IPreviewReportPdfViewProps {
  width: number
  pdfFile: object
  zoomRatio?: number
}

const PreviewReportPdfView = ({ width, pdfFile, zoomRatio = 1 }: IPreviewReportPdfViewProps) => {
  const selectedPage = useAppSelector(getSelectedPage)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setSelectedPage(1))
  }, [])

  return (
    <Document
      className={styles.previewReportDocument}
      file={pdfFile}
      onLoadSuccess={({ numPages }: { numPages: number }) =>
        dispatch(initializePdfPaging({ pageCount: numPages }))
      }
      loading={
        <PreviewReportLoading
          width={width * zoomRatio}
          height={width * zoomRatio * 1.414}
          isThumb={false}
          isSidebar={false}
        />
      }
      onLoadError={() => {
        dispatch(setPdfLoadStatus('error'))
      }}
    >
      <Page
        pageNumber={selectedPage ?? 1}
        width={width * zoomRatio}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        renderMode="svg"
      />
    </Document>
  )
}
export default PreviewReportPdfView
