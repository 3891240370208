import { ReactComponent as NoDataIllustration } from 'assets/svg/no-findings.svg'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const NoData = () => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.container}
      id="nova-report-No-Data-root"
      data-testid="nova-report-No-Data-root"
    >
      <NoDataIllustration className={styles.svgImg} />
      <span className={styles.addDetails}>{t('dataCapture.noData.addDetails')}</span>
    </div>
  )
}

export default NoData
