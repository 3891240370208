import styles from './styles.module.scss'

interface LoadingCardProps {
  height?: string
  width?: string
  borderRadius?: string | number
}

const LoadingCard = ({ height, width, borderRadius }: LoadingCardProps) => {
  return (
    <div
      style={{
        height: height ? height : '100%',
        width: width ? width : '100%',
      }}
      data-testid="nova-report-Loading-Card"
      id="nova-report-Loading-Card-root"
    >
      <div
        data-testid="loading-card-element"
        className={styles.loadingCard}
        style={{ borderRadius: borderRadius ?? '2px' }}
      />
    </div>
  )
}

export default LoadingCard
