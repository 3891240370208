import {
  pushNodeOptionsType,
  sentenceGenerationForMultiSelect,
  SlateBadgeElement,
  SlateEditor,
} from '@novax/zip-frontend-library'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import { deleteObservationComponent } from 'features/reportDetails/reportDetailsSlice'
import { isEqual } from 'lodash'
import { ZipModuleCommonDtosComponentDto } from 'services/zipmodule.gen'
import { generateSlateBadgesFromIdValuePairs } from 'utils/sentenceGeneration'

import { getAllImages, setAllImages } from '../../images/imagesSlice'
import styles from '../styles.module.scss'
import { IValueInputType } from '../TerminologyRowLevel1'

export const HIDDEN_TERMINOLOGY_ITEMS = ['preservation_method']

export const filterOutHiddenTerminologyItems = (nodes: ITermTreeNode[]) =>
  nodes.filter((node) => !node.tKey || !HIDDEN_TERMINOLOGY_ITEMS.includes(node.tKey.toLowerCase()))

export const findLabelFromNode = (
  node: ITermTreeNode | undefined,
  index?: number,
  ownIdValuePair?: string[]
): { hasChildren: boolean; label: string | JSX.Element } => {
  let output: ITermTreeNode[] = []
  // if level 1, first dropdown just search for selected child value
  if (index == 0) {
    output = ownIdValuePair
      ? node?.children.filter((el: ITermTreeNode) => el.id == ownIdValuePair[0].split(',')[0]) ?? []
      : []
  }
  // if level 2 or 4
  else if (ownIdValuePair && ownIdValuePair.length > 0) {
    ownIdValuePair.forEach((pair) => {
      // check if dropdown has value selected level2,level3 format
      const selectedLevels = pair.split(',')
      // we handle container differently becaue it can have preservation method at the and which is not important here
      if (node?.tKey == 'container_number' && selectedLevels.length > 2) {
        const child = node?.children.find(
          (options: ITermTreeNode) => options.id === selectedLevels.at(node?.lvl)
        )
        if (child) output.push(child)
      }
      // if length % 2 == 1, value is selected and take last 2 values
      else if (selectedLevels.length % 2 === 1 && selectedLevels.at(-2) === node?.id) {
        const child = node?.children.find(
          (options: ITermTreeNode) => options.id === selectedLevels.at(-1)
        )
        if (child) output.push(child)
      }
    })
  }

  if (output.length > 0) {
    const outputValues = output.map((obj) => obj.value)

    return {
      label:
        outputValues.length > 1 ? sentenceGenerationForMultiSelect(outputValues) : outputValues[0],
      hasChildren: output.length === 1 ? output[0].children.length > 0 : false,
    }
  } else
    return {
      label: (
        <div className={styles.todoLabel} id="nova-reportterminology-Row-Utils-root">
          {node?.value}
        </div>
      ),
      hasChildren: false,
    }
}

interface updateSlateEditorOnTerminologySaveUtilArgs {
  editor: SlateEditor
  prevIdValuePairs: string[]
  currentIdValuePairs: string[]
  valueInput?: IValueInputType
  value?: string
  item?: ZipModuleCommonDtosComponentDto
  rootNode: ITermTreeNode
  pushOptions?: pushNodeOptionsType
}

/**
 * Updates Slate.js editor instance after the selected values changes. Generates badges from terminology and executes needed update actions on editor itself.
 *
 * @param editor instance of a Slate.js editor
 * @param prevIdValuePairs array of strings defining previously selected user input/values. Also known as "DropdownIds"
 * @param currentIdValuePairs array of strings defining currently selected user input/values. Also known as "DropdownIds"
 * @param valueInput object containing details custom user input (e.g. suffix, type...)
 * @param value string representation of the value that user typed in
 * @param item component object containing Observations for a given root node
 * @param rootNode Terminology node that is used as Observation. Also known as "workflow_step"
 *
 * @returns list of objects. Every object represents a paragraph. It holds paragraphId and list of badges for that paragraph.
 */
export const updateSlateEditorOnTerminologySaveUtil = ({
  editor,
  prevIdValuePairs,
  currentIdValuePairs,
  valueInput,
  value,
  item,
  rootNode,
  pushOptions,
}: updateSlateEditorOnTerminologySaveUtilArgs) => {
  // create list item if this is new observation
  if (
    !prevIdValuePairs ||
    prevIdValuePairs.length === 0 ||
    prevIdValuePairs.at(0) !== currentIdValuePairs.at(0)
  ) {
    const computedPushOptions =
      pushOptions ||
      // if first node was edited, insert new one right after it
      (prevIdValuePairs.at(0) && prevIdValuePairs.at(0) !== currentIdValuePairs.at(0)
        ? { otherNodeId: prevIdValuePairs[0], position: 'after' }
        : undefined)

    editor.pushNode(
      {
        id: currentIdValuePairs[0],
        type: 'list-item',
        children: [
          {
            id: currentIdValuePairs[0] + '-paragraph-root',
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
      },
      computedPushOptions
    )
  }

  // delete old list-item if first node was edited
  if (prevIdValuePairs.at(0) && prevIdValuePairs[0] !== currentIdValuePairs[0]) {
    editor.removeNodeWithId(prevIdValuePairs[0])
  }

  // update read-only badges
  // add badge for numeric input if observation has it
  const valueBadge: SlateBadgeElement | undefined =
    valueInput && (value || item?.text)
      ? {
          id: currentIdValuePairs[0] + '-text',
          label: 'value',
          type: 'badge',
          children: [{ text: `${value ? value : item?.text} ${valueInput.suffix}` }],
        }
      : undefined

  // if there's level4, those will be in separate paragraphs
  const paragraphs = generateSlateBadgesFromIdValuePairs({
    idValuePairs: currentIdValuePairs,
    rootNode,
    valueBadge,
    tkeysToExclude: HIDDEN_TERMINOLOGY_ITEMS,
  }).filter((p) => p.badges.length > 0)

  // delete removed paragraphs (that are in editor but not in generated paragraph array)
  editor.getChildrenOfNode(currentIdValuePairs[0]).forEach((existingParagraph) => {
    if (existingParagraph.id && !paragraphs.some((p) => p.paragraphId === existingParagraph.id))
      editor.removeNodeWithId(existingParagraph.id)
  })

  // update existing and create new paragraphs if needed
  paragraphs.forEach(({ paragraphId, badges }, i) => {
    if (!editor.nodeExists(paragraphId)) {
      editor.pushNode(
        {
          id: paragraphId,
          type: 'paragraph',
          children: [{ text: '' }],
        },
        // if adding first paragraph, place it as first child of list-item
        i === 0
          ? { otherNodeId: currentIdValuePairs[0], position: 'firstChild' }
          : { otherNodeId: paragraphs[i - 1].paragraphId, position: 'after' }
      )
    }
    editor.updateBadgesinNode(paragraphId, badges)
  })
}

interface generateDropdownIdsFromTerminologyTreeArgs {
  rootNode: ITermTreeNode
  selectedDropdownIds: string[]
}
export interface generatedDropdownIdsFromTerminologyLevel3NodesWithChildrenReturn {
  node: ITermTreeNode
  parentValue: string
  ancestorsIds: string
}
interface generateDropdownIdsFromTerminologyTreeReturn {
  dropdownIds: string[]
  level2Nodes: ITermTreeNode[]
  level3NodesWithChildren: generatedDropdownIdsFromTerminologyLevel3NodesWithChildrenReturn[]
}

/**
 * Generates DropdownIds (also known as idValuePairs) from the terminology and some selected dropdownIds.
 * This method makes it consistent and complete.
 *
 * @param rootNode Terminology node that is used as Observation. Also known as "workflow_step"
 * @param selectedDropdownIds array of strings defining currently selected user input/values. Also known as "DropdownIds"
 *
 * @returns Consistently formatted dropdownIds, terminologyNodes on depth level 2, terminology nodes on depth level 3 that have children
 */
export const generateDropdownIdsFromTerminologyTree = ({
  rootNode,
  selectedDropdownIds,
}: generateDropdownIdsFromTerminologyTreeArgs): generateDropdownIdsFromTerminologyTreeReturn => {
  const level1And2DropdownIds: string[] = []
  const level3NodesWithChildren: generatedDropdownIdsFromTerminologyLevel3NodesWithChildrenReturn[] =
    []
  if (selectedDropdownIds.length === 0) {
    return {
      dropdownIds: level1And2DropdownIds,
      level2Nodes: [],
      level3NodesWithChildren,
    }
  }
  level1And2DropdownIds.push(selectedDropdownIds[0])
  const level4DropdownIds: string[] = []
  const level1 = rootNode.children.find((n) => selectedDropdownIds[0].startsWith(n.id))

  level1?.children.forEach((level2) => {
    let minNumberOfValues = 4
    if (level2.tKey?.toLowerCase() == 'container_number') {
      minNumberOfValues = 6
    }
    const existingIdValues = selectedDropdownIds.filter(
      (idValue) =>
        idValue.startsWith(`${level1.id},${level2.id}`) &&
        idValue.split(',').length < minNumberOfValues
    )
    existingIdValues.forEach((value) => {
      // push level2 idValue to array
      level1And2DropdownIds.push(value ?? `${level1.id},${level2.id}`)

      if (value) {
        const level3Id = value.split(',').at(2)
        const level3 = level2.children.find((idValue) => idValue.id === level3Id)
        // if selected level3 has children add all level4 idValue to array
        if (level3?.children && level3.children.length > 0) {
          level3NodesWithChildren.push({
            node: level3,
            parentValue: level2.value,
            ancestorsIds: `${level1.id},${level2.id}`,
          })

          level3.children.forEach((level4) => {
            const existingIdValueLevel4 = selectedDropdownIds.filter((idValue) =>
              idValue.startsWith(`${level1.id},${level2.id},${level3.id},${level4.id}`)
            )
            // push level4 idValue to array
            existingIdValueLevel4.forEach((value) => {
              level4DropdownIds.push(value ?? `${level1.id},${level2.id},${level3.id},${level4.id}`)
            })
          })
        }
      }
    })
  })

  return {
    dropdownIds: [...level1And2DropdownIds, ...level4DropdownIds],
    level2Nodes: level1?.children ?? [],
    level3NodesWithChildren,
  }
}

/**
 * Returns true if provided level1 doesn't have any level3 or level5 selected in dropdownIds hence is empty
 *
 * @param level1 Level1 component object that will be tested
 *
 * @returns true if level1 is empty, false otherwise
 */
export const level1HasNoLevels3Selected = (level1: ZipModuleCommonDtosComponentDto) =>
  level1.dropdownIds
    ?.slice(1)
    .every((selectedDropdownId) => selectedDropdownId.split(',').length < 3) ?? false

interface deleteTerminologyRowArgs {
  terminologyTextAreaEditor?: React.RefObject<SlateEditor>
  idValuePairs: string[]
  rootNode: ITermTreeNode
  component?: ZipModuleCommonDtosComponentDto
}
export const useDeleteTerminologyRow = () => {
  const dispatch = useAppDispatch()
  const allImages = useAppSelector(getAllImages)

  const unselectImages = (component?: ZipModuleCommonDtosComponentDto) => {
    //before deleting, set isSelected flag for each component image to false
    const updatedImages = allImages.map((image) => {
      const stateImage = component?.images?.find((img) => isEqual(img.imageUid, image.imageUid))
      if (stateImage)
        return { ...image, isInPdf: false, isSelected: false, tag: '', orderNumber: 0 }
      else return { ...image }
    })

    dispatch(setAllImages(updatedImages))
  }

  const deleteTerminologyRow = ({
    rootNode,
    idValuePairs,
    terminologyTextAreaEditor,
    component,
  }: deleteTerminologyRowArgs) => {
    component?.images?.length && unselectImages(component)

    // Update TerminologyTextArea editor
    terminologyTextAreaEditor?.current &&
      idValuePairs.at(0) &&
      terminologyTextAreaEditor.current.removeNodeWithId(idValuePairs[0])

    dispatch(
      deleteObservationComponent({
        idValuePairs,
        rootNode,
      })
    )
  }

  return { deleteTerminologyRow }
}
