import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { IDrawerInfo, IImages } from 'features/reportDetails/interfaces/IReportDetails'
import { updateImageTags } from 'features/reportDetails/utils/updateReportImages'
import { ZipModuleCommonDtosImageDto, ZipModuleCommonDtosImageUidDto } from 'services/zipmodule.gen'

const initialState: IImages = {
  allImages: [],
  allImagesWithUpdatedTags: [],
  selectedImages: [],
  drawerInfo: {
    isDrawerOpen: false,
    isButtonShown: false,
  },
  isEditingState: false,
}

export const imagesSlice = createSlice({
  name: 'imagesSlice',
  initialState,
  reducers: {
    setAllImages: (state, action) => {
      state.allImages = action.payload
      state.allImagesWithUpdatedTags = updateImageTags(action.payload)
    },
    setSelectedImages: (state, action: PayloadAction<ZipModuleCommonDtosImageDto[]>) => {
      state.selectedImages = []
      action.payload?.forEach(
        (image: ZipModuleCommonDtosImageUidDto | ZipModuleCommonDtosImageDto) => {
          let imageUid: ZipModuleCommonDtosImageUidDto | undefined
          'isSelected' in image
            ? (imageUid = image.imageUid)
            : (imageUid = <ZipModuleCommonDtosImageUidDto>image)
          state.selectedImages.push({
            imageUid: imageUid,
            isSelected: true,
            isInPdf: false,
            orderNumber: state.selectedImages.length + 1,
            tag: (image as ZipModuleCommonDtosImageDto).tag ?? '',
          })
        }
      )
    },
    setDrawerInfo: (state, action: PayloadAction<IDrawerInfo>) => {
      state.drawerInfo = { ...state.drawerInfo, ...action.payload }
    },
    setIsEditingState: (state, action) => {
      state.isEditingState = action.payload
    },
  },
})
export const { setAllImages, setSelectedImages, setDrawerInfo, setIsEditingState } =
  imagesSlice.actions

export const getAllImages = (state: RootState) => {
  return state.images.allImages
}

export const getAllImagesWithUpdatedTags = (state: RootState) => {
  return state.images.allImagesWithUpdatedTags
}

export const getSelectedImages = (state: RootState) => {
  return state.images.selectedImages
}

export const getDrawerInfo = (state: RootState) => state.images.drawerInfo

export const getIsEditingState = (state: RootState) => state.images.isEditingState

export default imagesSlice.reducer
