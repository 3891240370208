import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getObservations,
  getShowContainersOnly,
  setLastCardDeleted,
  TSection,
} from 'features/dataCapture/dataCaptureSlice'
import { checkIfSectionIncludesBiopsyContainer } from 'features/dataCapture/utils/checkIfAnyBiopsyContainersExist'
import { normalizeTKeyParams, tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import _ from 'lodash'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import styles from '../styles.module.scss'

interface IDataCaptureSectionHeader {
  section?: TSection
}
const DataCaptureSectionHeader = ({ section }: IDataCaptureSectionHeader) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const observations = useAppSelector(getObservations)
  const showContainersOnly = useAppSelector(getShowContainersOnly)
  const biopsyContainerExistsInObservation = checkIfSectionIncludesBiopsyContainer(
    observations,
    section,
    t
  )
  const componentsInObservation = _.sum(
    observations.flatMap((observation) =>
      section?.terminology?.map((t) =>
        tKeysMatch(t.tKey, observation.tKey)
          ? t.isUnique
            ? observation.components?.length && observation.components?.length >= 1
            : Number(observation.components?.length)
          : 0
      )
    )
  )

  useEffect(() => {
    if (componentsInObservation === 0) {
      dispatch(setLastCardDeleted(true))
    } else {
      dispatch(setLastCardDeleted(false))
    }
  }, [componentsInObservation])

  const numberOfBiopsyContainersInObservation = biopsyContainerExistsInObservation.filter(
    (c) => c == true
  ).length

  const shouldHideHeader = () => {
    return !(
      (biopsyContainerExistsInObservation.includes(true) && showContainersOnly) ||
      (componentsInObservation !== 0 && !showContainersOnly)
    )
  }

  return (
    <>
      {
        <div
          className={shouldHideHeader() ? styles.sectionHeadingHidden : styles.sectionHeading}
          id="nova-report-Data-Capture-Section-Header-root"
        >
          <span className={styles.sectionTitle}>
            {i18n.exists('dataCapture.sectionLabel.' + normalizeTKeyParams(section?.tKey))
              ? t('dataCapture.sectionLabel.' + normalizeTKeyParams(section?.tKey))
              : t('dataCapture.sectionLabel.fallback')}
          </span>
          <span className={styles.sectionCardNumber}>
            {!showContainersOnly
              ? `${componentsInObservation} ${t('dataCapture.header.cards', {
                  count: componentsInObservation,
                })}`
              : !shouldHideHeader()
              ? `${t('dataCapture.header.showing')} ${numberOfBiopsyContainersInObservation} ${t(
                  'dataCapture.header.of'
                )} ${componentsInObservation} ${t('dataCapture.header.cards', {
                  count: componentsInObservation,
                })}`
              : ''}
          </span>
        </div>
      }
    </>
  )
}

export default DataCaptureSectionHeader
