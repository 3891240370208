import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../app/store'

export type PdfLoadStatuses = 'loading' | 'success' | 'error' | 'not started'
type PreviewReportSliceType = {
  pdfLoadStatus: PdfLoadStatuses
  isReportSubmitting: boolean
  selectedPage?: number
  pageCount?: number
}

export const initialState: PreviewReportSliceType = {
  pdfLoadStatus: 'not started',
  isReportSubmitting: false,
  selectedPage: undefined,
  pageCount: undefined,
}

export const previewReportSlice = createSlice({
  name: 'previewReportSlice',
  initialState,
  reducers: {
    setPdfLoadStatus: (state, action: PayloadAction<PdfLoadStatuses>) => {
      state.pdfLoadStatus = action.payload
    },
    setSelectedPage: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPage = action.payload
    },
    setIsReportSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isReportSubmitting = action.payload
    },
    initializePdfPaging: (
      state,
      action: PayloadAction<{
        pageCount?: number
      }>
    ) => {
      state.pageCount = action.payload.pageCount
      state.selectedPage = 1
    },
  },
})

export const { setSelectedPage, setIsReportSubmitting, initializePdfPaging, setPdfLoadStatus } =
  previewReportSlice.actions
export const getPdfLoadStatus = (state: RootState) => state.previewReport.pdfLoadStatus
export const getSelectedPage = (state: RootState) => state.previewReport.selectedPage
export const getTotalPageCount = (state: RootState) => state.previewReport.pageCount
export const getIsReportSubmitting = (state: RootState) => state.previewReport.isReportSubmitting

export default previewReportSlice.reducer
