import { Login, NovaAutoLogout, NovaOSApp } from '@novax/os'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { getNovaOSAppConfig } from 'configs/novaOSAppConfig'
import ReactDOM from 'react-dom/client'

import App from './App'
import { initAppInsights } from './AppInsights'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorkerRegistration'

// Initialize Sentry SDK
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT ?? 'local',
  integrations: [new BrowserTracing({ tracePropagationTargets: [] })],
  release: 'zipmodule-frontend@' + process.env.REACT_APP_VERSION,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_ENVIRONMENT == 'local' ? 0 : 1.0,
})

// Initialize Application Insights SDK
initAppInsights()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

root.render(
  <NovaOSApp config={getNovaOSAppConfig()}>
    <Login>
      <NovaAutoLogout />
      <App />
    </Login>
  </NovaOSApp>
)

serviceWorker.register()
