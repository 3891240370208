import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { getConfig, getToken, updateToken } from '@novax/os'

import {
  ImageReleasedMessageType,
  ProcedureStatusChangedMessage,
  ProcedureUpdatedMessage,
} from './MessageTypes'
interface messageListenerArgs {
  methodName: string
  callback: (
    message: ImageReleasedMessageType & ProcedureUpdatedMessage & ProcedureStatusChangedMessage
  ) => void
}

class SignalRService {
  private connection: HubConnection | null = null
  static instance: SignalRService
  static messageListeners: messageListenerArgs[] = []

  startConnection(): void {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_URL}/hubs/messaging`, {
        accessTokenFactory: async () => {
          await updateToken()
          const token = getToken()
          if (!token) {
            throw new Error('authentication required')
          }
          return token
        },
        headers: {
          'Nova-Tenant-Id': getConfig()?.AUTH_REALM ?? '',
          'Nova-Tenant-Language': localStorage.getItem('i18nextLng') ?? 'en',
        },
      })
      .build()

    this.connection
      .start()
      .then(() => {
        console.log('SignalR instance connected')

        // reinitialize message listeners if previously added
        SignalRService.messageListeners.map((listener) => {
          this.connection?.off(listener.methodName, listener.callback)
        })

        SignalRService.messageListeners
          .map((listener) => listener.methodName)
          .filter((methodName, i, a) => a.indexOf(methodName) == i)
          .map((methodName) => {
            this.connection?.off(methodName)
          })

        SignalRService.messageListeners.map((listener) => {
          this.connection?.on(listener.methodName, listener.callback)
        })
      })
      .catch((error) => {
        console.log('Error connecting to SignalR hub:', error)
      })
  }

  addMessageListener(
    methodName: string,
    callback: (
      message: ImageReleasedMessageType & ProcedureUpdatedMessage & ProcedureStatusChangedMessage
    ) => void
  ): void {
    SignalRService.messageListeners.push({ methodName, callback })
    this.connection?.on(methodName, callback)
  }

  removeMessageListener(
    methodName: string,
    callback?: (
      message: ImageReleasedMessageType & ProcedureUpdatedMessage & ProcedureStatusChangedMessage
    ) => void
  ) {
    SignalRService.messageListeners = SignalRService.messageListeners.filter(
      (listener) => listener.methodName !== methodName
    )
    if (!callback) {
      this.connection?.off(methodName)
    } else {
      this.connection?.off(methodName, callback)
    }
  }

  stopConnection(): void {
    this.connection?.stop().catch((error) => {
      console.log('Error stopping SignalR connection:', error)
    })
  }

  public static getInstance(): SignalRService {
    if (!SignalRService.instance) SignalRService.instance = new SignalRService()
    return SignalRService.instance
  }

  getConnection(): HubConnection | null {
    return this.connection
  }
}

export default SignalRService.getInstance()
