import {
  CheckIcon,
  DeleteIcon,
  Header,
  HeaderColorVariants,
  HeaderVariants,
  LeftIcon,
  LoadingIcon,
  NotificationType,
  notify,
  UpdateIcon,
} from '@novax/zip-frontend-library'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import CustomNovaNavigation from 'components/CustomNovaNavigation/CustomNovaNavigation'
import NovaIcon from 'components/NovaIcon'
import { tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import { scrollToObservation } from 'features/reportDetails/utils/scrollToSection'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePutApiDataCaptureByIdMutation,
  ZipModuleFeaturesDataCaptureResponsesSectionDto,
  ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'
import {
  getDeleteModalConfig,
  getDiscardModalConfig,
  getErrorSubmittingModalConfig,
} from 'utils/modalConfigs'

import { useGlobalModalContext } from '../../../../../../components/GlobalModalProvider/GlobalModalProvider'
import LoadingCard from '../../../../../../components/loading/LoadingCard'
import {
  deleteFinding,
  getBasicInformation,
  getDiscardModalVariant,
  getEditingFindingId,
  getFindingDuplicateId,
  getFindingForm,
  getInitialFindingForm,
  getIsFindingFormDirty,
  getIsProcedureFinished,
  getIsSubmitLoading,
  getObservations,
  getReadyToDelete,
  getSections,
  getTempObservations,
  setDataCapture,
  setDeletingFindingId,
  setEditingFindingId,
  setFindingDuplicateId,
  setFindingForm,
  setFindingToHighlight,
  setInitialFindingForm,
  setIsFindingFormDirty,
  setIsSubmitLoading,
  setObservations,
  setShouldFetchData,
} from '../../../../dataCaptureSlice'
import { dataCaptureMapper } from '../../../../utils/dataCaptureMapper'
import {
  determineSelectedWorkflowObjects,
  findMaxIndexDropdownId,
  generateEmptyFindingFindingFormData,
} from '../../../../utils/utils'
import styles from './styles.module.scss'

const DataCaptureCreateEditHeader = ({
  isLoading,
  showInDevelopmentTag,
}: {
  isLoading?: boolean
  showInDevelopmentTag?: boolean
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const sections = useAppSelector(getSections)
  const observations = useAppSelector(getObservations)
  const findingForm = useAppSelector(getFindingForm)
  const basicInformation = useAppSelector(getBasicInformation)
  const editingFindingId = useAppSelector(getEditingFindingId)
  const isSubmitting = useAppSelector(getIsSubmitLoading)

  const [workflowTitle, setWorkflowTitle] = useState<string | undefined | null>()
  const [terminologySection, setTerminologySection] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined | null
  >()
  const [workflow, setWorkflow] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined | null
  >()
  const [workflowChild, setWorkflowChild] = useState<
    ZipModuleTerminologyCommonTerminologyNodeDto | undefined | null
  >()
  const [submitDataCapture] = usePutApiDataCaptureByIdMutation()
  const isFindingFormDirty = useAppSelector(getIsFindingFormDirty)
  const findingDuplicateId = useAppSelector(getFindingDuplicateId)
  const initialFindingForm = useAppSelector(getInitialFindingForm)
  const discardModalVariant = useAppSelector(getDiscardModalVariant)
  const readyToDelete = useAppSelector(getReadyToDelete)
  const tempObservations = useAppSelector(getTempObservations)

  const { showModal, hideAsyncModal } = useGlobalModalContext()
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)

  // each time we navigate to the component change the edit header title
  // if we are rendering the whole  workflow children array, set the title to
  // workflow label, if single child is displayed set to child's label
  useEffect(() => {
    const { terminologySection, workflow, workflowChild } = determineSelectedWorkflowObjects(
      sections,
      params as { workflowTKey: string; childTKey: string }
    )

    setTerminologySection(terminologySection)
    setWorkflow(workflow)
    setWorkflowChild(workflowChild)

    if (!workflow?.renderWorkflowChildren || !workflowChild) {
      setWorkflowTitle(workflow?.value)
    } else if (workflowChild) {
      setWorkflowTitle(workflowChild?.value)
    }
  }, [sections, params])

  const handleBackButtonClick = () => {
    if (isSubmitting) {
      return
    }
    if (isFindingFormDirty || findingDuplicateId) {
      showModal(
        getDiscardModalConfig({
          t,
          handleOk: findingDuplicateId ? handleDeleteConfirmed : handleBackwardNavigation,
          modalVariant: discardModalVariant,
          isAsync: !!findingDuplicateId,
        })
      )
    } else {
      handleBackwardNavigation()
    }
  }

  const handleDeleteButtonClick = () => {
    !isProcedureFinished && showModal(getDeleteModalConfig({ t, handleOk: handleDeleteConfirmed }))
  }

  const handleBackwardNavigation = (fetch = false) => {
    dispatch(setFindingForm({ form: {}, errors: [] }))
    dispatch(setEditingFindingId(''))
    dispatch(setIsFindingFormDirty(false))
    dispatch(setFindingDuplicateId(null))

    if (fetch) {
      dispatch(setShouldFetchData(true))
    } else {
      // when create edit is opened and no changes are made do not refetch data
      dispatch(setShouldFetchData(false))
    }

    const url = `/data-capture/${params.id}/${params.sectionTKey}`

    navigate(url, { replace: true })
  }

  const handleDeleteConfirmed = () => {
    const currentObservation = observations?.find((o) => tKeysMatch(o.tKey, workflow?.tKey)) ?? null
    const deleteFindingId = findingDuplicateId ?? editingFindingId
    const dropdownIds =
      currentObservation?.components?.find(
        (el) => el.dropdownIds && el.dropdownIds[0] == deleteFindingId
      )?.dropdownIds ?? []
    dispatch(deleteFinding({ observation: workflow, dropdownIds, source: 'create-edit' }))
  }

  useEffect(() => {
    if (readyToDelete === 'create-edit' && params.id) {
      submitDataCapture({
        zipModuleFeaturesDataCaptureSaveDataCaptureCommand: {
          dataCapture: { observations: tempObservations, basicInformation: basicInformation },
          serviceRequestId: params.id,
        } as ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
        id: params.id,
      })
        .then(() => {
          hideAsyncModal()
          dispatch(setDeletingFindingId(findingDuplicateId ?? editingFindingId))
          dispatch(setIsFindingFormDirty(false))
          dispatch(setFindingForm({ form: {}, errors: [] }))
          dispatch(setEditingFindingId(''))
          dispatch(setFindingDuplicateId(null))

          const url = `/data-capture/${params.id}/${params.sectionTKey}`
          navigate(url, { replace: true })
          dispatch(setFindingToHighlight({ id: null, newlyCreated: true }))
          setTimeout(() => {
            scrollToObservation(editingFindingId, 'smooth', 'center')
            setTimeout(() => {
              dispatch(
                setDataCapture({
                  sections: sections,
                  observations: tempObservations,
                  basicInformation: basicInformation,
                })
              )
            }, 300)
          }, 300)
        })
        .catch(() => {
          navigator.onLine && showModal(getErrorSubmittingModalConfig({ t }))
        })
    }
  }, [readyToDelete])

  const handleSubmit = async () => {
    let findingFormData = findingForm.form

    if (findingForm.errors.length) {
      const findingErrorId = findingForm.errors[0].split(',')[0]
      scrollToObservation(findingErrorId, 'smooth', 'center')
      setIsSubmitLoading(false)
      notify({
        type: NotificationType.ERROR,
        message: t('common.invalidValue'),
        description: t('common.correctNumericEntry'),
      })
      return
    }

    if (!isEmpty(findingForm.form) && isEqual(initialFindingForm.form, findingForm.form)) {
      handleBackwardNavigation()
      return
    }

    if (isEmpty(findingFormData) && terminologySection && (workflowChild || workflow)) {
      if (workflowChild) {
        findingFormData = generateEmptyFindingFindingFormData(terminologySection, workflowChild)
      } else if (workflow) {
        findingFormData = generateEmptyFindingFindingFormData(terminologySection, workflow)
      }
    }
    const updatedData = dataCaptureMapper(
      findingFormData,
      observations,
      sections as ZipModuleFeaturesDataCaptureResponsesSectionDto[],
      basicInformation ?? {},
      editingFindingId
    )

    const formData: ZipModuleFeaturesDataCaptureSaveDataCaptureCommand = {
      serviceRequestId: params.id ?? '',
      dataCapture: { observations: updatedData },
    }
    dispatch(setIsSubmitLoading(true))

    submitDataCapture({
      zipModuleFeaturesDataCaptureSaveDataCaptureCommand:
        formData as ZipModuleFeaturesDataCaptureSaveDataCaptureCommand,
      id: params.id ?? '',
    })
      .then((response) => {
        if ('data' in response && response.data.observations) {
          dispatch(setObservations(response.data.observations))
        } else {
          dispatch(setObservations([...(updatedData ?? [])]))
        }

        dispatch(setIsFindingFormDirty(false))
        dispatch(setFindingForm({ form: {}, errors: [] }))
        dispatch(setInitialFindingForm({ form: {}, errors: [] }))
        dispatch(setEditingFindingId(''))
        dispatch(setFindingDuplicateId(null))

        if (!editingFindingId || editingFindingId === '') {
          const observation =
            workflow && updatedData && updatedData.find((o) => o.tKey === workflow.tKey)
          const findingId =
            observation && observation.components && findMaxIndexDropdownId(observation.components)

          if (findingId) {
            dispatch(
              setFindingToHighlight({
                id: `${findingId.id},${findingId.index}`,
                newlyCreated: true,
              })
            )
          }
        } else {
          dispatch(setFindingToHighlight({ id: editingFindingId, newlyCreated: false }))
        }

        dispatch(setIsSubmitLoading(false))
        handleBackwardNavigation()
      })
      .catch(() => {
        // Error handling
        navigator.onLine && showModal(getErrorSubmittingModalConfig({ t }))
        dispatch(setIsSubmitLoading(false))
      })
  }
  return (
    <>
      <CustomNovaNavigation showInDevelopmentTag={showInDevelopmentTag} />
      <div className={styles.headerContent}>
        <Header
          color={HeaderColorVariants.LIGHT}
          variant={HeaderVariants.HIGH}
          isFixedWidth={false}
          isStaticWidth
        >
          <div
            className={styles.headerWrapper}
            id="nova-report-Data-Capture-Create-Edit-Header-root"
          >
            <div className={styles.headerTitleWrapper}>
              <div className={styles.flexCenter} data-testid="back-button">
                {!isLoading ? (
                  <LeftIcon
                    size={24}
                    className={styles.backButton}
                    onClick={handleBackButtonClick}
                  />
                ) : (
                  <div className={styles.backButton}>
                    <LoadingCard width={'24px'} height={'24px'} />
                  </div>
                )}
              </div>
              <div className={styles.headerTitle}>
                <div className={styles.flexCenter}>
                  {!isLoading ? (
                    <NovaIcon tkey={workflowChild?.tKey || workflow?.tKey} iconSize="24px" />
                  ) : (
                    <LoadingCard width={'18px'} height={'18px'} />
                  )}
                </div>
                {!isLoading ? (
                  <div className={styles.title}>{workflowTitle}</div>
                ) : (
                  <LoadingCard width={'80px'} height={'18px'} />
                )}
              </div>
            </div>
            <div className={styles.headerTitleWrapper}>
              {!isLoading ? (
                <button
                  className={styles.iconButton}
                  disabled={!editingFindingId || isProcedureFinished || isSubmitting}
                  onClick={handleDeleteButtonClick}
                  id={'delete-button'}
                  data-testid="delete-button"
                >
                  <DeleteIcon className={styles.backButton} />
                </button>
              ) : (
                <div className={styles.iconButton}>
                  <LoadingCard width={'24px'} height={'24px'} />
                </div>
              )}
              {!isLoading ? (
                <button
                  className={styles.iconButtonSubmit}
                  disabled={
                    (!!editingFindingId && !isFindingFormDirty && !findingDuplicateId) ||
                    isProcedureFinished
                  }
                  id="submitButton"
                  data-testid="submit-button"
                  onClick={handleSubmit}
                >
                  {!isSubmitting ? (
                    !findingDuplicateId && editingFindingId && editingFindingId !== '' ? (
                      <UpdateIcon size={24} />
                    ) : (
                      <CheckIcon />
                    )
                  ) : (
                    <LoadingIcon />
                  )}
                </button>
              ) : (
                <div className={styles.iconButtonSubmit}>
                  <LoadingCard width={'24px'} height={'24px'} />
                </div>
              )}
            </div>
          </div>
        </Header>
      </div>
    </>
  )
}

export default DataCaptureCreateEditHeader
