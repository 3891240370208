import { NumberInput } from '@novax/zip-frontend-library'
import { useAppSelector } from 'app/hooks'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import { getEditingFindingId, getFindingForm } from '../../../../../../../../dataCaptureSlice'
import {
  joinTKeyParams,
  splitTKeyParams,
  tKeysMatch,
} from '../../../../../../../../utils/URLhelper'

interface INumberInput {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  parentTkey: string
  isDisabled: boolean
  setFormData: (
    id: string,
    keyName: string,
    sequenceFromRoot: string,
    inputType: string,
    error?: string[]
  ) => void
}

const FindingNumber = ({ finding, setFormData, parentTkey, isDisabled }: INumberInput) => {
  const [number, setNumber] = useState<number | undefined>(undefined)
  const findingForm = useAppSelector(getFindingForm)
  const editingFindingId = useAppSelector(getEditingFindingId)
  const { t } = useTranslation()

  useEffect(() => {
    if (
      (!isEmpty(editingFindingId) ||
        (localStorage.getItem(window.location.pathname) &&
          !isEmpty(JSON.parse(localStorage.getItem(window.location.pathname) ?? '')))) &&
      !isEmpty(findingForm.form)
    ) {
      //reset form and any errors
      // set state on trigger edit mode
      const findingFormKeyValue = Object.entries(findingForm?.form) ?? []
      //We are finding the form which includes finding number input that is being loaded
      const numberForm = findingFormKeyValue?.find((el) => {
        if (el && el.length > 0 && finding?.tKey && parentTkey) {
          return el[0].includes(joinTKeyParams([parentTkey, finding?.tKey]))
        }
      })

      if (numberForm && numberForm.length > 1 && numberForm[1] != undefined) {
        const formValues = numberForm[1].split(',')
        // find index of current level of the size input(levelSize2), after that index is selected (levelSize3)
        // level1, levelSize2, levelSelectedSize3,
        const numberLevelValue = formValues.findIndex((rel: string) => rel == finding.id)

        //if levelSize3 exists that means number was selected
        if (
          numberLevelValue > -1 &&
          formValues.length > numberLevelValue + 1 &&
          formValues[numberLevelValue + 1] &&
          finding?.children &&
          finding?.children.length > 0
        ) {
          const selectedNumberValue = finding?.children.find(
            (el) => el.id == formValues[numberLevelValue + 1]
          )?.value
          if (selectedNumberValue) {
            // Number input only accepts number we need to remove sufix mm, and convert string to number
            // Example: value is : 20mm, we need to remove mm and convert to number 20
            setNumber(+Number(selectedNumberValue.replace(/[^0-9]/g, '')))
          }
        } else if (
          formValues.length === 1 &&
          !tKeysMatch(splitTKeyParams(parentTkey)[0], 'medication')
        ) {
          setNumber(formValues[0] == '' ? undefined : Number(formValues[0]))
        }
        if (tKeysMatch(splitTKeyParams(parentTkey)[0], 'medication') && numberLevelValue !== -1) {
          setNumber(
            formValues[numberLevelValue + 1] == ''
              ? undefined
              : +formValues[numberLevelValue + 1].replace(/[^0-9]/g, '')
          )
        }
      }
    } else if (isEmpty(findingForm.form)) {
      // on cancel edit form reset state
      number && setNumber(undefined)
    }
  }, [editingFindingId, findingForm.form])

  const handleNumberChange = (value: number | undefined | null) => {
    const key = finding.tKey?.toLowerCase() ?? ''
    // Remove non-number characters
    let numericValue: number | undefined =
      value !== undefined && value !== null ? Number(value?.toString().replace(/[^0-9]/g, '')) : 0

    // if user is deleting input and doesn't want any
    if (value == null) {
      numericValue = undefined
    }

    const oldNumberValue = number
    //set number state to number as Number or if empty, undefined
    setNumber(numericValue !== undefined && numericValue !== null ? +numericValue : undefined)
    //manually check for errors
    const hasError =
      finding.maxValue != null &&
      finding.minValue != null &&
      numericValue !== null &&
      numericValue !== undefined &&
      (numericValue > finding.maxValue || numericValue < finding.minValue)

    //edge case for medication
    //remove medication ID from finding.sequence from root and concat that value with Amount
    if (tKeysMatch(splitTKeyParams(parentTkey)[0], 'medication')) {
      setFormData(
        finding.id ?? '',
        joinTKeyParams([parentTkey, key]),
        [...(finding?.sequenceFromRoot?.split(',').slice(1) ?? []), numericValue].join(',') ??
          `${numericValue}`,
        'Number',
        hasError
          ? [
              `${finding.id},${
                finding.maxValue && Number(numericValue) > finding.maxValue
                  ? `${t('pathology.inputUpTo')} ${finding.maxValue} ${
                      finding.measurementUnit ?? 'mm'
                    }`
                  : `${t('pathology.inputAbove')} ${finding.minValue} ${
                      finding.measurementUnit ?? 'mm'
                    }`
              }`,
            ]
          : []
      )
    } else {
      //find ST for selected finding
      const childNumber = finding.children?.find((child) => child.tKey === `${key}-${numericValue}`)

      if (childNumber) {
        setForm(childNumber, numericValue, hasError)
      } else if (oldNumberValue !== undefined) {
        //selected finding does not exist in terminology, and it's not Medication, meaning value is out of bounds

        //find ST finding from previous value
        const oldChildNumber = finding.children?.find(
          (child) => child.tKey === `${key}-${oldNumberValue}`
        )
        //if prevous value exists, pass it to setFormData to deselect it and update state with new value
        if ((value == 0 || value == null || value == undefined) && oldNumberValue !== undefined) {
          //remove previous error by unselecting previous value
          setForm(oldChildNumber, oldNumberValue, hasError)
          //update form with new value (0)
          numericValue && setForm(undefined, numericValue, hasError)
        } else {
          setForm(undefined, numericValue, hasError)
        }
      }
      //left here intentionally for Sentry to catch exact missing finding tkeys
      /* istanbul ignore next */
      if (
        !childNumber &&
        !tKeysMatch(splitTKeyParams(parentTkey)[0], 'medication') &&
        numericValue != undefined
      ) {
        setForm(undefined, numericValue, hasError)
        console.error(
          `Children for ${finding.tKey} are not setup correctly. Check if they have ` +
            `${finding.tKey}-numberValue tkey setup correctly for number ${numericValue}!`
        )
      }
    }
  }

  const setForm = (
    childNumber: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
    numericValue: number | undefined,
    hasError: number | boolean | undefined
  ) => {
    setFormData(
      finding.id ?? '',
      joinTKeyParams([parentTkey, childNumber?.tKey ?? '']),
      childNumber?.sequenceFromRoot?.split(',').slice(1).join(',') ?? `${numericValue}`,
      'Number',
      hasError
        ? [
            `${finding.id},${
              finding.maxValue && Number(numericValue) > finding.maxValue
                ? `${t('pathology.inputUpTo')} ${finding.maxValue} ${
                    finding.measurementUnit ?? 'mm'
                  }`
                : `${t('pathology.inputAbove')} ${finding.minValue} ${
                    finding.measurementUnit ?? 'mm'
                  }`
            }`,
          ]
        : []
    )
  }

  const decreaseNumber = () => {
    if (!isDisabled) {
      //can not set negative numbers
      const newNumberValue = Number(number) > 0 ? Number(number) - 1 : null
      handleNumberChange(newNumberValue)
    }
  }
  const increaseNumber = () => {
    if (!isDisabled) {
      //if number is undefined, set to 1, otherwise add 1
      const newNumberValue = number ? number + +1 : 1
      if (newNumberValue) {
        handleNumberChange(newNumberValue)
      }
    }
  }
  const showError = (id: string | undefined) => {
    const fieldError = findingForm.errors.find((err) => err.split(',')[0] === id)
    return fieldError?.split(',')[1] ?? undefined
  }

  return (
    <NumberInput
      id={finding.id ?? undefined}
      value={number ?? undefined}
      spanAddonBefore="-"
      spanAddonAfter="+"
      onAddonBeforeClick={decreaseNumber}
      onAddonAfterClick={increaseNumber}
      onChange={handleNumberChange}
      disabled={isDisabled}
      placeholder={t('pathology.inputSize') + ` (${finding.measurementUnit ?? 'mm'})`}
      controls={false}
      error={showError(finding.id ?? undefined)}
    />
  )
}

export default FindingNumber
