import { SlateText } from '@novax/zip-frontend-library'
import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import { ZipModuleDomainTerminologyScoringRange } from 'services/zipmodule.gen'

export interface ICalculatedScoresForScoreDefinition {
  [scoreId: string]: {
    score: number
    valueNodes: ITermTreeNode[]
    showInsteadSelectedValues: boolean
    isComposite: boolean
    calculatedFrom?: string[]
    definedSentences: ZipModuleDomainTerminologyScoringRange[]
    defaultSentence?: string
    textNodes?: SlateText[]
  }
}

/**
 * Calculates all scorings defined in provided rootNode using idValuePairs as selected values.
 *
 * @param rootNodeForScoring node from terminology with "score" attribute containing score definitions
 * @param idValuePairs array of strings defining selected user input/values. Also known as "DropdownIds"
 *
 * @returns object that has scoreId as keys and calculated results as values. Some score definition attributes are also stored in the values
 */
export const calculateScores = (rootNodeForScoring: ITermTreeNode, idValuePairs: string[]) => {
  if (!rootNodeForScoring.scoring || rootNodeForScoring.scoring.length === 0) {
    console.warn('[calculateScores()] There is no scorings in provided rootNode!')
    return {}
  }

  const scores: ICalculatedScoresForScoreDefinition = {}

  // initialize all scores
  rootNodeForScoring.scoring.forEach((scoreDefinition) => {
    if (!scoreDefinition.id || !scoreDefinition.sentences) {
      // if id is not defined -> terminology error
      // if sentences is not defined -> this is not root node for that scoring -> go to next scoreDefinition
      !scoreDefinition.id &&
        console.error(
          `[calculateScores()] Scoring definition object NOT valid for node {id: ${rootNodeForScoring.id}, value: ${rootNodeForScoring.value} }. This is probably a terminology error!`
        )
      return
    }
    scores[scoreDefinition.id] = {
      score: 0,
      valueNodes: [],
      showInsteadSelectedValues: !!scoreDefinition.showInsteadSelectedValues,
      isComposite: !!scoreDefinition.isComposite,
      calculatedFrom: scoreDefinition.calculatedFrom ?? undefined,
      definedSentences: scoreDefinition.sentences,
      defaultSentence: scoreDefinition.defaultSentence ?? undefined,
    }
  })
  const labelNodes = rootNodeForScoring.children

  // go through label nodes and check if they are included in scoring
  labelNodes.forEach((labelNode) => {
    if (labelNode.scoring && labelNode.scoring.length !== 0) {
      // find selected values of that labelNode (can be multi-select)
      //remove first sequence because it is workflow step
      const labelNodeSequence =
        labelNode.sequenceFromRoot?.substring(labelNode.sequenceFromRoot.search(',') + 1) ?? ''
      const selectedValuesIds = idValuePairs.filter(
        (selection) =>
          selection.startsWith(labelNodeSequence) &&
          selection.split(',').length === labelNodeSequence.split(',').length + 1
      )

      //if there's no selected values, use scoreWhenEmpty
      if (selectedValuesIds.length === 0) {
        labelNode.scoring.forEach((labelNodeScoreDefinition) => {
          // if id not defined -> terminology error
          if (!labelNodeScoreDefinition.id) {
            console.error(
              `[calculateScores()] Scoring definition object NOT valid for node {id: ${labelNode.id}, value: ${labelNode.value} }. This is probably a terminology error!`
            )
            return
          }
          // add scoreWhenEmpty if defined
          if (scores[labelNodeScoreDefinition.id] && labelNodeScoreDefinition.scoreWhenEmpty)
            scores[labelNodeScoreDefinition.id].score += labelNodeScoreDefinition.scoreWhenEmpty
        })
      }

      //if there's selected values, use their respective score
      else {
        selectedValuesIds.forEach((selectedValueId) => {
          const selectedValueNode = labelNode.children.find(
            (node) => node.id === selectedValueId.split(',').at(-1)
          )
          if (!selectedValueNode?.scoring) return

          selectedValueNode.scoring.forEach((selectedValueNodeScoreDefinition) => {
            // if id or score not defined -> terminology error
            if (
              !selectedValueNodeScoreDefinition.id ||
              // this needs to be specific because !0 is true and we don't want that
              selectedValueNodeScoreDefinition.score === null ||
              selectedValueNodeScoreDefinition.score === undefined
            ) {
              console.error(
                `[calculateScores()] Scoring definition object NOT valid for node {id: ${selectedValueNode.id}, value: ${selectedValueNode.value} }. This is probably a terminology error!`
              )
              return
            }
            // add score and value node
            if (scores[selectedValueNodeScoreDefinition.id]) {
              const scoreObj = scores[selectedValueNodeScoreDefinition.id]
              scoreObj.score += selectedValueNodeScoreDefinition.score
              scoreObj.valueNodes.push(selectedValueNode)
            }
          })
        })
      }
    }
  })
  return scores
}

/**
 * Calculates all composite scores from the given object. Results are saved in the same object
 *
 * @param scores object containing already calculated "regular" scores and "empty" composite scores
 */
export const calculateCompositeScores = (scores: ICalculatedScoresForScoreDefinition) => {
  Object.values(scores)
    //for every score that is composite
    .filter((s) => s.isComposite && s.calculatedFrom)
    .forEach((compositeScoreResult) => {
      const numberOfScoresToCalculateFrom = compositeScoreResult.calculatedFrom?.length ?? 0
      let numberOfMissingScoresToCalculateFrom = 0
      // get every score to calculate from and add values to the composite score
      compositeScoreResult.calculatedFrom?.forEach((scoreIdToCalculateFrom) => {
        if (Object.hasOwn(scores, scoreIdToCalculateFrom)) {
          const scoreToCalculateFrom = scores[scoreIdToCalculateFrom]
          compositeScoreResult.score += scoreToCalculateFrom.score
          compositeScoreResult.valueNodes.push(...scoreToCalculateFrom.valueNodes)
        } else {
          numberOfMissingScoresToCalculateFrom += 1
        }
      })

      // if all scores to calculate from are missing, set result to NaN
      if (numberOfMissingScoresToCalculateFrom === numberOfScoresToCalculateFrom)
        compositeScoreResult.score = NaN
    })
}
