import { isEqual } from 'lodash'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosImageDto,
} from 'services/zipmodule.gen'
import { generateImageTag } from 'utils/sentenceGeneration'

import { ITermTreeNode } from '../interfaces/IReportDetails'

export const updateOrderNumbers = (
  selectedFinding: ZipModuleCommonDtosComponentDto,
  selectedImages: ZipModuleCommonDtosImageDto[],
  terminologyTree: ITermTreeNode[],
  activeObservationId: string
) => {
  let maxOrderNumber = 0
  if (selectedFinding?.images)
    maxOrderNumber = Math.max(...selectedFinding.images.map((image) => image.orderNumber ?? 0), 0)

  let tag = ''
  if (selectedFinding.dropdownIds && selectedFinding.dropdownIds.length > 0) {
    const componentIndex = selectedFinding.dropdownIds[0].split(',')[1]
    const componentId = selectedFinding.dropdownIds[0].split(',')[0]

    const level1 = terminologyTree?.find((el) => el.id == activeObservationId)
    tag = generateImageTag(componentIndex, componentId, level1)
  }

  const updatedImages: ZipModuleCommonDtosImageDto[] = []
  selectedImages.forEach((image) => {
    if (image.orderNumber) {
      updatedImages.push({
        ...image,
        orderNumber: maxOrderNumber + image.orderNumber,
        tag: tag,
        isInPdf: true,
      })
    }
  })
  return updatedImages
}

export const updateImageTags = (
  allImages: ZipModuleCommonDtosImageDto[]
): ZipModuleCommonDtosImageDto[] => {
  // group images by findings, e.g. if we have polyp and malignant tumor {P: imgsArr, MT: imgsArr, 'NoTag': unselectedImgsArr}
  const groupImagesAndDistinctTags = (): {
    groupedImages: Record<string, ZipModuleCommonDtosImageDto[]>
    distinctTags: string[]
  } => {
    const distinctTags: string[] = []
    const groupedImages: Record<string, ZipModuleCommonDtosImageDto[]> = allImages.reduce(
      (acc, image) => {
        // Extract the tag name and remove numbers if present
        const tagName = image.tag && !!image.tag.length ? image.tag?.replace(/\d/g, '') : 'NoTag'
        // Initialize the array for the tagName if it doesn't exist
        if (!acc[tagName]) {
          acc[tagName] = []
        }
        // Add the tagName to the distinct tags array (that we want to update)
        if (
          tagName !== 'NoTag' &&
          tagName !== 'EoE' &&
          tagName !== 'BBPS' &&
          tagName !== 'R' &&
          !distinctTags.includes(tagName)
        ) {
          distinctTags.push(tagName)
        }
        // Push the image to the corresponding tag group
        acc[tagName].push(image)
        return acc
      },
      {} as Record<string, ZipModuleCommonDtosImageDto[]>
    )
    return { groupedImages, distinctTags }
  }
  // Helper function to extract number from tag
  const getNumberFromTag = (tag: string): number | null => {
    const pattern: RegExp = /\d+/
    const match: RegExpExecArray | null = pattern.exec(tag)
    return match ? parseInt(match[0], 10) : null
  }
  const { groupedImages, distinctTags } = groupImagesAndDistinctTags()
  const newImages: ZipModuleCommonDtosImageDto[] = []
  distinctTags.forEach((tag) => {
    // get images for each distinct tag
    const imagesWithTag = groupedImages[tag]

    const tagsArray = imagesWithTag.flatMap((img) => img.tag)
    const sortedTagSet = Array.from(new Set(tagsArray)).sort((a, b) => {
      const numA = getNumberFromTag(a as string) || 0
      const numB = getNumberFromTag(b as string) || 0
      return numA - numB
    })
    let counter = 1
    for (const dt of sortedTagSet) {
      const minTag: string = tag + counter
      // since we iterate over sorted array it is enough to replace tag with minTag and increase the counter by 1
      imagesWithTag.forEach((image) => {
        if (image.tag === dt) {
          const updatedImage = { ...image, tag: minTag }
          newImages.push(updatedImage)
        }
      })
      counter++
    }
  })
  return allImages.map(
    (image) => newImages.find((img) => isEqual(img.imageUid, image.imageUid)) || image // If the image doesn't have a tag, return the original image
  )
}

export const updateImagesAfterDeleting = (
  allImages: ZipModuleCommonDtosImageDto[],
  deletedImage: ZipModuleCommonDtosImageDto
) => {
  const updatedImages = allImages.map((image) => {
    return isEqual(deletedImage.imageUid, image.imageUid)
      ? { ...image, isSelected: false, tag: '', isInPdf: false, orderNumber: 0 }
      : { ...image }
  })

  return updatedImages
}

export const updateImages = (
  allImages: ZipModuleCommonDtosImageDto[],
  selectedImages: ZipModuleCommonDtosImageDto[],
  imagesToDelete?: ZipModuleCommonDtosImageDto[]
) => {
  const updatedImages = allImages.map((image) => {
    const imageToDelete = imagesToDelete?.find((deletedImage) =>
      isEqual(deletedImage.imageUid, image.imageUid)
    )

    const imageToSave = selectedImages?.find((selectedImage) =>
      isEqual(selectedImage.imageUid, image.imageUid)
    )

    if (imageToDelete) {
      // If the image is marked for deletion
      return {
        ...image,
        isSelected: false,
        tag: '',
        isInPdf: false,
        orderNumber: 0,
      }
    } else if (imageToSave) {
      // If the image is marked for saving
      return {
        ...imageToSave,
      }
    } else {
      // If the image remains unchanged
      return { ...image }
    }
  })

  return updatedImages
}

export const isEqualImages = (
  selectedImages: ZipModuleCommonDtosImageDto[],
  findingImages: ZipModuleCommonDtosImageDto[]
) => {
  if (selectedImages.length !== findingImages.length) {
    return false
  }
  // Check if every selected image has a corresponding image in findingImages based on imageUid
  return selectedImages.every((selectedImage) =>
    findingImages.some((findingImage) => isEqual(findingImage.imageUid, selectedImage.imageUid))
  )
}

export const getUniqueImages = (
  images: ZipModuleCommonDtosImageDto[]
): ZipModuleCommonDtosImageDto[] => {
  const uniqueImages: ZipModuleCommonDtosImageDto[] = []

  images.forEach((image) => {
    const isDuplicate = uniqueImages.some((uniqueImg) =>
      isEqual(uniqueImg.imageUid, image.imageUid)
    )

    if (!isDuplicate) {
      uniqueImages.push(image)
    }
  })
  return uniqueImages
}

export const filterSelectedImagesByTag = (
  selectedImages: ZipModuleCommonDtosImageDto[],
  selectedFinding: ZipModuleCommonDtosComponentDto
): ZipModuleCommonDtosImageDto[] => {
  if (selectedFinding.images && selectedFinding.images.length > 0) {
    const findingTag = selectedFinding.images?.at(0)?.tag
    if (findingTag) {
      const filteredImages = selectedImages.filter((img) => !(img.tag && img.tag != findingTag))
      return filteredImages
    }
    return selectedImages.filter((img) => !(img.tag && img.tag.length > 0))
  } else {
    const filteredImages = selectedImages.filter((img) => !(img.tag && img.tag.length > 0))
    return filteredImages
  }
}

export const updateImagesAfterFirstLoad = (
  allImages: ZipModuleCommonDtosImageDto[],
  findings: ZipModuleCommonDtosComponentDto[],
  extentImages?: ZipModuleCommonDtosImageDto[] | null,
  preparationImages?: ZipModuleCommonDtosImageDto[] | null,
  withdrawalImages?: ZipModuleCommonDtosImageDto[] | null
): ZipModuleCommonDtosImageDto[] => {
  const newImages: ZipModuleCommonDtosImageDto[] = []
  findings.forEach((finding) => {
    if (
      finding.images &&
      finding.images.length > 0 &&
      finding.dropdownIds &&
      finding.dropdownIds.length > 0
    ) {
      // replace tag number with componentIndex
      const componentIndex = finding.dropdownIds[0].split(',')[1]
      const tagName =
        finding.images[0].tag && !!finding.images[0].tag.length
          ? finding.images[0].tag?.replace(/\d/g, '')
          : ''
      finding.images.forEach((img) =>
        newImages.push({
          ...img,
          tag: tagName + componentIndex,
        })
      )
      return newImages
    }
  })
  return allImages.map((img) => {
    const updatedImage = newImages.find((updatedImg) => isEqual(updatedImg.imageUid, img.imageUid))
    const extentImage = extentImages?.find((extentImg) => isEqual(extentImg.imageUid, img.imageUid))
    const preparationImage = preparationImages?.find((preparationImg) =>
      isEqual(preparationImg.imageUid, img.imageUid)
    )
    const withdrawalImage = withdrawalImages?.find((withdrawalImg) =>
      isEqual(withdrawalImg.imageUid, img.imageUid)
    )

    return (
      updatedImage ||
      extentImage ||
      preparationImage ||
      withdrawalImage || { ...img, tag: '', isInPdf: false, isSelected: false, orderNumber: 0 }
    )
  })
}
