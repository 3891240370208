import { useAppSelector } from 'app/hooks'
import {
  getCombinedSections,
  getEditingFindingId,
  getFindingForm,
  getInitialFindingForm,
  getIsProcedureFinished,
  getObservations,
  getProcedureStatus,
} from 'features/dataCapture/dataCaptureSlice'
import { tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import { shouldDisableComponent } from 'features/dataCapture/utils/utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import styles from '../styles.module.scss'
import {
  determineContainerMaxNumber,
  determineNumberOfBiopsiesMaxNumber,
  shouldLimitContainerChildrenSize,
  shouldLimitNumberOfBiopsiesChildrenSize,
} from '../util/containersChildrenSize'
import { AddContainerButton } from './addContainerButton/AddContainerButton'
import Finding from './finding/Finding'
import FindingNumber from './findingNumber/FindingNumber'
interface IProps {
  finding: ZipModuleTerminologyCommonTerminologyNodeDto
  parentTKey: string
  setFormData: (
    id: string,
    keyName: string,
    sequenceFromRoot: string,
    inputType: string,
    error?: string[]
  ) => void
  isDisabled: boolean
  isLevel4: boolean
  renderFindingLabel: boolean
}

export const FindingSectionRow = ({
  finding,
  parentTKey,
  setFormData,
  isLevel4,
  isDisabled = false,
  renderFindingLabel = false,
}: IProps) => {
  const procedureStatus = useAppSelector(getProcedureStatus)
  const findingFormInitial = useAppSelector(getInitialFindingForm)
  const findingForm = useAppSelector(getFindingForm)
  const editingItemId = useAppSelector(getEditingFindingId)
  const [containerRenderCount, setContainerRenderCount] = useState(13)
  const [numberOfBiopsiesRenderCount, setNumberOfBiopsiesRenderCount] = useState(13)
  const observations = useAppSelector(getObservations)
  const sections = useAppSelector(getCombinedSections)
  const isProcedureFinished = useAppSelector(getIsProcedureFinished)
  const params = useParams()
  const workflowTKey = params.workflowTKey

  useEffect(() => {
    if (shouldLimitContainerChildrenSize(finding?.tKey ?? '')) {
      // selectors for all containers on data capture behave the same
      // we are searching for all containers in current observation (container in GB and in poylp are the same containers in procedure room)
      // if component is for ex. "Polyp",
      //we are searching for all used containers in parent observation "findings"
      const maxSelectedChildIndex = determineContainerMaxNumber(
        finding?.id ?? '',
        observations.find((obs) => tKeysMatch(obs.tKey, workflowTKey)) ?? null,
        sections
          ?.find((s) => s.tKey == 'all')
          ?.terminology?.find((t) => tKeysMatch(t.tKey, workflowTKey)) ?? null
      )
      if (maxSelectedChildIndex && maxSelectedChildIndex + 1 > containerRenderCount) {
        setContainerRenderCount(maxSelectedChildIndex + 1)
      }
    }
  }, [findingForm])

  // on edit set correct display of number of biopsies
  useEffect(() => {
    if (editingItemId && shouldLimitNumberOfBiopsiesChildrenSize(finding.tKey ?? '')) {
      const maxSelectedChildIndex = determineNumberOfBiopsiesMaxNumber(findingForm.form)
      if (maxSelectedChildIndex && maxSelectedChildIndex + 1 > numberOfBiopsiesRenderCount) {
        setNumberOfBiopsiesRenderCount(maxSelectedChildIndex)
      }
    }
  }, [editingItemId, findingForm])

  const displayedChildren = (
    finding: ZipModuleTerminologyCommonTerminologyNodeDto | null | undefined
  ) => {
    if (shouldLimitContainerChildrenSize(finding?.tKey ?? '')) {
      return finding?.children?.slice(0, containerRenderCount) ?? []
    }
    if (shouldLimitNumberOfBiopsiesChildrenSize(finding?.tKey ?? '')) {
      return finding?.children?.slice(0, numberOfBiopsiesRenderCount) ?? []
    }
    return finding?.children ?? null
  }

  const setMoreContainers = (finding: ZipModuleTerminologyCommonTerminologyNodeDto) => {
    if (
      shouldLimitContainerChildrenSize(finding?.tKey ?? '') &&
      containerRenderCount < (finding?.children?.length ?? 0) + 1
    ) {
      setContainerRenderCount(containerRenderCount + 1)
    }
    if (
      shouldLimitNumberOfBiopsiesChildrenSize(finding?.tKey ?? '') &&
      numberOfBiopsiesRenderCount < (finding?.children?.length ?? 0) + 1
    ) {
      setNumberOfBiopsiesRenderCount(numberOfBiopsiesRenderCount + 1)
    }
  }

  // todo check if this still works or fix it if it doesn't
  const disabledHelper = (componentName: string) => {
    if (procedureStatus) {
      return shouldDisableComponent(
        isProcedureFinished,
        findingForm.form,
        findingFormInitial.form,
        editingItemId,
        componentName
      )
    }
    return false
  }

  // Render add button for all containers and number of biopsies
  // logic for all containers is the same, for number of biopsies is for now specific
  const renderAddButton = () => {
    if (
      (shouldLimitContainerChildrenSize(finding?.tKey ?? '') &&
        tKeysMatch(finding?.tKey ?? '', 'container_number') &&
        containerRenderCount < (finding?.children?.length || 0)) ||
      (tKeysMatch(finding?.tKey ?? '', 'number_of_biopsies') &&
        numberOfBiopsiesRenderCount < (finding?.children?.length || 0))
    ) {
      return (
        <AddContainerButton
          finding={finding}
          handleClick={setMoreContainers}
          disabledHelper={disabledHelper('findings')}
        />
      )
    }
  }

  return (
    <div className={`${styles.radioButtonRow} ${isLevel4 ? styles.mb0 : ''}`}>
      {renderFindingLabel && <div className={styles.level2Label}>{finding.value}</div>}
      {finding.inputType === 'Number' ? (
        <div className={`${styles.finding} ${styles.findingNumInput}`}>
          <FindingNumber
            finding={finding}
            setFormData={setFormData}
            parentTkey={parentTKey}
            isDisabled={isDisabled}
          />
        </div>
      ) : (
        displayedChildren(finding)?.map(
          (option: ZipModuleTerminologyCommonTerminologyNodeDto, index: number) => {
            return (
              <Finding
                key={finding.tKey + '-' + index}
                finding={finding}
                option={option}
                setFormData={setFormData}
                disabled={isDisabled}
                parentTkey={parentTKey}
              />
            )
          }
        )
      )}
      {renderAddButton()}
    </div>
  )
}
