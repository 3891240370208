import { useAppSelector } from 'app/hooks'
import {
  getDeletingFindingId,
  getFindingDuplicateId,
  getObservations,
  getReadyToDelete,
  getSections,
  getTabChanged,
  TSection,
} from 'features/dataCapture/dataCaptureSlice'
import { tKeysMatch } from 'features/dataCapture/utils/URLhelper'
import {
  determineTitleOfLevel1Node,
  sortObservationsByUpdatedAt,
} from 'features/dataCapture/utils/utils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

import DataCaptureCardWrapper from '../../card/DataCaptureCardWrapper'
import styles from '../styles.module.scss'
import DataCaptureSectionHeader from './DataCaptureSectionHeader'

interface IDataCaptureSectionWithTitle {
  section?: TSection
}

export interface IDataCaptureCardData {
  observationItem: ZipModuleCommonDtosComponentDto | ZipModuleCommonDtosObservationDto
  observation?: ZipModuleCommonDtosObservationDto
  workflowItem: ZipModuleTerminologyCommonTerminologyNodeDto
  title?: string | null
  tkey?: string | null
  updatedTime?: number
}

const DataCaptureSectionWithTitle = ({ section }: IDataCaptureSectionWithTitle) => {
  const location = useLocation()
  const sections = useAppSelector(getSections)
  const readyToDelete = useAppSelector(getReadyToDelete)
  const observations = useAppSelector(getObservations)
  const findingDuplicateId = useAppSelector(getFindingDuplicateId)
  const deletingFindingId = useAppSelector(getDeletingFindingId)
  const [cardsData, setCardsData] = useState<IDataCaptureCardData[]>([])
  const [lastEditedTime, setLastEditedTime] = useState<number>(0)

  const tabChanged = useAppSelector(getTabChanged)
  const shouldAnimateTabChange = () => {
    return (
      tabChanged.previous !== 'all' &&
      tabChanged.previous !== tabChanged.current &&
      tabChanged.previous !== section?.tKey &&
      tabChanged.current !== 'all'
    )
  }

  const shouldAnimateTabChangeSlideDown = () => {
    return (
      !!tabChanged.previous &&
      tabChanged.current === 'all' &&
      tabChanged.previous !== section?.tKey &&
      tabChanged.previous !== tabChanged.current
    )
  }

  const shouldAnimateTabChangeSlideUp = () => {
    return (
      ((!!tabChanged.previous && tabChanged.previous === 'all') || !tabChanged.previous) &&
      section?.tKey !== tabChanged.current &&
      tabChanged.previous !== tabChanged.current
    )
  }
  const [disableCardAnimation, setDisableCardAnimation] = useState<boolean>(true)

  useEffect(() => {
    // show animation only after card is deleted, this prevents exit card animation on tab switch
    if (readyToDelete) {
      setDisableCardAnimation(false)
    } else {
      const timeout = setTimeout(
        () => {
          setDisableCardAnimation(true)
        },
        // if we are deleting through `createEditHeader`, we need to enable animation for a longer duration
        location.state?.delete ? 4000 : 1500
      )
      return () => clearTimeout(timeout)
    }
  }, [readyToDelete])

  useEffect(() => {
    const tempArr: IDataCaptureCardData[] = []
    const updatedAtValues: number[] = []

    observations
      .filter((o) => o.components && o.components?.length > 0)
      .map((observation) => {
        const workflowItem = section?.terminology?.find((t) => tKeysMatch(t.tKey, observation.tKey))

        if (workflowItem && workflowItem.isUnique) {
          const updatedAt = observation.updatedAt ? new Date(observation.updatedAt).valueOf() : 0
          updatedAtValues.push(updatedAt)
          tempArr.push({ observationItem: observation, workflowItem, updatedTime: updatedAt })
        } else if (workflowItem) {
          observation?.components?.map((comp) => {
            const fallbackUpdatedAtTime =
              comp.dropdownIds &&
              (findingDuplicateId === comp.dropdownIds[0] ||
                deletingFindingId === comp.dropdownIds[0])
                ? Number.MAX_SAFE_INTEGER
                : 0
            const updatedAt = comp.updatedAt
              ? new Date(comp.updatedAt).valueOf()
              : fallbackUpdatedAtTime
            updatedAtValues.push(updatedAt)
            const level1 = determineTitleOfLevel1Node(sections, observation, comp)

            // if level1 is not found, component doesn't exist in data capture terminology
            if (level1) {
              const title = workflowItem?.renderWorkflowChildren
                ? level1?.value
                : workflowItem?.value
              const tkey = workflowItem?.renderWorkflowChildren ? level1?.tKey : workflowItem?.tKey

              tempArr.push({
                observationItem: comp,
                workflowItem,
                observation,
                title,
                tkey,
                updatedTime: updatedAt,
              })
            }
          })
        }
        // for all observations get updatedAt time
        else {
          sections
            .filter((s) => s.tKey != section?.tKey)
            .forEach((section) => {
              const workflowItem = section?.terminology?.find((t) =>
                tKeysMatch(t.tKey, observation.tKey)
              )
              if (workflowItem && workflowItem.isUnique) {
                const updatedAt = observation.updatedAt
                  ? new Date(observation.updatedAt).valueOf()
                  : 0
                updatedAtValues.push(updatedAt)
              } else if (workflowItem) {
                observation?.components?.map((comp) => {
                  const updatedAt = comp.updatedAt ? new Date(comp.updatedAt).valueOf() : 0
                  updatedAtValues.push(updatedAt)
                })
              }
            })
        }
      })

    sortObservationsByUpdatedAt(tempArr)
    setLastEditedTime(Math.max(...updatedAtValues))
    setCardsData(tempArr)
  }, [observations, section])

  const resolveLastEdited = (obs: IDataCaptureCardData) => {
    if (navigator.onLine) {
      return (
        !!obs.observationItem.updatedAt &&
        lastEditedTime === new Date(obs.observationItem.updatedAt).valueOf()
      )
    } else {
      return false
    }
  }

  const resolveDropdownIds = (obs: IDataCaptureCardData) => {
    if (obs.workflowItem.isUnique) {
      const obsComponents = (obs.observationItem as ZipModuleCommonDtosObservationDto)?.components
      if (obsComponents && obsComponents.length > 0) {
        return obsComponents[0]?.dropdownIds
      } else {
        return null
      }
    } else {
      return (obs.observationItem as ZipModuleCommonDtosComponentDto).dropdownIds
    }
  }

  return (
    <div style={{ overflow: 'hidden' }} id="nova-report-Data-Capture-Section-With-Title-root">
      <div
        className={`${styles.sectionContainer} 
        ${shouldAnimateTabChange() ? styles.sectionContainerAnimated : ''} 
        ${shouldAnimateTabChangeSlideDown() ? styles.sectionContainerAnimatedSlideDown : ''} 
        ${shouldAnimateTabChangeSlideUp() ? styles.sectionContainerAnimatedSlideUp : ''}  `}
        key={section?.tKey}
        id="nova-report-Data-Capture-Section-With-Title-root"
      >
        {/* before the section add the title if there are ANY biopsy containers present and the toggle for showOnlyContainers is true */}
        <DataCaptureSectionHeader section={section} />
        <TransitionGroup className={styles.transitionWithChildrenGroupCards}>
          {cardsData?.map((obs) => {
            return (
              <CSSTransition
                key={
                  obs.workflowItem.isUnique
                    ? (obs.observationItem as ZipModuleCommonDtosObservationDto).tKey
                    : (
                        obs.observationItem as ZipModuleCommonDtosComponentDto
                      ).dropdownIds?.toLocaleString()
                }
                classNames={{ exitActive: styles.dataCardExitActive }}
                timeout={{ exit: disableCardAnimation ? 0 : 300 }}
              >
                <DataCaptureCardWrapper
                  key={obs.tkey ?? (obs.observationItem as ZipModuleCommonDtosObservationDto).tKey}
                  tkey={obs.tkey ?? (obs.observationItem as ZipModuleCommonDtosObservationDto).tKey}
                  title={obs.title ?? obs.workflowItem.value}
                  workflowStep={obs.workflowItem}
                  observation={obs.workflowItem.isUnique ? obs.observationItem : obs.observation}
                  // @ts-ignore
                  dropdownIds={resolveDropdownIds(obs)}
                  isLastEdited={resolveLastEdited(obs)}
                />
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>
    </div>
  )
}

export default DataCaptureSectionWithTitle
