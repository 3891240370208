import i18n from 'i18next'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translationDE from './locales/de.json'
import translationEN from './locales/en.json'
import translationES from './locales/es.json'
import translationJA from './locales/ja.json'

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE
  },
  ja: {
    translation: translationJA
  }
}

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') ?? 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    fallbackLng: 'en',
    preload: ['en', 'de', 'ja', 'es'],
  })

export default i18n
