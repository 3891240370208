import { ModalType } from '@novax/zip-frontend-library'
import { IModalProps } from '@novax/zip-frontend-library/dist/cjs/types/components/Modal/Modal'
import React, { createContext, useContext, useState } from 'react'

import GlobalAntAsyncModal from './subcomponents/GlobalAntAsyncModal'
import GlobalAntModal from './subcomponents/GlobalAntModal'

export type ModalRef = { current: { onModalClose: () => void } | null }

export type ContextType = {
  showModal: (config: { modalType: ModalType; modalProps: object }) => void
  hideModal: () => void
  modalData: { modalType: ModalType | null; modalProps: IModalProps | null; mode?: string }
  hideAsyncModal: () => void
  modalRef: ModalRef
}

export const initialState: ContextType = {
  showModal: () => null,
  hideModal: () => null,
  modalData: { modalType: ModalType.INFO, modalProps: {} },
  hideAsyncModal: () => null,
  modalRef: { current: null },
}

const GlobalModalContext = createContext(initialState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

interface Props {
  children: React.ReactNode
}

export const GlobalModalProvider: React.FC<Props> = ({ children }) => {
  const [modalData, setModalData] = useState<{
    modalType: ModalType | null
    modalProps: object | null
    mode?: string
  }>({
    modalType: null,
    modalProps: null,
  })

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const modalRef: ModalRef = { current: { onModalClose: () => {} } }

  const showModal = (config: { modalType: ModalType; modalProps: object; mode?: string }) => {
    setModalData({
      ...modalData,
      modalType: config.modalType,
      modalProps: { ...config.modalProps },
      mode: config && config.mode,
    })
  }

  const hideModal = () => {
    setModalData({
      ...(typeof modalData === 'object' ? modalData : {}),
      modalType: null,
      modalProps: {},
      mode: undefined,
    })
  }

  const hideAsyncModal = () => {
    if (modalRef.current) {
      modalRef.current.onModalClose()
    }

    hideModal()
  }

  const renderComponent = () => {
    if (modalData.modalType && modalData.modalProps && modalData.mode != 'async') {
      return (
        <GlobalAntModal
          id="global-modal"
          type={modalData.modalType}
          {...(typeof modalData.modalProps === 'object' ? modalData.modalProps : {})}
        />
      )
    } else if (modalData.modalType && modalData.modalProps && modalData.mode == 'async') {
      return (
        <GlobalAntAsyncModal
          id="global-async-modal"
          type={modalData.modalType}
          {...(typeof modalData.modalProps === 'object' ? modalData.modalProps : {})}
        />
      )
    } else {
      return null
    }
  }

  return (
    <GlobalModalContext.Provider
      value={{
        modalData,
        showModal,
        hideModal,
        hideAsyncModal,
        modalRef,
      }}
    >
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  )
}
