import styles from './styles.module.scss'

const DataCaptureCardLoading = () => {
  return (
    <div
      className={styles.loadingCardContainer}
      data-testid="nova-report-Data-Capture-Card-Loading-root"
      id="nova-report-Data-Capture-Card-Loading-root"
    >
      <div className={styles.loadingCardInformation}>
        <div className={styles.loadingIcon} />
        <div className={styles.loadingCardTitles}>
          <div className={styles.loadingTitle} />
          <div className={styles.loadingSubtitle} />
        </div>
      </div>
      <div className={styles.loadingCardActionsWrapper}>
        <div className={styles.loadingCardActions}>
          <div className={styles.loadingIcon} />
          <div className={styles.loadingIcon} />
        </div>
        <div className={styles.loadingContainerInformation} />
      </div>
    </div>
  )
}

export default DataCaptureCardLoading
