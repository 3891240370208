import LoadingCard from 'components/loading/LoadingCard'
import { Fragment, useEffect, useState } from 'react'

import styles from './styles.module.scss'

const LoadingPathology = () => {
  const [sections, setSections] = useState<Array<number>>([])

  useEffect(() => {
    const array: number[] = []
    for (let i = 0; i < 12; i++) array.push(i)
    setSections(array)
  }, [])

  return (
    <>
      <Fragment>
        <div
          className={styles.labelSectionContainer}
          data-testid="loadingPathology"
          id="nova-report-Loading-Pathology-root"
        >
          <LoadingCard height="16px" />
        </div>
        <div className={styles.radioButtonRow}>
          {sections.map(
            (num) =>
              num < 6 && (
                <div className={`${styles.finding} ${styles.finding3}`} key={num}>
                  <LoadingCard />
                </div>
              )
          )}
        </div>
      </Fragment>
      <Fragment>
        <div className={styles.labelSectionContainer} data-testid="loadingPathology">
          <LoadingCard height="16px" />
        </div>
        <div className={styles.radioButtonRow}>
          {sections.map((num) => (
            <div className={`${styles.finding} ${styles.finding6}`} key={num}>
              <LoadingCard />
            </div>
          ))}
        </div>
      </Fragment>
      <Fragment>
        <div className={styles.labelSectionContainer} data-testid="loadingPathology">
          <LoadingCard height="16px" />
        </div>
        <div className={styles.radioButtonRow}>
          {sections.map(
            (num) =>
              num < 7 && (
                <div className={`${styles.finding} ${styles.finding3}`} key={num}>
                  <LoadingCard />
                </div>
              )
          )}
        </div>
      </Fragment>
      <Fragment>
        <div className={styles.labelSectionContainer} data-testid="loadingPathology">
          <LoadingCard height="16px" />
        </div>
        <div className={styles.radioButtonRow}>
          {sections.map((num) => (
            <div className={`${styles.finding} ${styles.finding6}`} key={num}>
              <LoadingCard />
            </div>
          ))}
        </div>
      </Fragment>
    </>
  )
}

export default LoadingPathology
