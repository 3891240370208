/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Children,
  cloneElement,
  createRef,
  isValidElement,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import {
  createRoutesFromElements,
  matchRoutes,
  Navigate,
  NavigateProps,
  Route,
  RouteObject,
  RouteProps,
  Routes,
  UNSAFE_RouteContext,
  useLocation,
  useRoutes,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import type { CSSTransitionProps } from 'react-transition-group/CSSTransition'

type Direction = 'forward' | 'back' | 'undirected'
type RouteElement = ReactElement<RouteProps, typeof Route>
type ChildElement = RouteElement | ReactElement<NavigateProps, typeof Navigate>
type RouteItem = Required<RouteObject> & {
  element: ReactElement & { ref: RefObject<HTMLDivElement> }
}

const getTransformStyles = (transformFn: string, max: string) => `
  & > .forward-enter {
    transform: ${transformFn}(${max});
    z-index: 10;
  }
  & > .forward-enter-active {
    transform: ${transformFn}(0);
    z-index: 10;
  }

  & > .back-enter {
    opacity: 1;
    z-index: 10;
  }
  & > .back-enter-active {
    transform: ${transformFn}(0);
    z-index: 10;
  }
  & > .forward-exit,
  & > .forward-exit-active {
    opacity: 1;
    z-index: 1;
  }
  & > .back-exit {
    transform: ${transformFn}(0);
    z-index: 10;
  }
  & > .back-exit-active {
    transform: ${transformFn}(${max});
    z-index: 10;
  }
`

const getTransitionGroupCss = (duration: number, timing: string) => css`
  display: grid;
  height: 100%;

  & > .item {
    grid-area: 1 / 1 / 4 / 4;

    &:not(:only-child) {
      &.back-enter-active {
        transition: opacity ${duration}ms ${timing};
      }
      &.forward-enter-active {
        transition: transform ${duration}ms ${timing};
      }
      &.forward-exit-active {
        transition: opacity ${duration}ms ${timing};
      }
      &.back-exit-active {
        transition: transform ${duration}ms ${timing};
      }
    }
  }

  &.slide {
    overflow: auto;
    ${getTransformStyles('translateX', '100%')}
  }
`

const isRouteElement = (element: ReactNode): element is RouteElement => {
  return isValidElement(element) && element.type === Route
}

const useNextPath = (pathname: string) => {
  const { matches: parentMatches } = useContext(UNSAFE_RouteContext)
  const routeMatch = parentMatches[parentMatches.length - 1]
  const parentPathnameBase = routeMatch ? routeMatch.pathnameBase : '/'
  return parentPathnameBase === '/' ? pathname : pathname.slice(parentPathnameBase.length) || '/'
}

const getMatch = (routes: RouteItem[], pathname: string) => {
  const matches = matchRoutes(routes, pathname)
  if (matches === null) {
    throw new Error(`Route ${pathname} does not match`)
  }

  const index = routes.findIndex((route) => {
    return matches.some((match) => match.route === route)
  })
  return { index, route: routes[index] }
}

export type SlideRoutesProps = {
  duration?: number
  timing?: 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' | 'linear'
  destroy?: boolean
  children: ChildElement | (ChildElement | undefined | null)[]
  compare?: (a: RouteItem, b: RouteItem) => number
}

const SlideRoutes = (props: SlideRoutesProps) => {
  const { duration = 200, timing = 'ease', destroy = true, compare, children } = props

  // routes
  const routeElements = Children.map(children, (child) => {
    if (!isRouteElement(child)) {
      return child
    }

    const { element, ...restProps } = child.props
    if (!element) {
      return child
    }

    const nodeRef = createRef<HTMLDivElement>()
    const newElement = (
      <div className="item" ref={nodeRef} id="nova-report-slide-Routes-root">
        {element}
      </div>
    )
    return { ...child, props: { ...restProps, element: newElement } }
  })

  const routes = createRoutesFromElements(routeElements) as RouteItem[]
  if (compare) {
    routes.sort(compare)
  }

  const location = useLocation()
  const routeList = useRoutes(routes, location)

  // direction
  const nextPath = useNextPath(location.pathname)
  const prevPath = useRef<string | null>(null)
  const direction = useRef<Direction>('undirected')

  const shouldTransition =
    location.pathname.includes('data-capture') &&
    prevPath.current &&
    prevPath.current.includes('data-capture')

  const nextMatch = shouldTransition
    ? getMatch(routes, nextPath)
    : // this object prevents transition
      { index: -1, route: { element: { ref: null } } as unknown as RouteItem }

  // if we need to add animation set direction
  if (shouldTransition) {
    if (prevPath.current && prevPath.current !== nextPath) {
      const prevMatch = getMatch(routes, prevPath.current)
      const indexDiff = nextMatch.index - prevMatch.index

      if (indexDiff > 0) {
        direction.current = 'forward'
      } else if (indexDiff < 0) {
        direction.current = 'back'
      } else if (indexDiff === 0) {
        direction.current = 'undirected'
      }
    }
    // animation is not needed -> set to undirected
  } else {
    direction.current = 'undirected'
  }

  prevPath.current = nextPath

  // props
  const childFactory = useCallback(
    (child: ReactElement<CSSTransitionProps>) =>
      cloneElement(child, { classNames: direction.current }),
    []
  )

  const cssTransitionProps = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => (destroy ? { timeout: duration } : { addEndListener() {} }),
    [destroy, duration]
  )
  return location.pathname.includes('data-capture') ? (
    <TransitionGroup
      className={`slide-routes slide`}
      childFactory={childFactory}
      css={getTransitionGroupCss(duration, timing)}
    >
      <CSSTransition
        key={nextMatch.route.path ?? nextMatch.index}
        nodeRef={nextMatch.route.element.ref}
        {...cssTransitionProps}
      >
        {routeList}
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <Routes>{children}</Routes>
  )
}

export default SlideRoutes
