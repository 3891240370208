import _ from 'lodash'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleFeaturesDataCaptureResponsesSectionDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

import { TSection } from '../dataCaptureSlice'
import { IFindingForm } from '../interfaces/IPathology'
import { normalizeTKeyParams, splitTKeyParams, tKeysMatch } from './URLhelper'
import { resolveDropdownIdsToTKeysList } from './utils'

export const findObservationTerminology = (
  sections: ZipModuleFeaturesDataCaptureResponsesSectionDto[],
  form: IFindingForm
) => {
  const observationKey = splitTKeyParams(Object.keys(form)[0])[0]

  return sections
    .flatMap((section) => section.terminology || [])
    .find(
      (terminology) => terminology.tKey && normalizeTKeyParams(terminology.tKey) === observationKey
    )
}

export const filterGroupLevel1Values = (key: string, value?: string[]) => {
  return { key, value: value?.filter((id) => id && id.toString().split(',').length > 1) ?? [] }
}

export const groupFormByLvl1 = (form: IFindingForm) => {
  return _(form)
    .groupBy((id) => id && id.toString().split(',')[0])
    .map((value, key) =>
      key && key !== 'undefined' ? filterGroupLevel1Values(key, value as string[]) : undefined
    )
    .value()
}

export const handleUniqueObservation = (
  groupedLvl1s: (
    | {
        key: string
        value: (string | string[] | undefined)[]
      }
    | undefined
  )[],
  mappedComponents: ZipModuleCommonDtosComponentDto[],
  observationToUpdate: ZipModuleCommonDtosObservationDto,

  observationTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  sections: TSection[]
) => {
  groupedLvl1s.forEach((groupedLvl1) => {
    // for each lvl 1 group we create only one instance of the lvl1 component unique is true
    mappedComponents.push(
      createComponent(
        groupedLvl1?.key,
        groupedLvl1?.value,
        observationToUpdate,

        observationTerminology,
        sections
      )
    )
  })
  const deepObservation: ZipModuleCommonDtosObservationDto = JSON.parse(
    JSON.stringify(observationToUpdate)
  )
  deepObservation.components = mappedComponents
  deepObservation.isUpdated = true
  observationToUpdate = deepObservation
  return observationToUpdate
}

export const handleNonUniqueObservation = (
  groupedLvl1s: (
    | {
        key: string
        value: (string | string[] | undefined)[]
      }
    | undefined
  )[],
  mappedComponents: ZipModuleCommonDtosComponentDto[],
  observationToUpdate: ZipModuleCommonDtosObservationDto,
  editingFindingId: string | undefined,

  observationTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  sections: TSection[]
) => {
  let observationToUpdateDeepCopy: ZipModuleCommonDtosObservationDto = JSON.parse(
    JSON.stringify(observationToUpdate)
  )
  groupedLvl1s.forEach((groupedLvl1) => {
    if (editingFindingId !== '' && editingFindingId) {
      // in edit mode for non unique finding
      observationToUpdateDeepCopy = handleEditNonUniqueObservation(
        groupedLvl1,
        observationToUpdate,
        editingFindingId
      )
    } else {
      // in add mdoe for non unique finding
      mappedComponents.unshift(
        createComponent(
          groupedLvl1?.key,
          groupedLvl1?.value,
          observationToUpdate,
          observationTerminology,
          sections
        )
      )
    }
  })
  observationToUpdate = observationToUpdateDeepCopy
  observationToUpdate.isUpdated = true
  return observationToUpdate
}

export const handleEditNonUniqueObservation = (
  groupedLvl1:
    | {
        key: string
        value: (string | string[] | undefined)[]
      }
    | undefined,
  observationToUpdate: ZipModuleCommonDtosObservationDto,
  editingFindingId: string
) => {
  // create deep copy observation
  const deepObservation: ZipModuleCommonDtosObservationDto = JSON.parse(
    JSON.stringify(observationToUpdate)
  )

  // find index of the component to edit
  const componentToEditIndex =
    observationToUpdate.components?.findIndex(
      (component) => component.dropdownIds && component.dropdownIds[0] === editingFindingId
    ) ?? 0

  // find component to edit
  const componentToEdit = observationToUpdate.components?.find(
    (component) => component.dropdownIds && component.dropdownIds[0] === editingFindingId
  )

  // create deep copy component to edit
  const deepComponentToEdit: ZipModuleCommonDtosComponentDto | undefined =
    componentToEdit && JSON.parse(JSON.stringify(componentToEdit))

  if (deepComponentToEdit && deepComponentToEdit.dropdownIds) {
    if (tKeysMatch(observationToUpdate.tKey, 'medication')) {
      if (
        groupedLvl1?.value &&
        groupedLvl1.value[0] &&
        groupedLvl1?.value[0].toString().split(',').length > 2
      ) {
        // medication has amount `antibioticsId,amountId,5`
        const valuesArray =
          groupedLvl1.value && groupedLvl1.value.length
            ? groupedLvl1.value?.toString().split(',')
            : undefined
        const amountValue = valuesArray?.pop()
        deepComponentToEdit.dropdownIds = _.concat(
          editingFindingId && editingFindingId.split(',')[0] === groupedLvl1?.key
            ? editingFindingId
            : `${groupedLvl1?.key},${findBiggestIndex(observationToUpdate.components)}`,
          valuesArray?.join(',')
        ) as []
        deepComponentToEdit.text = amountValue ?? null
        deepComponentToEdit.isUpdated = true
      } else {
        // medicaton has only selected level1
        deepComponentToEdit.dropdownIds = _.concat(
          editingFindingId && editingFindingId.split(',')[0] === groupedLvl1?.key
            ? editingFindingId
            : `${groupedLvl1?.key},${findBiggestIndex(observationToUpdate.components)}`,
          groupedLvl1?.value
            ? groupedLvl1.value.map((dropdownIds) => observationToUpdate.id + ',' + dropdownIds)
            : []
        ) as []
        deepComponentToEdit.text = null
        deepComponentToEdit.isUpdated = true
      }
    } else {
      // replace everything in the dropdownIds except the first ID pair `level1Id,index` - keeping it the same for edit mode
      deepComponentToEdit.isUpdated = true
      deepComponentToEdit.dropdownIds = _.concat(
        deepComponentToEdit.dropdownIds[0],
        groupedLvl1?.value
      ) as []
    }
  }

  if (deepObservation && deepObservation.components) {
    // change component on componentToEditIndex with newly updated component and put it first in array to show on top
    deepObservation.components.splice(componentToEditIndex, 1)
    deepObservation.components.unshift(deepComponentToEdit as ZipModuleCommonDtosComponentDto)

    // update obesrvation with deep copy
    observationToUpdate = deepObservation
    return observationToUpdate
  }
  return {}
}

export const createComponent = (
  key: string | undefined,
  value: (string | string[] | undefined)[] | undefined,
  observationToUpdate: ZipModuleCommonDtosObservationDto,
  observationTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  sections: TSection[]
) => {
  // creating a new component and filling dropdownIds with value
  let textAmount = null
  const valuesArray = value && value.length ? value?.toString().split(',') : undefined

  // for medication we need to push "amount" into `text` property of component which is the last value in valuesArray
  // generating TKeys from valuesArray to check if the "amount" is set
  if (
    tKeysMatch(observationTerminology?.tKey, 'medication') &&
    tKeysMatch(
      splitTKeyParams(
        resolveDropdownIdsToTKeysList(
          sections,
          [observationTerminology?.id, ...(valuesArray ?? [])].join(','),
          undefined,
          observationTerminology
        )
      ).pop(),
      'amount'
    )
  ) {
    textAmount = valuesArray?.pop()
  }

  // default behaviour
  return {
    dropdownIds: _.concat(
      `${key},${
        observationTerminology && observationTerminology.isUnique
          ? '1'
          : findBiggestIndex(observationToUpdate?.components)
      }`,
      tKeysMatch(observationTerminology?.tKey, 'medication') && valuesArray
        ? valuesArray?.join(',')
        : value
    ) as [],
    text: textAmount,
    images: null,
    isUpdated: true,
  }
}

export const updateObservationWithComponents = (
  observationToUpdate: ZipModuleCommonDtosObservationDto,
  mappedComponents: ZipModuleCommonDtosComponentDto[],
  observationTerminology: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
) => {
  // updating observation with its own properties, or if they not exist (newly created observation), then add value from terminology
  observationToUpdate = {
    id: observationToUpdate.id ?? observationTerminology?.id,
    value: observationToUpdate.value ?? observationTerminology?.value,
    tKey: observationToUpdate.tKey ?? observationTerminology?.tKey,
    components: _.uniqBy(
      [...mappedComponents, ...(observationToUpdate.components ?? [])],
      (val) => val.dropdownIds && val.dropdownIds[0]
    ),
    description: observationToUpdate.description ?? null,
    isSaved: observationToUpdate.isSaved ?? false,
    descriptionSlateJs: observationToUpdate.descriptionSlateJs ?? null,
    isUpdated: observationToUpdate.isUpdated ?? false,
  }

  return observationToUpdate
}

export const updateObservationsArray = (
  observations: ZipModuleCommonDtosObservationDto[],
  observationToUpdate: ZipModuleCommonDtosObservationDto
) => {
  // create deep copy observations
  const deepObservations: ZipModuleCommonDtosObservationDto[] | undefined = JSON.parse(
    JSON.stringify(observations)
  )

  // find index of the observation we want to update
  const observationExistsIndex = deepObservations?.findIndex(
    (observation) =>
      observation.tKey &&
      observationToUpdate.tKey &&
      normalizeTKeyParams(observation.tKey) === normalizeTKeyParams(observationToUpdate.tKey)
  )

  if (deepObservations && observationExistsIndex !== undefined)
    if (observationExistsIndex !== -1) {
      // if observation exists, remove from that index and put on first place
      deepObservations.splice(observationExistsIndex, 1)
      deepObservations.unshift(observationToUpdate)
    } else {
      // if observation was not found, push it as new observation
      deepObservations.unshift(observationToUpdate)
    }

  return deepObservations
}

export const findBiggestIndex = (
  components: ZipModuleCommonDtosComponentDto[] | undefined | null
) => {
  // finds biggest index from the components for the same level 1
  let maxNumberOfSameLevel1 = 0

  components?.forEach((el) => {
    const localMax =
      el && el.dropdownIds && el.dropdownIds[0] ? Number(el.dropdownIds[0].split(',')[1]) : 0

    if (localMax > maxNumberOfSameLevel1) {
      maxNumberOfSameLevel1 = localMax
    }
  })

  return maxNumberOfSameLevel1 + 1
}
