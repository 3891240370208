import { getConfig, getToken, updateToken } from '@novax/os'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { BaseQueryApi, BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react'
import { Semaphore } from 'async-mutex'
import qs from 'qs'

const semaphore = new Semaphore(5)
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    // refresh token if it expires in next 8 seconds
    await updateToken()

    // cannot store a token via redux-persist
    // because hooks cannnot be called in here
    const token = getToken()
    const realm = getConfig()?.AUTH_REALM ?? ''
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('Nova-Tenant-Id', realm)
      headers.set('Nova-Tenant-Language', localStorage.getItem('i18nextLng') ?? 'en')
    }
    return headers
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
  validateStatus: (response: Response) => {
    return response.status >= 200 && response.status <= 299
  },
})

const requestsCacheMap = new Map<
  string,
  {
    args: FetchArgs
    api: BaseQueryApi
    extraOptions: object
    timestamp: number
  }
>()

const baseQueryWithOfflineRetry: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error) {
    if (!navigator.onLine) {
      const timestamp = Date.now()

      // idempotent methods, only most recent is retried
      if (args.method && ['PUT', 'DELETE'].includes(args.method)) {
        requestsCacheMap.set(args.method + ':' + args.url, {
          args,
          api,
          extraOptions,
          timestamp,
        })
        // non-idempotent methods, all are retried
      } else if (args.method && ['POST', 'PATCH'].includes(args.method)) {
        requestsCacheMap.set(args.method + ':' + args.url + ':' + timestamp, {
          args,
          api,
          extraOptions,
          timestamp,
        })
      }

      await addEventListener('online', async function retryRequestWhenOnline(event) {
        if (event?.target) {
          event.target.removeEventListener(event.type, retryRequestWhenOnline)
        }

        if (
          requestsCacheMap.has(args.method + ':' + args.url) ||
          requestsCacheMap.has(args.method + ':' + args.url + ':' + timestamp)
        ) {
          semaphore.acquire().then(async ([, release]) => {
            try {
              if (args.method && ['PUT', 'DELETE'].includes(args.method)) {
                const reqObject = requestsCacheMap.get(args.method + ':' + args.url)

                if (reqObject && reqObject.timestamp === timestamp) {
                  result = await baseQuery(reqObject.args, reqObject.api, reqObject.extraOptions)
                  requestsCacheMap.delete(args.method + ':' + args.url)
                }
              } else if (args.method && ['POST', 'PATCH'].includes(args.method)) {
                const reqObject = requestsCacheMap.get(
                  args.method + ':' + args.url + ':' + timestamp
                )

                if (reqObject) {
                  result = await baseQuery(reqObject.args, reqObject.api, reqObject.extraOptions)
                  requestsCacheMap.delete(args.method + ':' + args.url + ':' + timestamp)
                }
              }
            } finally {
              release()
            }
          })
        }
      })
    }
  }
  return result
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const templateApi = createApi({
  baseQuery: baseQueryWithOfflineRetry,
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
})
