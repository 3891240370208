import SectionMenuLoading from '../sectionMenu/SectionMenuLoading'
import DataCaptureLoadingSection from './DataCaptureLoadingSection'

const DataCaptureLoadingSkeleton = () => {
  return (
    <>
      <SectionMenuLoading>
        <DataCaptureLoadingSection />
        <div
          style={{ height: 16 }}
          id="nova-report-Data-Capture-Loading-Skeleton-root"
          data-testid="nova-report-Data-Capture-Loading-Skeleton-root"
        />
        <DataCaptureLoadingSection />
      </SectionMenuLoading>
    </>
  )
}

export default DataCaptureLoadingSkeleton
