import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleFeaturesPathologyResponsesFindingsSummaryDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from 'services/zipmodule.gen'

import { TSection } from '../dataCaptureSlice'
import { IDataCaptureCardData } from '../feature/overview/subcomponents/section/subcomponents/DataCaptureSectionWithTitle'
import { IFindingForm } from '../interfaces/IPathology'
import { joinTKeyParams, normalizeTKeyParams, splitTKeyParams, tKeysMatch } from './URLhelper'

export const getPatientAge = (date: string | null) => {
  if (!date) return null

  const formatDate = new Date(date)
  const currentYear = new Date().getUTCFullYear()
  const calculateAge = currentYear - formatDate.getUTCFullYear()
  return calculateAge
}

export const determineTitleOfLevel1Node = (
  sections: TSection[],
  observation: ZipModuleFeaturesPathologyResponsesFindingsSummaryDto,
  component: ZipModuleCommonDtosComponentDto
) => {
  // find active section from the URL
  const section = sections.find((section) =>
    section.terminology?.find((t) => tKeysMatch(t.tKey, observation.tKey))
  )

  // find workflow step from URL section
  const workflowStep = section?.terminology?.find((t) => tKeysMatch(t.tKey, observation.tKey))
  // find level 1 from workflow step (e.g. Polyp, Antibiotics, Diverticulosis)
  return workflowStep?.children?.find(
    (level2) => component.dropdownIds && level2.id === component.dropdownIds[0].split(',')[0]
  )
}

// Function to check if a string has both space and a number. using this to check for "Container 1" string
const hasSpaceAndNumber = (str: string) => /\s/.test(str) && /\d/.test(str)

// Function to extract the numeric part from a string (Container 3 == 3)
const extractNumber = (str: string) => str.replace(/\D/g, '')

// Function to extract the non-numeric part from a string (Container 3 == Container)
const extractStringBeforeNumber = (str: string) => str.replace(/(\d.*)/, '')

export const determineBiopsyContainer = (
  workflowStep: ZipModuleTerminologyCommonTerminologyNodeDto | undefined | null,
  dropdownIds: string[] | undefined,
  translation: typeof useTranslation
) => {
  // value that this method is returning, array of biopsy and polypectomy containers
  let containers: string[] = []

  const level1 = workflowStep?.children?.find(
    (level1) => dropdownIds && level1.id === dropdownIds[0].split(',')[0]
  )
  if (!level1) {
    return []
  }

  level1.children?.forEach((lvl3) => {
    lvl3.children?.forEach((lvl4) => {
      lvl4.children?.forEach((lvl5) => {
        if (lvl5.tKey == 'container_number') {
          containers = [
            ...containers,
            ...findContainers(
              level1,
              lvl3.tKey + ',' + lvl4.tKey + ',' + lvl5.tKey,
              lvl3.value ?? '',
              'lvl3',
              dropdownIds,
              translation
            ),
          ]
        }
      })
    })
  })

  containers = [
    ...containers,
    ...findContainers(level1, 'container_number', '', 'lvl2', dropdownIds, translation),
  ]

  return containers
}

export const findContainers = (
  level1: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  tKey: string,
  lvl3Value: string,
  lvl: string,
  dropdownIds: string[] | undefined,
  translation: typeof useTranslation
) => {
  // value that this method is returning, array of biopsy and polypectomy containers
  let containers = []

  let levelToSearchFor: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
  let containerIdToFind = ''
  if (lvl == 'lvl2') {
    // looking if level1 has container
    levelToSearchFor = level1?.children?.find(
      (level2) =>
        level2.tKey?.toLowerCase() === 'container_number' || level2.tKey?.toLowerCase() === 'biopsy'
    )
    // creating dropdownId how it should look like in that array so we can find containers
    containerIdToFind = level1?.id + ',' + levelToSearchFor?.id
  } else if (lvl == 'lvl3') {
    // looking if level1 has container
    const level2 = level1?.children?.find(
      (level2) => level2.tKey?.toLowerCase() === tKey.split(',')[0]
    )
    const level3 = level2?.children?.find(
      (level2) => level2.tKey?.toLowerCase() === tKey.split(',')[1]
    )
    levelToSearchFor = level3?.children?.find(
      (level2) => level2.tKey?.toLowerCase() === tKey.split(',')[2]
    )
    if (!level2 || !levelToSearchFor) {
      return []
    }
    // creating dropdownId how it should look like in that array so we can find containers
    containerIdToFind =
      level1?.id + ',' + level2?.id + ',' + level3?.id + ',' + levelToSearchFor?.id
  }

  // we can have multiple containers selected it is an array
  const containerValues: Array<string> = []
  dropdownIds?.forEach((id) => {
    // if dropdownIds have container value
    // and that container has value selected (ex. Container 3) push it to containerValues array
    if (
      id &&
      id.includes(containerIdToFind) &&
      id.split(',').length > containerIdToFind.split(',').length
    ) {
      const containerValue = levelToSearchFor?.children?.find(
        (child) => child.id == id.split(',')[containerIdToFind.split(',').length]
      )
      if (containerValue?.value && normalizeTKeyParams(containerValue.tKey) != 'no_container') {
        containerValues.push(containerValue.value)
      }
    }
  })

  // if hospital has something different for example: Container Blue, then just render everything
  // or there is only one container
  // (ex: Container 1, Container 2, Container 3)
  if (containerValues && containerValues.length > 0) {
    let sentence = containerValues.join(', ')
    if (level1?.tKey == 'polyp' && lvl == 'lvl2') {
      sentence = translation('common.biopsyContainer') + ' - ' + sentence
    }
    if (lvl == 'lvl3') {
      sentence = lvl3Value + ' - ' + sentence
    }
    containers.push(sentence)
  }

  // if all containers are in format Container 2 (string + space + number) + we have multiple containers
  // we want to render: "Container 1,2,3"
  const allContainersValid = containerValues.every(hasSpaceAndNumber)
  if (allContainersValid && containerValues.length > 1) {
    containers = []
    // Extract the numeric part from each valid container
    const containerNumbers = containerValues.map(extractNumber)
    const sortedNumebrs = _.sortBy(containerNumbers, (number) => parseInt(number, 10))
    // Container + all the numbers selected
    let sentence = extractStringBeforeNumber(containerValues[0]) + ' ' + sortedNumebrs.join(', ')
    if (level1?.tKey == 'polyp' && lvl == 'lvl2') {
      sentence = translation('common.biopsyContainer') + ' - ' + sentence
    }
    if (lvl == 'lvl3') {
      sentence = lvl3Value + ' - ' + sentence
    }
    containers.push(sentence)
  }
  return containers
}

export const shouldDisableComponent = (
  procedureCompleted: boolean,
  findingForm: IFindingForm,
  findingFormInitial: IFindingForm,
  editingItemId: string,
  component: string
) => {
  const editModeChange = !_.isEqual(findingForm, findingFormInitial)

  switch (component) {
    case 'backBtn': {
      return editingItemId !== ''
    }
    case 'summaryBody': {
      return (editModeChange && procedureCompleted) || !!editingItemId || procedureCompleted
    }
    case 'updateFinding': {
      return !editModeChange || procedureCompleted
    }
    case 'deleteFinding':
    case 'findings':
      return procedureCompleted
    case 'addToSummaryBtn':
    case 'segmentedControl':
    case 'finishProcedureBtn': {
      return procedureCompleted || !!editingItemId
    }
    default:
      return true
  }
}

export const shouldRenderLevel4 = (
  option: ZipModuleTerminologyCommonTerminologyNodeDto,
  finding: ZipModuleTerminologyCommonTerminologyNodeDto,
  findingForm: IFindingForm,
  parentTkey: string
) => {
  for (const [key, value] of Object.entries(findingForm)) {
    if (
      finding.tKey &&
      option.tKey &&
      key === joinTKeyParams([parentTkey, finding.tKey, option.tKey])
    ) {
      const level3Id = value && value.split(',')[2]
      return !!(level3Id === option.id && option.children?.length && option.children?.length > 0)
    }
  }
}

export const setErrors = (id: string, errors: string[], error?: string[]) => {
  const err = error && error[0]
  const filteredErrors = errors.filter((error) => error.split(',')[0] !== id)
  err && filteredErrors.push(err)
  return filteredErrors
}

/**
 *
 * Resolves params string to currently selected section, workflow and
 * workflow child object.
 *
 * @param sections arrays of section objects from terminology
 * @param params url parameters with workflow and child tKeys set
 *
 * @return a tupple with a selected section, workflow and workflowChild
 */
// todo fix determineSelectedWorkflowObjects workflow child - be careful of all usages of this function
export const determineSelectedWorkflowObjects = (
  sections: TSection[],
  params: { workflowTKey: string; childTKey: string | null }
): Readonly<{
  terminologySection: TSection | null
  workflow: ZipModuleTerminologyCommonTerminologyNodeDto | null
  workflowChild: ZipModuleTerminologyCommonTerminologyNodeDto | null
}> => {
  const terminologySection = sections.find((section) => {
    return section.terminology?.find((t) => tKeysMatch(t.tKey, params.workflowTKey))
  })

  const workflow = terminologySection?.terminology?.find((t) =>
    tKeysMatch(t.tKey, params.workflowTKey)
  )

  const workflowChild =
    params.childTKey && workflow?.children?.find((c) => tKeysMatch(c.tKey, params.childTKey))

  return {
    terminologySection: terminologySection || null,
    workflow: workflow || null,
    workflowChild: workflowChild || null,
  } as const
}

/**
 *
 * Resolves to a workflow child node object if one is selected.
 *
 * @param findingForm findingForm object from localStorage
 * @param keyName currently selected workflow keyName
 * @param workflow currently selected workflow object
 */
export const determineSelectedWorkflowChild = (
  findingForm: IFindingForm,
  keyName: string,
  workflow: ZipModuleTerminologyCommonTerminologyNodeDto
): ZipModuleTerminologyCommonTerminologyNodeDto | null | undefined => {
  for (let key in findingForm) {
    if (key.includes(keyName) && workflow.children) {
      //for amount, remove "amount" part from the key and return with `workflowTkey{,}level1Tkey`

      if (tKeysMatch(splitTKeyParams(key)[2], 'amount')) {
        key = joinTKeyParams(splitTKeyParams(key).slice(0, -1))
      }
      return workflow.children.find(
        (child) => child.id === findingForm[key as keyof typeof findingForm]
      )
    }
  }

  return null
}

export const getTerminologyItemByTKeys = (
  tKeys: string[],
  terminologyObjects: ZipModuleTerminologyCommonTerminologyNodeDto[] | null | undefined
): ZipModuleTerminologyCommonTerminologyNodeDto | null | undefined => {
  // Base case: if the object is null/undefined or tKeys array is empty, return undefined
  if (!terminologyObjects || terminologyObjects.length === 0 || tKeys.length === 0) {
    return undefined
  }

  const currentTKey = tKeys[0]

  // Find the object in children array with matching tKey
  const foundObject = terminologyObjects.find((child) => child.tKey === currentTKey)
  if (foundObject) {
    // If it's the last tKey in the array, return the found object
    if (tKeys.length === 1) {
      return foundObject
    } else {
      //otherwise, recursively call the function with remaining tKeys and the found object's children
      return getTerminologyItemByTKeys(tKeys.slice(1), foundObject.children)
    }
  } else {
    // If no object with matching tKey is found, return undefined
    return undefined
  }
}

/**
 *
 * Generates terminology key list from dropdown ids
 *
 * @param sections
 * @param selectedSectionTKey
 * @param dropdownIds
 */
export const resolveDropdownIdsToTKeysList = (
  sections: TSection[],
  dropdownIds: string,
  selectedSectionTKey?: string,
  terminologyItem?: ZipModuleTerminologyCommonTerminologyNodeDto
) => {
  const terminologySection = sections.find((section) => {
    return section.terminology?.find((t) => tKeysMatch(t.tKey, selectedSectionTKey))
  })

  let currentItem =
    terminologyItem ??
    terminologySection?.terminology?.find((t) => tKeysMatch(t.tKey, selectedSectionTKey))

  const tKeyListItems: string[] = [selectedSectionTKey ?? '']
  dropdownIds?.split(',').map((dropdownId: string) => {
    if (currentItem && currentItem?.children) {
      const childIndex = currentItem.children.findIndex((c) => {
        return c.id === dropdownId
      })

      if (childIndex > -1 && currentItem?.tKey) {
        currentItem = currentItem.children[childIndex]
        tKeyListItems.push(currentItem?.tKey ?? '')
      }
    }
  })

  return joinTKeyParams(tKeyListItems)
}

export const findSectionFindings = (
  section: TSection,
  observations: ZipModuleCommonDtosObservationDto[]
) => {
  let findingsFound = false
  section.terminology?.forEach((t) => {
    observations.find((o) => tKeysMatch(o.tKey, t.tKey))?.components?.length
      ? (findingsFound = true)
      : null
  })
  return findingsFound
}

/**
 * Given a components array from the observation, find the {id, index} pair from
 * the dropdown components with the biggest index value.
 *
 * @param components
 */
export const findMaxIndexDropdownId = (components: ZipModuleCommonDtosComponentDto[]) => {
  return components.reduce(
    (maxDropdownId, currentObj: ZipModuleCommonDtosComponentDto) => {
      if (currentObj.dropdownIds && currentObj.dropdownIds[0].split(',').length >= 2) {
        const [id, indexStr] = currentObj.dropdownIds && currentObj.dropdownIds[0].split(',')
        const index = parseInt(indexStr)
        if (!isNaN(index) && index > maxDropdownId.index) {
          return { id, index }
        }
      }

      return maxDropdownId
    },
    { id: '', index: -1 }
  )
}

/**
 *
 * Generate empty finding with values in format
 * ˙${terminologyNodeLevel0.key},{,},${terminologyNodeLevel1.key} = terminologyNodeLevel1.id`
 *  except for medication which has `${terminologyNodeLevel0.key} = terminologyNodeLevel0.id`.
 *
 *  In e.g. empty polyp: {findings{,}polyp: '20230771'},
 *  empty medication {medication: '20230009'} ,
 *  empty bbps {preparation{,}boston_bowel_preparation_score: '20230007'},
 *  empty procedure details
 *  {procedure_details{,}patient_preparation: '20230010',
 *  procedure_details{,}sedation: '20230010',
 *  procedure_details{,}insufflation: '20230010',
 *  procedure_details{,}asa_classification: '20230010'}
 *
 * @param terminologySection terminologySection object (or node level 0)
 * @param terminologyNode terminologyNode (or node level 1)
 */
export const generateEmptyFindingFindingFormData = (
  terminologySection: TSection,
  terminologyNode: ZipModuleTerminologyCommonTerminologyNodeDto
) => {
  const findingForm: Record<string, string | null> = {}

  if (!terminologyNode.alwaysSelected && terminologySection.tKey && terminologyNode.tKey) {
    // currently only medication
    if (terminologyNode.lvl === 0) {
      findingForm[normalizeTKeyParams(terminologyNode.tKey)] = terminologyNode.id || null
    } else {
      // findings
      findingForm[joinTKeyParams([terminologySection.tKey, terminologyNode.tKey])] =
        terminologyNode.id || null
    }
  } else if (terminologyNode.children) {
    // procedure details (except medication)
    terminologyNode.children.map((terminologyChildNode) => {
      if (terminologyNode.tKey && terminologyChildNode.tKey) {
        findingForm[joinTKeyParams([terminologyNode.tKey, terminologyChildNode.tKey])] =
          terminologyNode.id || null
      }
    })
  }

  return findingForm
}

export const getStopwatchMsTime = (procedureId: string) => {
  if (localStorage.getItem(procedureId + '-stopwatchStartTime')) {
    const startStopWatchDate = Number(localStorage.getItem(procedureId + '-stopwatchStartTime'))
    return Number(Date.now()) - startStopWatchDate
  } else return 0
}

export const sortObservationsByUpdatedAt = (observations: IDataCaptureCardData[]) => {
  observations.sort((a, b) => {
    if (!a.updatedTime || !b.updatedTime) {
      return 0
    } else {
      const dateA = a.updatedTime
      const dateB = b.updatedTime

      return dateB - dateA
    }
  })
}

export const removePropertiesFromObjectByKey = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  keyToExclude: string,
  option?: string,
  keyToKeep?: string,
  level?: number
) => {
  const temp = data

  for (const key in data) {
    if (keyToKeep && key.includes(keyToKeep)) {
      continue
    } else if (option) {
      if (temp[key].includes(option)) delete temp[key]
    } else {
      if (
        tKeysMatch(
          joinTKeyParams(splitTKeyParams(key).slice(0, level)),
          joinTKeyParams(splitTKeyParams(keyToExclude).slice(0, level))
        )
      )
        delete temp[key]
    }
  }
  return temp
}
