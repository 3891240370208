import { LeftIcon, MinusIcon, PlusIcon, RightIcon } from '@novax/zip-frontend-library'
import { Button, Divider } from 'antd'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getSelectedPage,
  getTotalPageCount,
  setSelectedPage,
} from 'features/previewReport/previewReportSlice'

import colors from '../../../../scss/colors.scss'
import styles from './styles.module.scss'

export interface PreviewReportPageControlDockProps {
  zoomRatio: number
  onZoomRatioChange: (newRatio: number) => void
  zoomEnabled?: boolean
}

const PreviewReportPageControlDock = ({
  zoomEnabled,
  zoomRatio,
  onZoomRatioChange,
}: PreviewReportPageControlDockProps) => {
  const dispatch = useAppDispatch()
  const totalPageCount = useAppSelector(getTotalPageCount)
  const selectedPage = useAppSelector(getSelectedPage)

  const handleSelectPage = (number: number) => {
    dispatch(setSelectedPage(number))
  }

  return (
    <div
      className={styles.paginationWrapper}
      id="nova-report-Preview-Report-Page-Control-Dock-root"
    >
      <div className={styles.pagination}>
        {selectedPage && totalPageCount && (
          <>
            <div
              className={styles.icon}
              id="nova-report-Preview-Report-Pdf-View-button-prev"
              data-testid="nova-report-Preview-Report-Pdf-View-button-prev"
              onClick={() =>
                handleSelectPage(selectedPage === 1 ? totalPageCount : selectedPage - 1)
              }
            >
              <LeftIcon />
            </div>
            <div style={{ letterSpacing: '11px', marginRight: '-11px' }}>
              {`${selectedPage}/${totalPageCount}`}
            </div>
            <div
              className={styles.icon}
              id="nova-report-Preview-Report-Pdf-View-button-next"
              data-testid="nova-report-Preview-Report-Pdf-View-button-next"
              onClick={() =>
                handleSelectPage(selectedPage === totalPageCount ? 1 : selectedPage + 1)
              }
            >
              <RightIcon />
            </div>
          </>
        )}
        {
          /* istanbul ignore next: code not reachable */ (zoomEnabled ?? true) && (
            <>
              <Divider type="vertical" className={styles.paginationDivider} />
              <Button
                data-testid="nova-report-Preview-Report-Pdf-View-button-zoom-minus"
                icon={<MinusIcon style={{ color: colors.white }} />}
                ghost
                className={styles.borderTransparent}
                disabled={zoomRatio < 0.1}
                onClick={() => onZoomRatioChange(zoomRatio - 0.1)}
              />
              <div>{`${Math.round(zoomRatio * 100)}%`}</div>
              <Button
                data-testid="nova-report-Preview-Report-Pdf-View-button-zoom-plus"
                icon={<PlusIcon style={{ color: colors.white }} />}
                ghost
                className={styles.borderTransparent}
                disabled={zoomRatio > 2.5}
                onClick={() => onZoomRatioChange(zoomRatio + 0.1)}
              />
            </>
          )
        }
      </div>
    </div>
  )
}
export default PreviewReportPageControlDock
