import { ReactNode } from 'react'

import styles from './styles.module.scss'
interface ISectionMenuLoading {
  children: ReactNode
}

const SectionMenuLoading = ({ children }: ISectionMenuLoading) => {
  return (
    <>
      <div
        className={`${styles.sectionWrapper} ${styles.gap24}`}
        id="nova-report-Section-Menu-Loading-root"
      >
        <div className={`${styles.flex} ${styles.gap16}`}>
          <div className={`${styles.sectionTitleLoading} ${styles.width33}`} />
          <div className={`${styles.sectionTitleLoading} ${styles.width160}`} />
          <div className={`${styles.sectionTitleLoading} ${styles.width70}`} />
        </div>
        <div className={`${styles.alignTop} ${styles.gap16} ${styles.flex}`}>
          <div className={`${styles.sectionTitleLoading} ${styles.width90} ${styles.marginTop}`} />
          <div className={`${styles.sectionTitleLoading} ${styles.width50} ${styles.rounded}`} />
        </div>
      </div>
      <>{children}</>
    </>
  )
}

export default SectionMenuLoading
