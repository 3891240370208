import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { BrowserHistoryOptions, createBrowserHistory } from 'history'

export const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: createBrowserHistory({
          basename: '',
        } as BrowserHistoryOptions),
      },
    },
  },
})

export function initAppInsights() {
  if (process.env.REACT_APP_INSIGHTS_CONNECTION && process.env.REACT_APP_ENVIRONMENT !== 'local') {
    appInsights.loadAppInsights()
  }
}
