import { ModalType } from '@novax/zip-frontend-library'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'

import { useGlobalModalContext } from '../GlobalModalProvider'
import styles from './styles.module.scss'
import { getIcon } from './utils'

interface Props {
  id: string
  type: ModalType
}

const GlobalAntAsyncModal: React.FC<Props> = ({ type }) => {
  const { hideAsyncModal, modalData, modalRef } = useGlobalModalContext()

  const { modalProps } = modalData
  const { title, content, cancelText, okText, width, onOk, onCancel } = modalProps || {}

  const [modalOpen, setModalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  // trigger open on component mount
  useEffect(() => {
    setModalOpen(true)
  }, [])

  const handleOk = () => {
    setConfirmLoading(true)
    onOk && onOk()
  }

  const handleCancel = () => {
    onCancel && onCancel()
    setModalOpen(false)
    hideAsyncModal()
  }
  useEffect(() => {
    if (modalRef.current) {
      // Set the ref in the context when the component mounts
      modalRef.current.onModalClose = () => {
        // Notify the component when hideAsyncModal is called
        setConfirmLoading(false)
      }

      return () => {
        modalRef.current = null
      }
    }
  }, [modalRef])

  const renderTitle = () => (
    <span
      className={styles.asyncModalTitle}
      id="nova-report-C:\-Users\katar\novax\zip-module\frontend\src\components\-Global-Modal-Provider\subcomponents\-Global-Ant-Async-Modal-root"
    >
      {getIcon(type)}
      {title}
    </span>
  )

  return (
    <Modal
      data-testid={'global-async-modal-root'}
      styles={{
        content: { padding: '32px 32px 28px' },
        footer: {
          display: 'flex',
          justifyContent: 'end',
          flexWrap: 'wrap',
          rowGap: '8px',
        },
      }}
      title={renderTitle()}
      open={modalOpen}
      onOk={handleOk}
      maskClosable={false}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      cancelButtonProps={{
        disabled: confirmLoading,
        style: { display: cancelText ? 'initial' : 'none' },
      }}
      okButtonProps={{
        className: type === ModalType.ERROR ? `${styles.errorStyles}` : '',
      }}
      cancelText={cancelText}
      okText={<span>{okText}</span>}
      width={width || '416px'}
    >
      <p id="nova-report-Global-Ant-Async-Modal-root" style={{ paddingLeft: '38px' }}>
        {content}
      </p>
    </Modal>
  )
}

export default GlobalAntAsyncModal
