import { Typography } from 'antd'
import { t } from 'i18next'

import styles from './styles.module.scss'

const NotForClinicalUseBanner = () => {
  return (
    <div
      className={styles.banner}
      id="nova-report-Not-For-Clinical-Use-Banner-root"
      data-testid="nova-report-Not-For-Clinical-Use-Banner-root"
    >
      <Typography className={styles.bannerText}>{t('common.notForClinicalUse')}</Typography>
    </div>
  )
}

export default NotForClinicalUseBanner
