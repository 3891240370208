import { IFindingForm } from 'features/dataCapture/interfaces/IPathology'
import { removePropertiesFromObjectByKey } from 'features/dataCapture/utils/utils'
import _ from 'lodash'

import {
  joinTKeyParams,
  splitTKeyParams,
  TKEY_SEPARATOR,
  tKeysMatch,
} from '../../../../../../../utils/URLhelper'

/**
 *
 * Sets new form data object, called after one finding is selected.
 *
 * @param form findingForm object from localStorage
 * @param pathologyObservation current term tree
 * @param workflow currently selected workflow object
 * @param id currently selected option id
 * @param keyName currently selected workflow keyName
 * @param inputType currently selected option input type
 * @param sequenceFromRoot selected option sequence from root value
 *
 */
export const setFormDataObject = (
  form: IFindingForm,
  keyName: string,
  sequenceFromRoot: string,
  inputType: string
) => {
  let dropdownIds = ''
  const key = keyName
  const level = splitTKeyParams(keyName).length - 1
  // set local variable to avoid non-writeable object
  let data = { ...form }
  if (
    (tKeysMatch(splitTKeyParams(keyName)[0], 'medication') && inputType !== 'Number') ||
    !tKeysMatch(splitTKeyParams(keyName)[0], 'medication')
  ) {
    data = removePropertiesFromObjectByKey(
      data,
      key.slice(0, key.lastIndexOf(TKEY_SEPARATOR)),
      inputType === 'MultiSelect' ? sequenceFromRoot : undefined,
      tKeysMatch(splitTKeyParams(keyName)[1], 'no_additional_medication') ? undefined : 'amount',
      level
    )
    if (!_.isEmpty(data) && tKeysMatch(splitTKeyParams(keyName)[0], 'medication')) {
      // change old medication level 1 key and value to new
      const keys = Object.keys(data).map((k) => splitTKeyParams(k))[0]
      const values = Object.values(data).map((v) => v.toString().split(','))[0]

      keys[1] = splitTKeyParams(keyName)[1]
      values[0] = sequenceFromRoot

      data = { [joinTKeyParams(keys)]: values.join(',') }
    }
  }
  // generate dropdown ids
  dropdownIds = sequenceFromRoot
  // select or deselect option
  if (form[key as keyof typeof form] !== dropdownIds) {
    data = {
      ...data,
      [key]: dropdownIds,
    }
  } else {
    //remove property with empty value
    data = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(data).filter(([key, value]) => value !== '')
    )
  }

  return data
}
