import { Location, Params } from 'react-router-dom'

/**
 *
 * Create url links from section, workflow and child terminology keys.
 *
 * @param params
 * @param location
 * @param isCreateEdit
 */
export const createURLFromParams = (
  params: Params<string>,
  location: Location,
  isCreateEdit: boolean
) => {
  const sectionTKey = normalizeTKeyParams(params.sectionTKey)
  const workflowTKey = normalizeTKeyParams(params.workflowTKey)
  const childTKey = normalizeTKeyParams(params.childTKey)
  const dropdownId = normalizeTKeyParams(params.dropdownId)
  const createEdit = isCreateEdit ?? location.pathname.includes('create-edit')

  return `/data-capture/${params.id}${createEdit ? '/create-edit' : ''}${
    sectionTKey ? `/${sectionTKey}` : '/section_all'
  }${workflowTKey ? `/${workflowTKey}` : ''}${childTKey ? `/${childTKey}` : ''}${
    dropdownId ? `/${dropdownId}` : ''
  }`
}

/**
 *
 * Resolves params object to section tKey
 *
 * @param params url params object
 */
export const getSectionTKeyFromParams = (params: Readonly<Params<string>>) => {
  return params.sectionTKey?.replaceAll('section_', '')?.toLowerCase()
}

/**
 *
 * Funcition used to normalize terminology keys, into lowercase with no spaces,
 *  by chaining string transformations.
 *
 * @param paramsString terminology key string
 */
export const normalizeTKeyParams = (paramsString: string | null | undefined) => {
  return paramsString?.toLowerCase().replaceAll(' ', '_').trim() ?? ''
}

/**
 *
 * Returns true if two terminology keys match in their normalized forms.
 *
 * @param paramsString1 terminology key string
 * @param paramsString2 terminology key string
 */
export const tKeysMatch = (
  paramsString1: string | null | undefined,
  paramsString2: string | null | undefined
) => {
  return (
    paramsString1 &&
    paramsString2 &&
    normalizeTKeyParams(paramsString1) === normalizeTKeyParams(paramsString2)
  )
}

/**
 * Separator combination used in joining and splitting terminology keys.
 */
export const TKEY_SEPARATOR = '{,}'

/**
 * Used to join an array of terminology key into a joined normalized string with TKEY_SEPARATOR.
 * @param tKeys array of terminology key strings
 */
export const joinTKeyParams = (tKeys: string[]) => {
  const normalizedTKeys = tKeys.map((tKey) => normalizeTKeyParams(tKey))
  return normalizedTKeys.join(TKEY_SEPARATOR)
}

/**
 *
 * Used to split joined terminology keys string into an array of terminology keys by using TKEY_SEPARATOR.
 *
 * @param joinedTKeyString normalized joined string
 */
export const splitTKeyParams = (joinedTKeyString: string) => {
  if (!joinedTKeyString) {
    return []
  }
  return joinedTKeyString.split(TKEY_SEPARATOR)
}
