import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleFeaturesDataCaptureResponsesBasicInformationDto,
  ZipModuleFeaturesDataCaptureResponsesDataCaptureDto,
  ZipModuleFeaturesDataCaptureResponsesSectionDto,
} from 'services/zipmodule.gen'

import { IFindingForm } from '../interfaces/IPathology'
import {
  findObservationTerminology,
  groupFormByLvl1,
  handleNonUniqueObservation,
  handleUniqueObservation,
  updateObservationsArray,
  updateObservationWithComponents,
} from './dataCaptureMapperHelper'
import { normalizeTKeyParams, splitTKeyParams } from './URLhelper'

export const dataCaptureMapper = (
  form: IFindingForm,
  observations: ZipModuleCommonDtosObservationDto[],
  sections: ZipModuleFeaturesDataCaptureResponsesSectionDto[],
  basicInformation: ZipModuleFeaturesDataCaptureResponsesBasicInformationDto,
  editingFindingId?: string
) => {
  // initialize data model for backend
  const data: ZipModuleFeaturesDataCaptureResponsesDataCaptureDto = {
    basicInformation,
    sections,
    observations: [],
  }

  // find observation to update, or if does not exist set it to empty object
  let observationToUpdate: ZipModuleCommonDtosObservationDto =
    observations.find(
      (observation) =>
        observation.tKey &&
        normalizeTKeyParams(observation.tKey) === splitTKeyParams(Object.keys(form)[0])[0]
    ) || {}

  let observationToUpdateDeepCopy: ZipModuleCommonDtosObservationDto = JSON.parse(
    JSON.stringify(observationToUpdate)
  )

  // find terminology for observation we're submitting
  const observationTerminology = findObservationTerminology(sections, form)
  // group form by level 1 for data filling
  const groupedLvl1s = groupFormByLvl1(form)

  const mappedComponents: ZipModuleCommonDtosComponentDto[] = []

  if (observationTerminology?.isUnique) {
    observationToUpdateDeepCopy = handleUniqueObservation(
      groupedLvl1s,
      mappedComponents,
      observationToUpdate,
      observationTerminology,
      sections
    )
  } else {
    observationToUpdateDeepCopy = handleNonUniqueObservation(
      groupedLvl1s,
      mappedComponents,
      observationToUpdate,
      editingFindingId,
      observationTerminology,
      sections
    )
  }
  observationToUpdate = observationToUpdateDeepCopy

  // update observatioToUpdate components
  observationToUpdate = updateObservationWithComponents(
    observationToUpdate,
    mappedComponents,
    observationTerminology
  )

  // creating deep copy of all observations and updating observation we want to submit
  const deepObservations = updateObservationsArray(observations, observationToUpdate)

  data.observations = deepObservations
  return data.observations
}
