import { CustomDescendant, SlateBadgeElement, SlateText } from '@novax/zip-frontend-library'
import { ITermTreeNode } from 'features/reportDetails/interfaces/IReportDetails'
import i18n from 'i18n'
import { ZipModuleTerminologyCommonTerminologyNodeDto } from 'services/zipmodule.gen'

import {
  calculateCompositeScores,
  calculateScores,
  ICalculatedScoresForScoreDefinition,
} from './scoreCalculation'

/**
 * @param sentence string with special characters pointing different text styles
 *
 * @returns Array of Slate.js text objects with appropriate style attributes
 */
const parseSentenceToSlateText = (sentence: string): SlateText[] => {
  // split by <b> to get sections that need to be in bold
  return sentence.split('<b>').map((str, i) => ({
    text: str,
    bold: i % 2 === 1,
  }))
}

/**
 * Interpolates sentences with values from calculated scoring.
 *
 * Supported values: ${totalScore}, ${scores}, ${sentences}
 *
 * Supported operators:  separator(STRING)
 *
 * @example
 * "Total score ${totalScore} was calculated from ${scores:separator(+)}." -> "Total score 6 was calculated from 1+2+3."
 *
 * @param sentence string with special characters pointing different values to interpolate
 * @param scoreObject object that holds calculated score and nodes used for calculation
 *
 * @returns string with interpolated values
 */
const interpolateSentenceWithValues = (
  sentence: string,
  scoreObject: { score: number; valueNodes: ITermTreeNode[]; scoringId: string }
): string => {
  let interpolatedSentence = ''

  // interpolate values to sentence
  let interpolationEndIndex = -1
  let interpolationStartIndex = sentence.indexOf('${')
  while (interpolationStartIndex !== -1) {
    // add previous part of sentence
    interpolatedSentence += sentence.substring(interpolationEndIndex + 1, interpolationStartIndex)
    // calculate new end index
    interpolationEndIndex = sentence.indexOf('}', interpolationStartIndex)
    // if end not found -> error
    if (interpolationEndIndex === -1) {
      console.error(
        `[generateSlateTextFromScoringSentence()] PARSE ERROR! Sentence invalid: '${sentence}'`
      )
      return sentence
    }

    const interpolationToken = sentence
      .substring(interpolationStartIndex + 2, interpolationEndIndex)
      .split(':')
    // compute values for interpolation
    const valueIdentificator = interpolationToken.at(0)
    const values: string[] = []
    switch (valueIdentificator) {
      case 'totalScore':
        values.push(scoreObject.score.toString())
        break
      case 'scores':
        values.push(
          ...scoreObject.valueNodes
            .map(
              (v) =>
                v.scoring?.find((scoreDefinition) => scoreDefinition.id === scoreObject.scoringId)
                  ?.score
            )
            .map((s) => s?.toString() ?? 'undefined')
        )
        break
      case 'sentences':
        values.push(...scoreObject.valueNodes.map((v) => v.sentence ?? v.value))
        break
      default:
        values.push('UNKNOWN VALUE: ' + valueIdentificator)
        break
    }

    // extract operator if present
    const operatorTokens = interpolationToken.at(1)?.split('(')
    const operator = operatorTokens?.at(0)
    const operatorValue = operatorTokens?.at(1)?.slice(0, -1)
    switch (operator) {
      case 'separator':
        interpolatedSentence += values.join(operatorValue ?? ', ')
        break
      default:
        interpolatedSentence += values.join(', ')
        break
    }

    // find next interpolation start
    interpolationStartIndex = sentence.indexOf('${', interpolationEndIndex)
  }
  // add any string after the last interpolation, if it exists
  if (interpolationEndIndex + 1 < sentence.length)
    interpolatedSentence += sentence.substring(interpolationEndIndex + 1)

  return interpolatedSentence
}

/**
 * Generates Slate.js text nodes for every score from the given object. Scores should already be calculated.
 *
 * Results are saved in the same object
 *
 * @param scores object containing already calculated scores
 */
const generateSlateTextNodesFromCalculatedScores = (
  calculatedScores: ICalculatedScoresForScoreDefinition
): void => {
  // find sentence for score and generate SlateText objects
  Object.keys(calculatedScores).forEach((scoreId) => {
    const scoreCalculationResult = calculatedScores[scoreId]
    let definedSentenceFound = false
    // iterate through defined sentences
    scoreCalculationResult.definedSentences.forEach((sentenceDefinition) => {
      if (
        !definedSentenceFound &&
        sentenceDefinition.sentence &&
        // this needs to be specific because 0 is false and we don't want that
        sentenceDefinition.from !== null &&
        sentenceDefinition.from !== undefined &&
        sentenceDefinition.to !== null &&
        sentenceDefinition.to !== undefined &&
        scoreCalculationResult.score >= sentenceDefinition.from &&
        scoreCalculationResult.score <= sentenceDefinition.to
      ) {
        scoreCalculationResult.textNodes = parseSentenceToSlateText(
          interpolateSentenceWithValues(sentenceDefinition.sentence, {
            ...scoreCalculationResult,
            scoringId: scoreId,
          })
        )
        definedSentenceFound = true
      }
    })

    // sentence not found -> insert default sentence
    if (!definedSentenceFound && scoreCalculationResult.defaultSentence)
      scoreCalculationResult.textNodes = parseSentenceToSlateText(
        interpolateSentenceWithValues(scoreCalculationResult.defaultSentence, {
          ...scoreCalculationResult,
          scoringId: scoreId,
        })
      )
  })
}

/**
 * Used to merge text values when node is 'Multiselect'. Result is pushed in existingBadge
 *
 * @param badge Slate.js badge element with new value
 * @param existingBadge Slate.js badge element with existing value
 */
const mergeBadgeToExistingBadge = (
  badge: SlateBadgeElement,
  existingBadge: SlateBadgeElement,
  separator?: string
) => {
  // replace 'and' with ',' if needed
  if (
    existingBadge.children.length > 1 &&
    existingBadge.children.at(-2)?.text === i18n.t('common.and')
  )
    existingBadge.children[existingBadge.children.length - 2] = {
      text: separator ?? ', ',
    }
  existingBadge.children.push({ text: i18n.t('common.and') })
  existingBadge.children.push(badge.children[0])
}

interface generateSlateBadgesFromIdValuePairsParams {
  idValuePairs: string[]
  rootNode: ITermTreeNode
  valueBadge?: SlateBadgeElement
  generationStrategy?: 'values' | 'sentences'
  excludeLevel1?: boolean
  tkeysToExclude?: string[]
  boldLevel1?: boolean
}
interface generateSlateBadgesFromIdValuePairsReturnItem {
  paragraphId: string
  badges: SlateBadgeElement[]
}

/**
 * Generates Slate.js badge objects from terminology and selected values
 *
 * @param idValuePairs array of strings defining selected user input/values. Also known as "DropdownIds"
 * @param rootNode Terminology node that is used as Observation. Also known as "workflow_step"
 * @param valueBadge Slate.js badge object that gets appended in a first returning object
 * @param generationStrategy strategy what to use when generating the text for badges.
 *                           'values' uses value field from terminology, 'sentences' uses sentence field.
 *                            Default is 'sentences' and value field is a fallback if sentence is not defined
 * @param excludeLevel1 If true, excludes level1 node from generated result
 * @param tkeysToExclude List of tkeys that will be excluded from generated result
 * @param boldLevel1 If true, bolds level1 text node in generated result
 *
 * @returns list of objects. Every object represents a paragraph. It holds paragraphId and list of badges for that paragraph.
 */
export const generateSlateBadgesFromIdValuePairs = (
  params: generateSlateBadgesFromIdValuePairsParams
): generateSlateBadgesFromIdValuePairsReturnItem[] => {
  const {
    idValuePairs,
    rootNode,
    valueBadge,
    generationStrategy = 'sentences',
    excludeLevel1,
    tkeysToExclude,
    boldLevel1,
  } = params
  const output: generateSlateBadgesFromIdValuePairsReturnItem[] = []

  let level1Id = idValuePairs.at(0)
  // remove id addition if present
  const separatorIndex = level1Id?.lastIndexOf(',')
  level1Id = level1Id?.substring(0, separatorIndex)

  const level1Node = rootNode.children.find((node) => node.id === level1Id)
  if (!(level1Node?.sequenceFromRoot && level1Id) || separatorIndex === -1) return output

  const paragraph1Badges: SlateBadgeElement[] = []
  output.push({
    paragraphId: idValuePairs[0] + '-paragraph-root',
    badges: paragraph1Badges,
  })

  // calculate scoring if defined
  const scoringIdsForNode: { [sequenceFromParent: string]: string[] } = {}
  let scoringsResults: ICalculatedScoresForScoreDefinition = {}
  if (level1Node.scoring && level1Node.scoring.length !== 0) {
    scoringIdsForNode[level1Node.sequenceFromRoot] = level1Node.scoring
      .filter((s) => !!s.id)
      .map((s) => s.id as string)
    const calculatedScores = calculateScores(level1Node, idValuePairs)
    scoringsResults = { ...scoringsResults, ...calculatedScores }
  }
  // Add level1 label if not replaced by scoring
  const level1ScoringIsReplacingValues =
    Object.hasOwn(scoringIdsForNode, level1Node.sequenceFromRoot) &&
    scoringIdsForNode[level1Node.sequenceFromRoot].some(
      (s) => scoringsResults[s]?.showInsteadSelectedValues
    )
  // we don't want to generate sentence for alwaysSelected = true but only if that is only child
  const skipBadgeCreation =
    level1ScoringIsReplacingValues ||
    (level1Node.alwaysSelected && rootNode.children.length === 1) ||
    excludeLevel1

  if (!skipBadgeCreation)
    paragraph1Badges.push({
      id: level1Node.sequenceFromRoot,
      type: 'badge',
      children: [
        {
          bold:
            rootNode.tKey?.toLowerCase() === 'findings' || level1Node.alwaysSelected || boldLevel1,
          id: `${level1Node.sequenceFromRoot}-text`,
          text:
            generationStrategy === 'sentences' && level1Node.sentence
              ? level1Node.sentence
              : level1Node.value,
        },
      ],
    })
  const deeperParagraphs: {
    [level3NodeSequenceFromRoot: string]: SlateBadgeElement[]
  } = {}

  // Add level 3 and level 5 nodes that are selected
  idValuePairs
    // filter out unselected records (values are always level 3 & 5, while 2 & 4 are just labels)
    .filter((pairs) => pairs.split(',').length % 2 === 1)
    .forEach((values) => {
      let selectedIds = values.split(',').slice(1)
      let labelNode: ITermTreeNode | undefined = undefined
      let valueNode: ITermTreeNode | undefined = level1Node
      let scoringDefinitionNode: ITermTreeNode | undefined = undefined
      if (
        valueNode?.children.find((c) => c.id == selectedIds[0])?.tKey == 'container_number' &&
        selectedIds.length > 2
      ) {
        selectedIds = selectedIds.slice(0, 2)
      }
      // get the deepest label-value pair
      const depth = selectedIds.length / 2
      for (let i = 0; i < depth; i++) {
        labelNode = valueNode?.children.find((n) => n.id === selectedIds[i * 2])
        valueNode = labelNode?.children.find((n) => n.id === selectedIds[i * 2 + 1])
        // level1 is already calculated -> exclude it
        scoringDefinitionNode =
          !scoringDefinitionNode &&
          valueNode?.sequenceFromRoot !== level1Node.sequenceFromRoot &&
          valueNode?.scoring &&
          valueNode.scoring.length > 0
            ? valueNode
            : scoringDefinitionNode

        // skip this selection if in hidden list
        if (labelNode?.tKey && tkeysToExclude?.includes(labelNode.tKey)) return
      }
      if (labelNode?.sequenceFromRoot && valueNode?.sequenceFromRoot) {
        const generatedBadge: SlateBadgeElement = {
          id: labelNode.sequenceFromRoot,
          label: labelNode.value,
          type: 'badge',
          children: [
            {
              id: valueNode.sequenceFromRoot,
              text:
                valueNode.inputType !== 'MultiSelect' &&
                generationStrategy === 'sentences' &&
                valueNode.sentence
                  ? valueNode.sentence
                  : valueNode.value,
            },
          ],
        }
        // STAFF CUSTOM LOGIC
        if (level1Node.tKey === 'staff_members') {
          // custom separator ;
          generatedBadge.separator = { text: '; ' }
        }

        let deeperNodeScoringIsReplacingValues = false
        // if scoring defined
        if (scoringDefinitionNode?.sequenceFromRoot && scoringDefinitionNode.scoring) {
          // add scoring ids for that node sequence
          scoringIdsForNode[scoringDefinitionNode.sequenceFromRoot] = scoringDefinitionNode.scoring
            .filter((s) => !!s.id)
            .map((s) => s.id as string)
          //if some score isn't already calculated, calculate it
          if (
            scoringDefinitionNode.scoring.some((s) => s.id && !Object.hasOwn(scoringsResults, s.id))
          ) {
            // calculate scoring and save
            const calculatedScores = calculateScores(scoringDefinitionNode, idValuePairs)
            scoringsResults = { ...scoringsResults, ...calculatedScores }
          }
          // change flag if any scoring replaces values
          deeperNodeScoringIsReplacingValues = scoringIdsForNode[
            scoringDefinitionNode.sequenceFromRoot
          ].some((s) => scoringsResults[s]?.showInsteadSelectedValues)
        }

        //push level3 to first paragraph except if it has children (level4 & level5)
        if (selectedIds.length === 2 && valueNode.children.length === 0) {
          const existingBadge = paragraph1Badges.find((badge) => badge.id === generatedBadge.id)

          if (existingBadge) {
            mergeBadgeToExistingBadge(generatedBadge, existingBadge)
          } else {
            // add separator if level1 was always selected and already in the paragraph
            if (
              level1Node.alwaysSelected &&
              paragraph1Badges.length === 1 &&
              paragraph1Badges.find((b) => b.id === level1Node.sequenceFromRoot)
            ) {
              generatedBadge.separator = { text: ' - ' }
            }

            // STAFF CUSTOM LOGIC
            // add bolded label text on level 3 nodes
            if (level1Node.tKey === 'staff_members') {
              generatedBadge.children.splice(0, 0, { bold: true, text: labelNode.value + ': ' })
            }

            // push to its paragraph if not replaced with scoring
            !deeperNodeScoringIsReplacingValues && paragraph1Badges.push(generatedBadge)
          }
        } else {
          //create new paragraph if there's no paragraph for this level3
          const level3Sequence = valueNode.sequenceFromRoot.split(',').slice(0, 4).join(',')
          if (!deeperParagraphs[level3Sequence]) {
            deeperParagraphs[level3Sequence] = [
              {
                id: `${level3Sequence}-details`,
                type: 'badge',
                children: [
                  {
                    bold: true,
                    id: `${level3Sequence}-details-text`,
                    text: labelNode.value,
                  },
                ],
              },
            ]
          }

          const existingBadge = deeperParagraphs[level3Sequence].find(
            (badge) => badge.id === generatedBadge.id
          )
          // if a badge can contain multiple items, add them to the children and add conjunctions
          if (existingBadge) {
            mergeBadgeToExistingBadge(generatedBadge, existingBadge)
          } else {
            // push to its paragraph if not replaced by scoring
            // we also don't want to generate sentence for alwaysSelected = true but only if that is only child
            const skipBadgeGeneration =
              deeperNodeScoringIsReplacingValues ||
              (valueNode.alwaysSelected && labelNode.children.length === 1)

            // STAFF CUSTOM LOGIC
            // add bolded label text except for level 3 with children
            if (
              level1Node.tKey === 'staff_members' &&
              labelNode.sequenceFromRoot.split(',').length > 3
            ) {
              generatedBadge.children.splice(0, 0, { bold: true, text: labelNode.value + ': ' })
            }

            !skipBadgeGeneration && deeperParagraphs[level3Sequence].push(generatedBadge)
          }
        }
      }
    })

  // calculate composite scorings
  calculateCompositeScores(scoringsResults)

  // generate slate text for all calculated scorings
  generateSlateTextNodesFromCalculatedScores(scoringsResults)

  //add scoring to paragraph1 if present
  if (Object.hasOwn(scoringIdsForNode, level1Node.sequenceFromRoot)) {
    const scoringsToInsert = scoringIdsForNode[level1Node.sequenceFromRoot]
    paragraph1Badges.push(
      ...scoringsToInsert
        // filter out empty text node arrays
        .filter((s) => (scoringsResults[s].textNodes?.length ?? 0) > 0)
        .map(
          (s) =>
            ({
              id: `scoring-badge-${s}`,
              type: 'badge',
              children: scoringsResults[s].textNodes,
            } as SlateBadgeElement)
        )
    )
  }

  //add all deeper paragraphs to output
  Object.entries(deeperParagraphs).forEach(([level3SequenceFromRoot, paragraphBadges]) => {
    // add custom separator only on second badge
    const secondBadge = paragraphBadges.at(1)
    if (secondBadge) secondBadge.separator = { text: ' - ' }

    // STAFF CUSTOM LOGIC
    // reset separator on third badge (from ; to ,)
    if (level1Node.tKey === 'staff_members') {
      const thirdBadge = paragraphBadges.at(2)
      if (thirdBadge) thirdBadge.separator = undefined
    }

    const paragraphToAdd: generateSlateBadgesFromIdValuePairsReturnItem = {
      paragraphId: `${idValuePairs[0]}-paragraph-${paragraphBadges[0].id}`,
      badges: paragraphBadges,
    }
    //add deeper scorings if present
    if (Object.hasOwn(scoringIdsForNode, level3SequenceFromRoot)) {
      const scoringsToInsert = scoringIdsForNode[level3SequenceFromRoot]
      paragraphToAdd.badges.push(
        ...scoringsToInsert
          // filter out empty text node arrays
          .filter((s) => (scoringsResults[s].textNodes?.length ?? 0) > 0)
          .map(
            (s) =>
              ({
                id: `scoring-badge-${s}`,
                type: 'badge',
                children: scoringsResults[s].textNodes,
                // add dash if secondBadge does not exist
                separator: !secondBadge ? { text: ' - ' } : undefined,
              } as SlateBadgeElement)
          )
      )
    }
    output.push(paragraphToAdd)
  })

  // add value badge to the end of first paragraph
  valueBadge && output.at(0)?.badges.push(valueBadge)
  return output
}

interface generateParagraphFromBadgesParams {
  badges: SlateBadgeElement[]
  separator?: SlateText | string
  wrapInListItem?: boolean
}

/**
 * Generates Slate.js paragraph object from provided badges adding a separator text between them
 *
 * @param badges Slate.js badge objects that are inserted in the paragraph
 * @param separator Custom separator to insert between the badges. Default is ', '
 * @param wrapInListItem Wrap the resulting paragraph object in the Slate.js list-item object
 *
 * @returns Populated Slate.js Paragraph or List-Item object (depending on wrapInListItem prop)
 */
export const generateParagraphFromBadges = (
  params: generateParagraphFromBadgesParams
): CustomDescendant => {
  const { badges, separator, wrapInListItem = false } = params
  let computedSeparator = separator
  if (!separator) {
    computedSeparator = { text: ', ' }
  } else if (typeof separator === 'string') {
    computedSeparator = { text: separator }
  }

  const paragraph: CustomDescendant = {
    type: 'paragraph',
    children: badges.flatMap((b, i) =>
      i !== badges.length - 1 ? [b, computedSeparator as SlateText] : [b]
    ),
  }

  if (wrapInListItem) return { type: 'list-item', children: [paragraph] }
  else return paragraph
}

export const getLevel1Value = (
  workflowStep: ZipModuleTerminologyCommonTerminologyNodeDto | undefined,
  dropdownIds: string[]
) => {
  const level1Id = dropdownIds[0].split(',')[0]
  const level1: ZipModuleTerminologyCommonTerminologyNodeDto | undefined =
    workflowStep?.children?.find((el) => el.id == level1Id)
  return level1?.value
}

export const generateImageTag = (
  componentIndex: string,
  componentId: string,
  level1: ITermTreeNode | undefined
) => {
  let tag = ''
  if (level1?.tKey?.toLowerCase() == 'extent_of_examination') {
    tag = 'EoE'
  } else if (level1?.tKey?.toLowerCase() == 'preparation') {
    tag = 'BBPS'
  } else if (level1?.tKey?.toLowerCase() == 'withdrawal') {
    tag = 'R'
  } else {
    let level2Name = level1?.children.find((el) => el.id == componentId)?.value ?? ''
    level2Name = level2Name.replace(/\([^)]*\)/g, '')?.trim()
    level2Name = level2Name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('')
    tag = level2Name + componentIndex
    tag = tag.replace(/\s+/g, '')
  }
  return tag
}
