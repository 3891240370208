import { SlateBadgeElement, SlateText, SlateToHtml } from '@novax/zip-frontend-library'
import { generateDropdownIdsFromTerminologyTree } from 'features/reportDetails/subcomponents/terminologyRow/utils/terminologyRowUtils'

import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from '../../../../../../services/zipmodule.gen'
import { generateSlateBadgesFromIdValuePairs } from '../../../../../../utils/sentenceGeneration'
import { ITermTreeNode } from '../../../../../reportDetails/interfaces/IReportDetails'
import { tKeysMatch } from '../../../../utils/URLhelper'

interface ISentenceParagraph {
  workflowStep?: ZipModuleTerminologyCommonTerminologyNodeDto
  dropdownIds?: string[]
  observation?: ZipModuleCommonDtosObservationDto
}

const SentenceParagraphs = ({ workflowStep, dropdownIds, observation }: ISentenceParagraph) => {
  const orderComponents = (
    rootNode: ITermTreeNode,
    components: ZipModuleCommonDtosComponentDto[]
  ) => {
    if (components.length == 1) {
      return components
    }
    const sortedComponents: ZipModuleCommonDtosComponentDto[] = []
    rootNode.children.forEach((child) => {
      const foundComponents = components.filter(
        (c) => c.dropdownIds && c.dropdownIds[0].split(',')[0] == child.id
      )
      if (foundComponents) {
        sortedComponents.push(...foundComponents)
      }
    })
    return sortedComponents ?? components
  }
  // reusing slate badge generation from report details
  const generateSentences = () => {
    if (observation && observation.components && workflowStep) {
      let components = observation.components

      // if workflow step is not unique we are generating sentence only for the single component
      if (!workflowStep.isUnique) {
        components = components.filter(
          (c) => c.dropdownIds && dropdownIds && c.dropdownIds[0] === dropdownIds[0]
        )
      }
      const sortedComponents = orderComponents(workflowStep as ITermTreeNode, components)
      return sortedComponents.map((component) => {
        // reorder dropdownIds after editing
        const dropdownIdsReordered = generateDropdownIdsFromTerminologyTree({
          rootNode: workflowStep as ITermTreeNode,
          selectedDropdownIds: component.dropdownIds ?? [],
        })
        // special case for medication -> we create a badge element for the amount
        const valueBadge: SlateBadgeElement | undefined =
          tKeysMatch(observation.tKey, 'medication') &&
          component.text &&
          dropdownIdsReordered?.dropdownIds &&
          dropdownIdsReordered?.dropdownIds[0]
            ? {
                id:
                  dropdownIdsReordered?.dropdownIds &&
                  dropdownIdsReordered?.dropdownIds[0] + '-text',
                label: 'value',
                type: 'badge',
                children: [{ text: `${component.text} mg` }],
              }
            : undefined

        return (
          dropdownIdsReordered.dropdownIds &&
          generateSlateBadgesFromIdValuePairs({
            idValuePairs: dropdownIdsReordered.dropdownIds,
            rootNode: workflowStep as ITermTreeNode,
            valueBadge,
            generationStrategy: 'values',
            excludeLevel1: workflowStep.renderWorkflowChildren,
            tkeysToExclude: ['container_number'],
          })
        )
      })
    } else {
      return []
    }
  }

  const sentences = generateSentences()

  // add separators to slate badges
  const fillParagraphBadgeListWithSeparators = (
    badges: SlateBadgeElement[]
  ): (SlateBadgeElement | SlateText)[] => {
    const paragraphBadgesFilledList: (SlateBadgeElement | SlateText)[] = []

    if (badges?.length > 0) {
      badges.map((badge, index) => {
        if (index === 0) {
          paragraphBadgesFilledList.push({ text: '' } as SlateText)
        } else if (badge.separator) {
          paragraphBadgesFilledList.push({
            text: badge.separator.text,
            bold: badge.separator.bold,
          } as SlateText)
        } else {
          paragraphBadgesFilledList.push({
            text: ', ',
          } as SlateText)
        }
        paragraphBadgesFilledList.push(badge)
      })
    }

    return paragraphBadgesFilledList
  }

  return (
    <>
      {sentences &&
        sentences.map((sentence, index) => (
          <div
            key={`${dropdownIds && dropdownIds[0]}, ${index}`}
            id={`${dropdownIds && dropdownIds[0]}, ${index}`}
          >
            {/*sentence section */}
            {sentence &&
              sentence.map((paragraph) => (
                <div key={paragraph.paragraphId} id={paragraph.paragraphId}>
                  {paragraph.badges && (
                    <div>
                      {/*sentence row */}
                      <SlateToHtml
                        slateList={fillParagraphBadgeListWithSeparators(paragraph.badges)}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
    </>
  )
}

export default SentenceParagraphs
