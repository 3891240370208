import { NotificationType, notify } from '@novax/zip-frontend-library'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

const OfflineContext = () => {
  const [online, setOnline] = useState(navigator.onLine)
  const [wasOffline, setWasOffline] = useState(false)

  const setToOnline = () => {
    setOnline(true)
  }

  const setToOffline = () => {
    setOnline(false)
  }

  useEffect(() => {
    if (!online) {
      setWasOffline(true)
      notify({
        type: NotificationType.INFO,
        message: t('common.youAreOffline'),
        description: t('common.youAreOfflineText'),
      })
    } else if (wasOffline) {
      notify({
        type: NotificationType.INFO,
        message: t('common.youAreOnline'),
        description: t('common.youAreOnlineText'),
      })
    }
  }, [online])

  useEffect(() => {
    window.addEventListener('online', () => setToOnline())
    window.addEventListener('offline', () => setToOffline())

    return () => {
      window.removeEventListener('online', () => setToOnline)
      window.removeEventListener('offline', () => setToOffline)
    }
  }, [])

  return <></>
}
export default OfflineContext
