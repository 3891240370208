import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { isEmpty, isEqual } from 'lodash'

import {
  ZipModuleCommonDtosComponentDto,
  ZipModuleCommonDtosObservationDto,
  ZipModuleCommonEnumsProcedureStatus,
  ZipModuleFeaturesDataCaptureResponsesBasicInformationDto,
  ZipModuleFeaturesDataCaptureResponsesDataCaptureDto,
  ZipModuleFeaturesDataCaptureResponsesSectionDto,
  ZipModuleTerminologyCommonTerminologyNodeDto,
} from '../../services/zipmodule.gen'
import { CombinedStatus, InternalStatus } from '../../types'
import {
  isProcedureActiveDocumentationFinished,
  isProcedureFinishedDocumentationActive,
  isProcedureFinishedDocumentationFinished,
} from '../../utils/procedureStatusResolver'
import { NotificationTypes } from './enum/notificationTypes'
import { IFindingForm } from './interfaces/IPathology'
import { findBiggestIndex } from './utils/dataCaptureMapperHelper'
import { normalizeTKeyParams } from './utils/URLhelper'

export type TSection = ZipModuleFeaturesDataCaptureResponsesSectionDto & {
  label?: string
  key?: string
}

export const initialState = {
  basicInformation: {} as ZipModuleFeaturesDataCaptureResponsesBasicInformationDto,
  observations: [] as ZipModuleCommonDtosObservationDto[],
  tempObservations: [] as ZipModuleCommonDtosObservationDto[],
  procedureStatus: {} as ZipModuleCommonEnumsProcedureStatus | null,
  sections: [] as TSection[],
  combinedSections: [] as TSection[],
  stopWatchTime: 0,
  isStopWatchRunning: false,
  showContainersOnly: false,
  lastCardDeleted: false,
  isContainerToggleDisabled: false,
  findingForm: {
    form: {} as IFindingForm,
    previousForm: {} as IFindingForm,
    errors: [] as string[],
  },
  initialFindingForm: {
    form: {} as IFindingForm,
    errors: [] as string[],
  },
  editingFindingId: '' as string,
  deletingFindingId: null as string | null,
  findingToHighlight: { id: null, newlyCreated: false } as {
    id: string | null
    newlyCreated: boolean
  },
  isSubmitLoading: false,
  isFindingFormDirty: false,
  shouldFetchData: true,
  findingToDuplicateId: null as string | null,
  discardModalVariant: 'create' as 'create' | 'edit' | 'duplicate',
  readyToDelete: null as 'overview' | 'create-edit' | null,
  isProcedureDocumentationFinished: false,
  tabChanged: { current: null as string | null, previous: null as string | null },
  displayNotification: null as string | null,
  displayedNotifications: [] as string[],
  disableUPLButton: false,
}

export const dataCaptureSlice = createSlice({
  name: 'data-capture',
  initialState,
  reducers: {
    setDataCapture: (
      state,
      action: PayloadAction<ZipModuleFeaturesDataCaptureResponsesDataCaptureDto>
    ) => {
      if (action.payload) {
        state.findingForm = { form: {}, previousForm: {}, errors: [] }
        state.basicInformation = action.payload.basicInformation ?? {}
        state.procedureStatus = action.payload.basicInformation?.procedureStatus ?? null
        state.observations = action.payload.observations ?? []
        state.sections = action.payload.sections ?? []
        state.readyToDelete = null
        state.isProcedureDocumentationFinished = false
        state.displayNotification = null

        // set is procedure documentation finished
        if (
          isProcedureFinishedDocumentationFinished(
            state.basicInformation?.procedureStatus,
            state.basicInformation?.procedureInternalStatus
          )
        ) {
          state.isProcedureDocumentationFinished = true
          if (
            !state.displayedNotifications.includes(
              NotificationTypes.procedureFinishedDocumentationFinished
            )
          ) {
            state.displayNotification = NotificationTypes.procedureFinishedDocumentationFinished
            state.displayedNotifications = [
              ...state.displayedNotifications,
              NotificationTypes.procedureFinishedDocumentationFinished,
            ]
          }
        } else if (
          isProcedureActiveDocumentationFinished(
            state.basicInformation?.procedureStatus,
            state.basicInformation?.procedureInternalStatus
          )
        ) {
          state.isProcedureDocumentationFinished = true
          if (
            !state.displayedNotifications.includes(
              NotificationTypes.procedureActiveDocumentationFinished
            )
          ) {
            state.displayNotification = NotificationTypes.procedureActiveDocumentationFinished
            state.displayedNotifications = [
              ...state.displayedNotifications,
              NotificationTypes.procedureActiveDocumentationFinished,
            ]
          }
        } else if (
          isProcedureFinishedDocumentationActive(
            state.basicInformation?.procedureStatus,
            state.basicInformation?.procedureInternalStatus
          )
        ) {
          state.disableUPLButton = true
        }

        // set stopwatch
        if (state.isProcedureDocumentationFinished) {
          state.stopWatchTime = action.payload.basicInformation?.reportWritingTime ?? 0
        } else {
          if (
            !localStorage.getItem(
              action.payload.basicInformation?.procedureNumber + '-stopwatchStartTime'
            )
          ) {
            localStorage.setItem(
              action.payload.basicInformation?.procedureNumber + '-stopwatchStartTime',
              Date.now().toString()
            )
          }
          state.isStopWatchRunning = true
        }

        // map through sections and add key and label for SectionMenu component needs
        state.combinedSections = state.sections.map((section) => {
          return {
            ...section,
            key: section.tKey ?? '',
            label: section.sectionName ?? '',
          }
        })

        //add default "All" section to start
        state.combinedSections.unshift({
          tKey: 'all',
          key: 'all',
          sectionName: 'All',
          label: 'All',
          terminology: state.sections.flatMap((section) => section.terminology ?? []),
        })
      }
    },
    setInitialDataCapture: () => {
      return initialState
    },
    setObservations: (state, action: PayloadAction<ZipModuleCommonDtosObservationDto[]>) => {
      state.observations = action.payload
    },
    setIsStopWatchRunning: (state, action) => {
      state.isStopWatchRunning = action.payload
    },
    setShowContainersOnly: (state, action: PayloadAction<boolean>) => {
      state.showContainersOnly = action.payload
    },
    setLastCardDeleted: (state, action: PayloadAction<boolean>) => {
      state.lastCardDeleted = action.payload
    },

    setIsContainerToggleDisabled: (state, action: PayloadAction<boolean>) => {
      state.isContainerToggleDisabled = action.payload
    },
    setFindingForm: (state, action: PayloadAction<{ form: IFindingForm; errors: string[] }>) => {
      const oldform = JSON.parse(JSON.stringify(state.findingForm.form))
      if (!isEqual(oldform, JSON.parse(JSON.stringify(state.findingForm.previousForm)))) {
        state.findingForm.previousForm = oldform
      }
      state.findingForm.form = action.payload.form
      state.findingForm.errors = action.payload.errors
      if (isEmpty(action.payload?.form)) {
        localStorage.removeItem(window.location.pathname)
      } else {
        // we save current work locally (if token expires and user gets logged out)
        localStorage.setItem(window.location.pathname, JSON.stringify(action.payload))
      }
    },
    setEditingFindingId: (state, action: PayloadAction<string>) => {
      state.editingFindingId = action.payload
    },
    setInitialFindingForm: (
      state,
      action: PayloadAction<{ form: IFindingForm; errors: string[] }>
    ) => {
      state.initialFindingForm = action.payload
    },
    setFindingToHighlight: (
      state,
      action: PayloadAction<{ id: string | null; newlyCreated: boolean }>
    ) => {
      state.findingToHighlight = action.payload
    },
    setReadyToDelete: (state, action: PayloadAction<'overview' | 'create-edit' | null>) => {
      state.readyToDelete = action.payload
    },
    deleteFinding: (
      state,
      action: PayloadAction<{
        observation: ZipModuleTerminologyCommonTerminologyNodeDto | undefined | null
        dropdownIds: string[] | undefined
        source: 'overview' | 'create-edit'
      }>
    ) => {
      // if observation is unique with we empty the components array
      state.tempObservations = JSON.parse(JSON.stringify(state.observations))
      if (action.payload.observation?.isUnique) {
        const observationToChange = state.tempObservations.find(
          (obs) =>
            normalizeTKeyParams(obs.tKey) === normalizeTKeyParams(action.payload.observation?.tKey)
        )

        if (observationToChange) {
          observationToChange.components = []
        }
      }
      // otherwise we delete only one child -> pop that dropdown and refresh index values
      else {
        // find observation to change
        const observationToChange = state.tempObservations.find(
          (obs) =>
            normalizeTKeyParams(obs.tKey) === normalizeTKeyParams(action.payload.observation?.tKey)
        )

        if (observationToChange) {
          const resultComponentsArray: ZipModuleCommonDtosComponentDto[] = []
          observationToChange?.components?.forEach(
            (component: ZipModuleCommonDtosComponentDto): void => {
              if (
                action.payload.dropdownIds &&
                component.dropdownIds &&
                [...component.dropdownIds][0] !== action.payload.dropdownIds[0]
              ) {
                resultComponentsArray.push(component)
              }
            }
          )
          observationToChange.components = resultComponentsArray
        }
      }

      state.readyToDelete = action.payload.source
    },
    duplicateFinding: (
      state,
      action: PayloadAction<{
        observation: ZipModuleTerminologyCommonTerminologyNodeDto | undefined
        dropdownIds: string[] | undefined
      }>
    ) => {
      // find observation to change
      const observationToChange = state.observations.find(
        (obs) =>
          normalizeTKeyParams(obs.tKey) === normalizeTKeyParams(action.payload.observation?.tKey)
      )

      // find component to duplicate inside of observation to change
      const componentToDuplicate: ZipModuleCommonDtosComponentDto = JSON.parse(
        JSON.stringify(
          observationToChange?.components?.find((comp) =>
            isEqual(comp.dropdownIds, action.payload.dropdownIds)
          )
        )
      )

      // create `level1,index` ids with next biggest index
      const level1Index = `${
        componentToDuplicate?.dropdownIds && componentToDuplicate?.dropdownIds[0].split(',')[0]
      },${findBiggestIndex(observationToChange?.components as ZipModuleCommonDtosComponentDto[])}`

      // duplicate rest of ids from component to change
      const idsToDuplidate = componentToDuplicate?.dropdownIds?.splice(1) ?? []

      // update state with newly duplicated component
      observationToChange?.components?.unshift({
        dropdownIds: new Array(level1Index).concat(idsToDuplidate),
        text: componentToDuplicate?.text,
        images: componentToDuplicate?.images,
        isUpdated: componentToDuplicate?.isUpdated,
      })
      state.findingToHighlight = { id: level1Index, newlyCreated: true }
      state.findingToDuplicateId = level1Index
      state.shouldFetchData = false
    },
    setIsSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.isSubmitLoading = action.payload
    },
    setIsFindingFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isFindingFormDirty = action.payload
    },
    setShouldFetchData: (state, action: PayloadAction<boolean>) => {
      state.shouldFetchData = action.payload
    },
    setDeletingFindingId: (state, action: PayloadAction<string | null>) => {
      state.deletingFindingId = action.payload
    },

    setFindingDuplicateId: (state, action: PayloadAction<string | null>) => {
      state.findingToDuplicateId = action.payload
    },
    setDiscardModalVariant: (state, action: PayloadAction<'create' | 'edit' | 'duplicate'>) => {
      state.discardModalVariant = action.payload
    },
    setTabChanged: (
      state,
      action: PayloadAction<{ current: string | null; previous: string | null }>
    ) => {
      state.tabChanged = action.payload
    },
    setIsProcedureFinished: (state, action: PayloadAction<boolean>) => {
      state.isProcedureDocumentationFinished = action.payload
    },
    setProcedureStatus: (
      state,
      action: PayloadAction<{ status: CombinedStatus | null; internalStatus: InternalStatus }>
    ) => {
      const tempBasicInfo = JSON.parse(JSON.stringify(state.basicInformation))

      if (
        tempBasicInfo.procedureInternalStatus === InternalStatus.NurseStart &&
        action.payload.internalStatus === InternalStatus.DoctorStart
      ) {
        state.isProcedureDocumentationFinished = true
        state.displayNotification = NotificationTypes.procedureDoctorStarted
        state.displayedNotifications = [
          ...state.displayedNotifications,
          NotificationTypes.procedureDoctorStarted,
        ]
      } else if (
        tempBasicInfo.procedureInternalStatus === InternalStatus.NurseStart &&
        action.payload.internalStatus === InternalStatus.NurseEnd
      ) {
        state.isProcedureDocumentationFinished = true
      } else if (
        tempBasicInfo.procedureStatus === CombinedStatus.InProgress &&
        action.payload.status === CombinedStatus.Finished &&
        action.payload.internalStatus === InternalStatus.NurseStart
      ) {
        state.displayNotification = NotificationTypes.procedureFinishedDocumentationStarted
        state.displayedNotifications = [
          ...state.displayedNotifications,
          NotificationTypes.procedureFinishedDocumentationStarted,
        ]
        state.disableUPLButton = true
      } else if (
        tempBasicInfo.procedureStatus === CombinedStatus.InProgress &&
        action.payload.status === CombinedStatus.Finished &&
        action.payload.internalStatus !== InternalStatus.NurseStart &&
        action.payload.internalStatus !== InternalStatus.Created
      ) {
        state.displayNotification = NotificationTypes.procedureFinishedDocumentationFinished
        state.displayedNotifications = [
          ...state.displayedNotifications,
          NotificationTypes.procedureFinishedDocumentationFinished,
        ]
      }

      tempBasicInfo.procedureInternalStatus = action.payload.internalStatus
      if (action.payload.status) tempBasicInfo.procedureStatus = action.payload.status
      state.basicInformation = tempBasicInfo
    },
    setDisplayNotification: (state, action: PayloadAction<string | null>) => {
      state.displayNotification = action.payload
      if (action.payload) {
        state.displayedNotifications = [...state.displayedNotifications, action.payload]
      }
    },
  },
})

export const {
  setDataCapture,
  setShowContainersOnly,
  setLastCardDeleted,
  setIsContainerToggleDisabled,
  setFindingForm,
  setEditingFindingId,
  setFindingToHighlight,
  setObservations,
  deleteFinding,
  duplicateFinding,
  setIsSubmitLoading,
  setInitialFindingForm,
  setIsFindingFormDirty,
  setShouldFetchData,
  setDeletingFindingId,
  setFindingDuplicateId,
  setDiscardModalVariant,
  setIsStopWatchRunning,
  setInitialDataCapture,
  setReadyToDelete,
  setTabChanged,
  setIsProcedureFinished,
  setProcedureStatus,
  setDisplayNotification,
} = dataCaptureSlice.actions
export const getBasicInformation = (state: RootState) => state.dataCapture.basicInformation
export const getObservations = (state: RootState) => state.dataCapture.observations
export const getSections = (state: RootState) => state.dataCapture.sections
export const getCombinedSections = (state: RootState) => state.dataCapture.combinedSections
export const getStopWatchTime = (state: RootState) => state.dataCapture.stopWatchTime
export const getIsStopWatchRunning = (state: RootState) => state.dataCapture.isStopWatchRunning
export const getShowContainersOnly = (state: RootState) => state.dataCapture.showContainersOnly
export const getLastCardDeleted = (state: RootState) => state.dataCapture.lastCardDeleted
export const getIsContainerToggleDisabled = (state: RootState) =>
  state.dataCapture.isContainerToggleDisabled
export const getFindingForm = (state: RootState) => state.dataCapture.findingForm
export const getInitialFindingForm = (state: RootState) => state.dataCapture.initialFindingForm
export const getEditingFindingId = (state: RootState) => state.dataCapture.editingFindingId
export const getFindingToHighlight = (state: RootState) => state.dataCapture.findingToHighlight
export const getIsSubmitLoading = (state: RootState) => state.dataCapture.isSubmitLoading
export const getProcedureStatus = (state: RootState) => state.dataCapture.procedureStatus
export const getIsFindingFormDirty = (state: RootState) => state.dataCapture.isFindingFormDirty
export const getShouldFetchData = (state: RootState) => state.dataCapture.shouldFetchData
export const getDeletingFindingId = (state: RootState) => state.dataCapture.deletingFindingId
export const getFindingDuplicateId = (state: RootState) => state.dataCapture.findingToDuplicateId
export const getDiscardModalVariant = (state: RootState) => state.dataCapture.discardModalVariant
export const getIsProcedureFinished = (state: RootState) =>
  state.dataCapture.isProcedureDocumentationFinished
export const getReadyToDelete = (state: RootState) => state.dataCapture.readyToDelete
export const getTempObservations = (state: RootState) => state.dataCapture.tempObservations
export const getTabChanged = (state: RootState) => state.dataCapture.tabChanged
export const getDisplayNotification = (state: RootState) => state.dataCapture.displayNotification
export const getDisableUPLButton = (state: RootState) => state.dataCapture.disableUPLButton

export default dataCaptureSlice.reducer
