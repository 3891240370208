import { Spin } from 'antd'

import styles from './styles.module.scss'

interface LoadingScreenProps {
  label: string
}

const LoadingScreen = (props: LoadingScreenProps) => {
  return (
    <div className={styles.background} id="nova-report-Loading-Screen-root">
      <span className={styles.spinner}>
        <Spin data-testid="spinner" />
      </span>
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}

export default LoadingScreen
