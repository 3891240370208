import { useTranslation } from 'react-i18next'
import { ZipModuleFeaturesPathologyRequestsFindingDetailDto } from 'services/zipmodule.gen'

import { TSection } from '../dataCaptureSlice'
import { tKeysMatch } from './URLhelper'
import { determineBiopsyContainer } from './utils'

export const checkIfSectionIncludesBiopsyContainer = (
  observations: ZipModuleFeaturesPathologyRequestsFindingDetailDto[],
  section: TSection | undefined,
  translation: typeof useTranslation
) => {
  const containersFromAllObservations: boolean[] = []

  section?.terminology?.forEach((terminology) => {
    const obs = observations.find((o) => tKeysMatch(o.tKey, terminology.tKey))
    if (obs) {
      obs.components?.forEach((component) => {
        const containers = determineBiopsyContainer(
          terminology,
          component.dropdownIds ?? [],
          translation
        )
        containersFromAllObservations.push(containers && containers.length > 0 ? true : false)
      })
    }
  })

  return containersFromAllObservations
}
