import React from 'react'

import DataCaptureAddButton from '../dataCaptureAddButton/DataCaptureAddButton'
import DataCaptureHeader from '../dataCaptureHeader/DataCaptureHeader'
import styles from './styles.module.scss'

const DataCaptureWithHeader: React.FC<{
  children: React.ReactNode
  showAddButton: boolean
  isLoading: boolean
  endDocumentation: () => void
}> = ({ children, showAddButton, isLoading, endDocumentation }) => {
  return (
    <div className={styles.wrapper} id="nova-report-Data-Capture-With-Header-root">
      <DataCaptureHeader isLoading={isLoading} endDocumentation={endDocumentation} />
      <div className={styles.scrollContainer}>
        <div className={styles.container}>{children}</div>
      </div>
      {showAddButton && <DataCaptureAddButton align={'right'} isLoading={isLoading} />}
    </div>
  )
}

export default DataCaptureWithHeader
