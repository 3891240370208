import { getBasicInformation, setShouldFetchData } from 'features/dataCapture/dataCaptureSlice'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getErrorFetchingModalConfig } from 'utils/modalConfigs'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useGlobalModalContext } from '../../../../components/GlobalModalProvider/GlobalModalProvider'
import { useGetApiDataCaptureByIdQuery } from '../../../../services/zipmodule.gen'
import { InternalStatus } from '../../../../types'
import DataCaptureStatusChangeWrapper from '../../component/DataCaptureStatusChangeWrapper'
import { setDataCapture, setEditingFindingId } from '../../dataCaptureSlice'
import DataCaptureAddFinding from './subcomponents/dataCaptureAddFinding/DataCaptureAddFinding'
import DataCaptureCreateEditWithHeader from './subcomponents/dataCaptureCreateEditWithHeader/DataCaptureCreateEditWithHeader'

const DataCaptureCreateEdit = () => {
  const { id, dropdownId } = useParams()
  /* istanbul ignore next: branch unreachable */
  const serviceRequestIdParam = id === undefined ? '' : id
  const dispatch = useAppDispatch()
  const { showModal } = useGlobalModalContext()
  const basicInformation = useAppSelector(getBasicInformation)
  const [initialDataLoad, setInitialDataLoad] = useState<boolean>(true)
  const childRef = useRef<{ endDocumentation: () => void; setNurseStarted: () => void }>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    data,
    isFetching,
    error,
    refetch: refetchDataCapture,
  } = useGetApiDataCaptureByIdQuery(
    { id: serviceRequestIdParam },
    {
      refetchOnMountOrArgChange: false,
      skip: Object.keys(basicInformation).length !== 0,
    }
  )

  useEffect(() => {
    error &&
      // to prevent the modal for 404 errors, which will redirect to not-found
      navigator.onLine &&
      showModal(
        getErrorFetchingModalConfig({
          t,
          handleOk: refetchDataCapture,
          handleCancel: () => {
            navigate('/procedures')
          },
        })
      )
  }, [error])

  useEffect(() => {
    if (data) {
      if (initialDataLoad) {
        dispatch(setDataCapture(data))
        // on initial data load set nurse started status
        data.basicInformation?.procedureInternalStatus === InternalStatus.Created &&
          childRef?.current?.setNurseStarted()
      }
      dispatch(setEditingFindingId(''))
      /* istanbul ignore next: code possibly unreachable */
      if (!initialDataLoad) {
        dispatch(setShouldFetchData(false))
      }

      setInitialDataLoad(false)
    }
  }, [data])

  useEffect(() => {
    if (dropdownId) {
      dispatch(setEditingFindingId(dropdownId))
    }
  }, [dropdownId])

  return (
    <DataCaptureStatusChangeWrapper ref={childRef}>
      <DataCaptureCreateEditWithHeader isLoading={isFetching}>
        <DataCaptureAddFinding isLoading={isFetching} />
      </DataCaptureCreateEditWithHeader>
    </DataCaptureStatusChangeWrapper>
  )
}

export default DataCaptureCreateEdit
