import React from 'react'

import DataCaptureCreateEditHeader from '../dataCaptureCreateEditHeader/DataCaptureCreateEditHeader'
import styles from './styles.module.scss'

const DataCaptureCreateEditWithHeader: React.FC<{
  children: React.ReactNode
  isLoading?: boolean
}> = ({ children, isLoading }) => {
  return (
    <div className={styles.wrapper} id="nova-report-Data-Capture-With-Header-root">
      <DataCaptureCreateEditHeader isLoading={isLoading} />
      <div className={styles.scrollContainer}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  )
}

export default DataCaptureCreateEditWithHeader
